import React from 'react';
import { Flex, Select, Tag, Col, Badge} from 'antd';
import { connect } from 'react-redux';
// import TacitbaseAvtar from '../../ant/Avtar';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import TacitbaseAvtar from '../../ant/Avtar';

// Define separate date filter groups
const firstRowFilters = ['All time'];
const secondRowFilters = ['Today', 'Yesterday', 'Last week'];
const lastRowFilters = ['Last month', 'Last year'];

interface SearchedSideFilterComponentProps {
  jobPlatforms: any;
  sources: any;
  onFilterChange: (filters: { filter_by?: string, sources: string[] }) => void;  // Parent callback prop (optional filter_by)
}

const SearchedSideFilterComponent: React.FC<SearchedSideFilterComponentProps> = ({ sources, onFilterChange }) => {
  const [selectedDate, setSelectedDate] = React.useState<string>('All time'); // Only one date at a time
  const [selectedSources, setSelectedSources] = React.useState<string[]>([]);  // Multiple sources
  const [filters, setFilters] = React.useState<{ filter_by?: string, sources: string[] }>({
    filter_by: '',  // Default filter (empty, no date filter)
    sources: []
  });

  const convertToQueryFormat = (date: string) => {
    if (date === 'All time') {
      return null; // Don't send any filter for "All Time"
    }
  
    let filterQuery: string = 'updated_at:=<0'; // Default value for other filters
  
    // Use block scope for each case
    const now = new Date();
  
    switch (date) {
      case 'Today': {
        // Set to start of today (midnight)
        now.setHours(0, 0, 0, 0);
        const todayTimestamp = Math.floor(now.getTime() / 1000); // Unix timestamp in seconds
        filterQuery = `updated_at:=>${todayTimestamp}`; // Filter for today
        break;
      }
      case 'Yesterday': {
        // Set to start of yesterday
        now.setDate(now.getDate() - 1);
        now.setHours(0, 0, 0, 0);
        const yesterdayTimestamp = Math.floor(now.getTime() / 1000); // Unix timestamp in seconds
        filterQuery = `updated_at:=>${yesterdayTimestamp}`; // Filter for yesterday
        break;
      }
      case 'Last week': {
        // Set to start of last week (7 days ago)
        const firstDayOfWeek = now.getDate() - now.getDay(); // Get the first day of the current week
        now.setDate(firstDayOfWeek - 7); // Go back to the start of last week
        now.setHours(0, 0, 0, 0);
        const lastWeekTimestamp = Math.floor(now.getTime() / 1000); // Unix timestamp in seconds
        filterQuery = `updated_at:=>${lastWeekTimestamp}`; // Filter for last week
        break;
      }
      case 'Last month': {
        // Set to the start of the previous month
        now.setMonth(now.getMonth() - 1);
        now.setDate(1); // First day of the previous month
        now.setHours(0, 0, 0, 0);
        const lastMonthTimestamp = Math.floor(now.getTime() / 1000); // Unix timestamp in seconds
        filterQuery = `updated_at:=>${lastMonthTimestamp}`; // Filter for last month
        break;
      }
      case 'Last year': {
        // Set to the start of the previous year
        now.setFullYear(now.getFullYear() - 1);
        now.setMonth(0); // January (0 index-based)
        now.setDate(1); // First day of January
        now.setHours(0, 0, 0, 0);
        const lastYearTimestamp = Math.floor(now.getTime() / 1000); // Unix timestamp in seconds
        filterQuery = `updated_at:=>${lastYearTimestamp}`; // Filter for last year
        break;
      }
      default: {
        // If no match, return empty filter for "All Time"
        filterQuery = ''; // No filter for "All Time"
        break;
      }
    }
  
    return filterQuery;
  };
  

  // Handle date selection (only one date can be selected)
  const handleDateChange = (tag: string, checked: boolean) => {
    const nextSelectedDate = checked ? tag : 'All time'; // Only one date is allowed
    setSelectedDate(nextSelectedDate);

    const queryFormat = convertToQueryFormat(nextSelectedDate);

    // If the result is null, we skip setting 'filter_by' to ensure it's not included
    const updatedFilters = { 
      ...filters, 
      filter_by: queryFormat || undefined, // Remove filter_by if it's "All Time"
    };
    setFilters(updatedFilters);

    // Call parent function with updated filters (send 'filter_by' only when applicable)
    onFilterChange(updatedFilters); // Pass the formatted query to parent for API call
  };

  // Handle source selection
  const handleSourceChange = (value: string[]) => {
    setSelectedSources(value);
    const updatedFilters = { ...filters, sources: value };
    setFilters(updatedFilters);
    onFilterChange(updatedFilters); // Call parent function with updated filters
  };

  return (
    <div
      className="px-4 py-2 w-full overflow-y-scroll flex"
      style={{ height: "540px" }}
    >
      <Col>
        {/* First Row (All Time) */}
        <Col>
          <div className="p-1 mb-3 text-sm font-normal">Last updated</div>
          <Flex gap={8} wrap align="center">
            {firstRowFilters.map<React.ReactNode>((tag) => (
              <Tag.CheckableTag
                key={tag}
                checked={selectedDate === tag} // Only one selected at a time
                onChange={(checked) => handleDateChange(tag, checked)}
                className="border date-custom-tag" // Add bordered style
              >
                {tag}
              </Tag.CheckableTag>
            ))}
          </Flex>
        </Col>

        {/* Second Row (Today, Yesterday, Last Week) */}
        <Col className="mt-3">
          <Flex gap={6} wrap align="center">
            {secondRowFilters.map<React.ReactNode>((tag) => (
              <Tag.CheckableTag
                key={tag}
                checked={selectedDate === tag} // Only one selected at a time
                onChange={(checked) => handleDateChange(tag, checked)}
                className="border date-custom-tag" // Add bordered style
              >
                {tag}
              </Tag.CheckableTag>
            ))}
          </Flex>
        </Col>

        {/* Last Row (Last Month, Last Year) */}
        <Col className="mt-3">
          <Flex gap={6} wrap align="center">
            {lastRowFilters.map<React.ReactNode>((tag) => (
              <Tag.CheckableTag
                key={tag}
                checked={selectedDate === tag} // Only one selected at a time
                onChange={(checked) => handleDateChange(tag, checked)}
                className="border date-custom-tag" // Add bordered style
              >
                {tag}
              </Tag.CheckableTag>
            ))}
          </Flex>
        </Col>

        {/* Sources Filter */}
        <div className="overflow-y-scroll flex flex-col space-y-2">
          <div className="p-1 my-2 mt-8 text-sm font-normal">
            Filter by source{" "}
            <Badge
              className="ml-1"
              count={filters.sources.length}
              size="default"
              style={{ backgroundColor: "#4B48A5" }}
            />
          </div>
          <div className="overflow-y-scroll flex flex-col space-y-2">
            <Select
              mode="multiple"
              placeholder="Select source"
              value={selectedSources}
              onChange={handleSourceChange} // Handle sources change
              showSearch // Enable search functionality
              allowClear // Enable clearing the selected sources
              style={{ width: "100%", marginTop: "4px" }}
              filterOption={(input, option: any) => {
                const text =
                  option.props.children.props.children[1]?.props.children;
                return text.toLowerCase().includes(input.toLowerCase());
              }}
            >
              {Object.entries(sources).map(([id, source]: any) => (
                <Select.Option key={id} value={id}>
                  <div className="flex flex-row items-center">
                    <div className="ml-1">
                      {/* <Avatar src={source.profile} style={{backgroundColor:'white'}} size="small" /> */}
                      {source.profile ? (
                        <img
                          src={source.profile}
                          alt={source.name}
                          style={{ width: 20, height: 20, borderRadius: "50%" }}
                        />
                      ) : (
                        <TacitbaseAvtar content={source.name[0]} />
                      )}
                    </div>
                    <div className="pl-2">
                      {capitalizeFirstLetter(source.name)}
                    </div>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </Col>
    </div>
  );
};

// Map state to props
const mapStateToProps = (state: any) => ({
  jobPlatforms: state.opening.jobPlatform,
  sources: state.opening.candidateSources,
});

// Connect and export component
export default connect(mapStateToProps)(SearchedSideFilterComponent);

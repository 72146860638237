import React, { useEffect, useRef, useState } from "react";
import {
  InputNumber,
  Radio,
  Select,
  Typography,
  Button,
  Divider,
  Space,
  Input,
} from "antd";
import FormItem from "../../ant/FormItem";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { Integrations, Location, jdCreationObject } from "./Types";
import JobPostAdditionalInfo from "./JobPostAdditionalInfo";
import { company } from "../../type/type";
import {  getCompanies, getDepartment } from "../../lib/api";
import { filterLocations } from "../../utils/location";
import CurrentIntegrations from "./CurrentIntegrations";
import { FormInstance } from "antd/lib";
import { connect } from "react-redux";
import { newOrganisation } from "../../lib/apiRes";
import LinkedinSource from "./LinkedinSource";
import HideJobPost from "./HideJobPost";
import AddIcon from "../Icons/AddIcon";
const { Text } = Typography;
const { Option } = Select;

type JobPostInformationProps = {
  data: jdCreationObject;
  handleSetData: (value: jdCreationObject) => void;
  handleSetIntegrations : (value:Integrations[] ) => void;
  getCurrentStep: (state: number) => void;
  getWorkspaceId: (value: string) => void;
  currentStep: number;
  formInstance: FormInstance;
  integration:Integrations[];
  orgdata: newOrganisation;
};

const JobPostInformation = (props: JobPostInformationProps) => {
  const [searchText, setSearchText] = useState("");
  const [companyData, setCompanyData] = useState<company[]>([]);
  const [items, setItems] = useState(["Engineering", "Marketing", "Sales"]);
  const selectRef = useRef(null);

  // fetching getCompanies api to get available workspace
  useEffect(() => {
    getCompanies().then((res) => {
      setCompanyData(res);
      if (!props.data.workspaceId) {
        props.getWorkspaceId(res[0].id);
      }
    });
    getDepartment(props.orgdata.career_page_url_suffix)
      .then((res) => {
        if (res.department) {
          const uniqueDepartments = Array.from(new Set([...items, ...res.department]));
          setItems(uniqueDepartments);
        }
      })
      .catch((error) => {
        console.error("Error fetching department data:", error);
      });
  }, []);



  //getting job post hide status on career page
  const handleHideStatus = (value: boolean) => {
    let newData: jdCreationObject = {
      ...props.data,
      hide: value,
    };
    props.handleSetData(newData);
  };

  // validating number of opening
  const validateNumberOfOpening = (_: any, value: number) => {
    if (value < 0) {
      return Promise.reject("Must be greater than zero");
    }
    if (value > 100) {
      return Promise.reject("Must be less than 100");
    }
    return Promise.resolve();
  };

  // available job types
  const jobTypes = [
    { label: "Full time", value: "Full-time" },
    { label: "Part time", value: "Part-time" },
    { label: "Internship", value: "Internship" },
    { label: "Contract", value: "Contract" },
  ];

  // available workplace types
  const workplaceTypes = [
    { label: "On site", value: "On-site" },
    { label: "Remote", value: "Remote" },
    { label: "Hybrid", value: "Hybrid" },
  ];

  // function that add new department in data
  const onDepartmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newData = {
      ...props.data,
      department: event.target.value,
    };
    props.handleSetData(newData);
  };

  // function that add new department via UI(input)
  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();

    if(props.data.department && !items.includes(props.data.department)) {
      setItems([...items, props.data.department]);
      let newData = {
        ...props.data,
        department: "",
      };
      props.handleSetData(newData);
    }else{
      return;
    }
  };

  // after selecting one location close the multiple select 
  const handleLocationSelect = (value: any, option: any) => {
    let locations = props.data.location ? props.data.location : [];
    // Blur the select component to close it
    if (selectRef.current) {
      const element:HTMLSelectElement = selectRef.current;
      element.blur();
    }
    props.handleSetData({
      ...props.data,
      location: [...locations, option.locationObj],
    });

    setSearchText("");
  };
// after deselecting location removing it from data
  const handleLocationDeselect = (value: any) => {
    let locations = props.data.location ? props.data.location : [];
    let updatedLocations = locations.filter(
      (loc: Location) => `${loc.city}, ${loc.state}, ${loc.country}` !== value
    );
    props.handleSetData({ ...props.data, location: updatedLocations });
  };

  return (
    <>
      <div className="w-full h-full px-8 pt-4">
        <div>
          <Text strong style={{ fontSize: "14px" }}>
            Job post details
          </Text>
        </div>
        <div>
          <Text type="secondary" style={{ fontSize: "14px" }}>
            Provide a brief overview of the job post, workplace type and
            location of the job with company.
          </Text>
        </div>
        <div className="w-full">
        <div className="flex flex-col gap-4 mt-4 sm:flex-col md:flex-col lg:flex-row lg:gap-8">
            <div className="w-full">
              <FormItem
                name="workspace"
                label="Workspace"
                rules={[
                  {
                    required: true,
                    message: capitalizeFirstLetter("workspace required"),
                  },
                ]}
              >
                <Select
                  value={props.data.workspaceId}
                  style={{
                    width: "100%",
                  }}
                  popupMatchSelectWidth={false}
                  onChange={(value: string) => props.getWorkspaceId(value)}
                  placeholder="My workspace 1"
                >
                  {companyData.map(
                    (companyItem: company) =>
                      companyItem && (
                        <Option key={companyItem.id} value={companyItem.id}>
                          {companyItem.name}
                        </Option>
                      )
                  )}
                </Select>
              </FormItem>
            </div>
            <div className="w-full">
              <FormItem
                name="department"
                label={capitalizeFirstLetter("department")}
              >
                <Select
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      <Space style={{ padding: "0 8px 4px" }}>
                        <Input
                          placeholder="Please enter department"
                          value={props.data.department}
                          onChange={onDepartmentChange}
                          onKeyDown={(e) => e.stopPropagation()}
                        />
                        <Button
                          type="text"
                          icon={<AddIcon />}
                          onClick={addItem}
                        >
                          Add department
                        </Button>
                      </Space>
                    </>
                  )}
                  options={items.map((item:string) => ({
                    label: item,
                    value: item,
                  }))}
                />
              </FormItem>
            </div>
          </div>
          <div className="flex flex-col gap-4 mt-4 sm:flex-col md:flex-col lg:flex-row lg:gap-8">
            <div className="w-full">
              <FormItem
                name="location"
                label={capitalizeFirstLetter("job locations")}
                rules={[
                  {
                    required: true,
                    message: capitalizeFirstLetter("locations required"),
                  },
                ]}
              >
                <Select
                  ref={selectRef}
                  mode="multiple"
                  maxTagCount={"responsive"}
                  maxCount={4}
                  showSearch
                  onSearch={(value) => setSearchText(value)}
                  filterOption={false}
                  optionFilterProp="children"
                  onDeselect={handleLocationDeselect}
                  onSelect={handleLocationSelect}
                  notFoundContent={null}
                  placeholder="Search city"
                  value={props.data.location}
                >
                  {filterLocations(searchText).map((location: Location) => (
                    <Option
                      key={location.city}
                      value={`${location.city}, ${location?.state}, ${location.country}`}
                      locationObj={location}
                    >
                      {location?.city},{location?.state},{location?.country}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </div>
            <div className="w-full">
              <FormItem
                name="number_of_openings"
                label={capitalizeFirstLetter("Number of openings")}
                rules={[{ validator: validateNumberOfOpening }]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  onKeyPress={(e) => {
                    if (e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                />
              </FormItem>
            </div>
          </div>
          <div className="flex flex-col gap-4 mt-4 sm:flex-col md:flex-col lg:flex-row lg:gap-8">
          <div className="w-full p-2 " style={{ backgroundColor: "#F0EFFE" }}>
              <FormItem
                name="job_type"
                label={capitalizeFirstLetter("job type")}
                rules={[
                  {
                    required: true,
                    message: capitalizeFirstLetter("job type required"),
                  },
                ]}
              >
                  <Radio.Group
                  className="flex justify-start flex-wrap rounded"
                    value={props.data.job_type}
                    onChange={(e: any) =>
                      props.handleSetData({
                        ...props.data,
                        job_type: e.target.value,
                      })
                    }
                    options={jobTypes}
                  />
              </FormItem>
            </div>

            <div className="w-full p-2 " style={{ backgroundColor: "#F0EFFE" }}>
              <FormItem
                name="workplace_type"
                label={capitalizeFirstLetter("workplace type")}
                rules={[
                  {
                    required: true,
                    message: capitalizeFirstLetter("workplace type required"),
                  },
                ]}
              >
                  <Radio.Group
                  className="flex justify-start flex-wrap rounded"
                    value={props.data.workplace_type}
                    onChange={(e: any) =>
                      props.handleSetData({
                        ...props.data,
                        workplace_type: e.target.value,
                      })
                    }
                    options={workplaceTypes}
                  />
              </FormItem>
            </div>
          </div>

          <JobPostAdditionalInfo data={props.data} formInstance = {props.formInstance}  />
        </div>
        <CurrentIntegrations
          data={props.data}
          integration = {props.integration}
          handleSetIntegrations = {props.handleSetIntegrations}
        />
        <LinkedinSource/>
        <HideJobPost
        handleHideStatus={handleHideStatus}
        data={props.data}
        />
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  orgdata: state.opening.currentOrg,

});
export default connect(mapStateToProps)(JobPostInformation);

import { ASCENDING, DATE, DESCENDING, SET_VIEW_TYPE, SOCKET_CLIENT_ID } from "../../constant";
import {
  company,
  FilterKeysType,
  FormBuilder,
  Jd,
  JobBoard,
  Member,
  Opening,
  openingMemberType,
  OState,
  wsAction,
} from "../../type/type";
import { SUBSCRIBE } from "../actionType"
import { InitCurrentTrackerForm, InitcurrentCandidateInPool, applicantStatus, defaultOpening, initCandidatePool } from "./initialState"
import { waitForSocketConnection } from "../../service/waitForWsConnection"

export function init_ws(state: any, action: any) {
  return {
    ...state,
    wsConnection: action.payload,
  };
}

export function init_opening(state: any, action: any) {
  var openings: OState = {};
  var companyBoards: {
    [company_id: string]: {
      company_id: string,
      company_name: string,
      boards: string[],
      analytics: any // Ensure to include analytics if needed
    }
  } = {} // Initialize companyBoards as an empty object

  if (action.payload) {
    action.payload.forEach((jobBoard: JobBoard) => {
      const boardIds: string[] = [];
      jobBoard?.boards?.forEach((board: Opening) => {
        openings[board.id] = board // Use board.id as the key
        boardIds.push(board.id) // Add board.id to boardIds array
      });

      // Add or update the companyBoard in the companyBoards object
      companyBoards[jobBoard.company_id] = {
        company_id: jobBoard.company_id,
        company_name: jobBoard.company_name,
        boards: boardIds,
        analytics: jobBoard.analytics // Ensure to include analytics if needed
      };
    });
  }

  return {
    ...state,
    openings: {
      ...state.openings,
      pageSection: {
        ...state.openings.pageSection,
        sectionName: "PERSONAL BOARD",
        openings: openings,
      },
    },
    companyBoards: {
      ...state.companyBoards,
      ...companyBoards, // Assign the updated companyBoards object
    },
    orgStatus: {
      ...state.orgStatus,
      prev: {
        ...state.orgStatus.prev,
        id: state.applicationId,
      },
      current: {
        ...state.orgStatus.current,
        id: state.applicationId,
      },
    },
  };
}

export function add_new_opening(state: any, action: any) {
  var openingMembersIds: openingMemberType[] = [];
  openingMembersIds.push({
    id: action.data.member_id,
    access: action.data.access,
  });

  // Check if the company already exists in companyBoards
  let currentCompany = state.companyBoards[action.data.company_id];
  if (!currentCompany) {
    // Company doesn't exist, create a new company and add it to companyBoards array
    currentCompany = {
      company_id: action.data.company_id,
      company_name: action.data.company_name,
      boards: [action.data.id],
    };
  } else {
    // Company exists, update the boards array
    if (!currentCompany.boards.includes(action.data.id)) {
      currentCompany.boards.push(action.data.id);
    }
  }

  return {
    ...state,
    openings: {
      ...state.openings,
      pageSection: {
        ...state.openings.pageSection,
        openings: {
          ...state.openings.pageSection.openings,
          [action.data.id]: {
            ...(state.openings.pageSection.openings[action.data.id] || {}),
            ...action.data,
          },
        },
      },
      
    },
    companyBoards: {
      ...state.companyBoards,
      [action.data.company_id]: currentCompany,
    },
    currentOpening:
      action.data.member_id === state.loginUser.user.id &&
      sessionStorage.getItem(SOCKET_CLIENT_ID) === action.data.socket_client_id
        ? action.data
        : state.currentOpening,
    archivedApplicants: {
      ...state.archivedApplicants,
      [action.data.id]: state.archivedApplicants[action.data.id] || [],
    },
    archiveList: {
      ...state.archiveList,
      [action.data.id]: state.archiveList[action.data.id] || [],
    },
    openingMembers: {
      ...state.openingMembers,
      [action.data.id]: [
        ...(state.openingMembers[action.data.id] || []),
        ...openingMembersIds,
      ],
    },
    totalBoards:{
      ...state.totalBoards,
      active: state.totalBoards.active +1,
      total: state.totalBoards.total
    },
  };
}
export function remove_opening(state: any, action: any) {
  const { company_id, id: opening_id, member_id } = action.data;

  // Get the current company from companyBoards
  const currentCompany = state.companyBoards[company_id];
  if (currentCompany) {
    // Filter out the opening ID from the company's boards
    const updatedBoards = currentCompany.boards.filter((boardId:string) => boardId !== opening_id);

    // Create a new companyBoards object without the company if no boards are left
    const updatedCompanyBoards = updatedBoards.length > 0
      ? { ...state.companyBoards, [company_id]: { ...currentCompany, boards: updatedBoards } }
      : { ...state.companyBoards };

    // Remove the company from companyBoards if no boards are left
    if (updatedBoards.length === 0) {
      delete updatedCompanyBoards[company_id];
    }

    return {
      ...state,
      companyBoards: updatedCompanyBoards,
      openings: {
        ...state.openings,
        pageSection: {
          ...state.openings.pageSection,
          openings: {
            ...state.openings.pageSection.openings,
            [opening_id]: undefined,
          },
        },
      },
      openingMembers: {
        ...state.openingMembers,
        [opening_id]: (state.openingMembers[opening_id] || []).filter((member:any) => member.id !== member_id),
      },
      archivedApplicants: {
        ...state.archivedApplicants,
        [opening_id]: undefined,
      },
      archiveList: {
        ...state.archiveList,
        [opening_id]: undefined,
      },
      currentOpening: state.currentOpening.id === opening_id ? {} : state.currentOpening,
    };
  }

  // If the company does not exist in companyBoards, return the state unchanged
  return state;
}



export function add_opening(state: any, action: any) {
  var openingMembersIds: openingMemberType[] = [];
  openingMembersIds.push({
    id: action.data.member_id,
    access: action.data.access,
  });



    // Company doesn't exist, create a new company and add it to companyBoards array
    let currentCompany: {
      company_id: string,
      company_name: string,
      boards: string[]
    } = {
      company_id: action.data?.company_id,
      company_name: action.data?.company_name,
      boards: [action.data.id]
    };

  

  return {
    ...state,
    openings: {
      ...state.openings,
      pageSection: {
        ...state.openings.pageSection,
        openings: {
          ...state.openings.pageSection.openings,
          [action.data.id]: action.data,
        },
      },
    },
    companyBoards: {
      ...state.companyBoards,
      [action.data?.company_id]: currentCompany
    },
    currentOpening:
      action.data.member_id === state.loginUser.user.id &&
      sessionStorage.getItem(SOCKET_CLIENT_ID) === action.data.socket_client_id
        ? action.data
        : state.currentOpening,
    archivedApplicants: {
      [action.data.id]: [],
    },
    archiveList: {
      [action.data.id]: [],
    },
    openingMembers: {
      ...state.openingMembers,
      [action.data.id]: openingMembersIds,
    },
  };
}

export function add_opening_from_ws(state: any, action: any) {

  let currentCompany: {
    company_id: string,
    company_name: string,
    boards: string[]
  } = {
    company_id: action.data?.company_id,
    company_name: action.data?.company_name,
    boards: [action.data.id]
  };

    var openingMembersIds: openingMemberType[] = [];
    openingMembersIds.push({
      id: action.data.member_id,
      access: action.data.access,
    });
  
    return {
      ...state,
      openings: {
        ...state.openings,
        pageSection: {
          ...state.openings.pageSection,
          openings: {
            ...state.openings.pageSection.openings,
            [action.data.id]: {...action.data,members:[]}
            ,
          },
        },
      },
      companyBoards: {
        ...state.companyBoards,
        [action.data?.company_id]: currentCompany
      },     
      openingMembers: {
        ...state.openingMembers,
        [action.data.id]: openingMembersIds,
      },
    };
  }
export function getActiveBoard(state:any,action:any){
  return {
   ...state,
    totalBoards: action.payload,
  }
}
export function init_current_opening(state: any, action: any) {
  return {
    ...state,
    currentOpening: action.payload,
    // applicantLabels: {},
    showSearch: false,
    archiveList: {
      [action.payload.id]: [],
    },
    archivedApplicants: {
      [action.payload.id]: [],
    },
  }
}
export function init_current_tracker_jd(state: any, action: { payload: Jd }) {
  return {
    ...state,
    currentTrackerJd: action.payload,

  }
}
export function init_current_tracker_jd_form(state: any, action: { payload: FormBuilder }) {
  return {
    ...state,
    currentTrackerForm: action.payload,
  };
}
export function remove_current_opening(state: any) {
  return {
    ...state,
    currentOpening: defaultOpening,
    boardList: {},
    labels: {},
    applicantLabels: {},
    applicantMembers: {},
    openingMembers: {},
    openingActivity: [],
    slackOptions: {},
    filterCount: null,
    currentCandidateInPool: InitcurrentCandidateInPool,
    candidatePool: initCandidatePool,
    currentTrackerJd: InitCurrentTrackerForm,
    currentTrackerForm: InitCurrentTrackerForm,
    applicantStatus: applicantStatus
  };
}
export function update_opening_name(state: any, action: any) {
  return {
    ...state,
    currentOpening:
      state.currentOpening.id === action.data.id
        ? {
            ...state.currentOpening,
            name: action.data.name,
          }
        : state.currentOpening,
    openings: {
      ...state.openings,
      pageSection: {
        ...state.openings.pageSection,
        openings: {
          ...state.openings.pageSection.openings,
          [action.data.id]: {
            ...state.openings.pageSection.openings[action.data.id],
            name: action.data.name,
          },
        },
      },
    },
  };
}
export function update_current_opening(state: any, action: any) {
  return {
    ...state,
    currentOpening:
      state.currentOpening.id === action.data.id
        ? {
            ...state.currentOpening,
            archive: action.data.archive, 
          }
        : state.currentOpening,
    openings: {
      ...state.openings,
      pageSection: {
        ...state.openings.pageSection,
        openings: {
          ...state.openings.pageSection.openings,
          [action.data.id]: {
            ...state.openings.pageSection.openings[action.data.id],
            archive: action.data.archive, 
          },
        },
      },
    },
  };
}

export function update_opening_description(state: any, action: any) {
  return {
    ...state,
    currentOpening:
      state.currentOpening.id === action.data.id
        ? {
            ...state.currentOpening,
            description: action.data.description,
          }
        : state.currentOpening,
    openings: {
      ...state.openings,
      pageSection: {
        ...state.openings.pageSection,
        openings: {
          ...state.openings.pageSection.openings,
          [action.data.id]: {
            ...state.openings.pageSection.openings[action.data.id],
            description: action.data.description,
          },
        },
      },
    },
  };
}

export function update_board_background_color(state: any, action: any) {
  return {
    ...state,
    currentOpening:
      state.currentOpening.id === action.data.id
        ? {
            ...state.currentOpening,
          color: action.data.color,
          background_image: action.data.background_image
          }
        : state.currentOpening,
    openings: {
      ...state.openings,
      pageSection: {
        ...state.openings.pageSection,
        openings: {
          ...state.openings.pageSection.openings,
          [action.data.id]: {
            ...state.openings.pageSection.openings[action.data.id],
            color: action.data.color,
            background_image: action.data.background_image
          },
        },
      },
    },
  };
}
export function starred_board(state: any, action: any) {
  return {
    ...state,
    openings: {
      ...state.openings,
      pageSection: {
        ...state.openings.pageSection,
        openings: {
          ...state.openings.pageSection.openings,
          [action.data.id]: {
            ...state.openings.pageSection.openings[action.data.id],
            starred: action.data.starred,
          },
        },
      },
    },
    currentOpening:
      state.currentOpening.id === action.data.id
        ? {
            ...state.currentOpening,
            starred: action.data.starred,
          }
        : state.currentOpening,
  };
}

export function init_opening_membership(state: any, action: any) {
  var openingMembersIds: openingMemberType[] = [];
  action.data.map((member: Member) => {
    openingMembersIds.push({ id: member.id, access: member.access });
  });
  return {
    ...state,
    openingMembers: {
      ...state.openingMembers,
      [action.currentOpeningId]: openingMembersIds,
    },
  };
}
export function slack_integration(state: any, action: any) {
  return {
    ...state,
    slackOptions: action.payload,
  };
}

export function user_notification_settings_integration(state: any, action: any) {
  return {
    ...state,
    notificationOptions: action.payload,
  };
}



export function show_search(state: any) {
  return {
    ...state,
    showSearch: !state.showSearch,
  };
}
export function reset_search(state: any) {
  return {
    ...state,
    showSearch: false,
  };
}

export function update_search_flag(state: any) {
  return {
    ...state,
    showSearch: false,
  };
}
export function set_slack_option(state: any, action: any) {
  return {
    ...state,
    slackOptions: action.data,
  };
}

export function delete_slack_option(state: any) {
  let resetOptions: any = {};
  for (const [key] of Object.entries(state.slackOptions.setting)) {
    resetOptions[key] = state.slackOptions.setting[key].map(
      (o: any) => (o = { ...o, check: false })
    );
  }
  return {
    ...state,
    slackOptions: {
      id: "",
      userId: "",
      topic: "",
      subscriber: "",
      setting: resetOptions,
    },
  };
}
//  (a = { ...a, check: false })

export function board_slack_options(state: any) {
  return {
    ...state,
  };
}

export function dequeue_ws_request_action(state: any, action: any) {
  const remainingWsRequest = Object.keys(state.websocketActionRequestQueue)
    .filter((key) => key !== action.data.subTopic)
    .reduce((obj: any, key: string) => {
      obj[key] = state.websocketActionRequestQueue[key];
      return obj;
    }, {});

  const remainingWsRequestSorted = Object.keys(remainingWsRequest).sort(
    function (a, b) {
      return remainingWsRequest[a].time - remainingWsRequest[b].time;
    }
  );

  return {
    ...state,
    websocketActionRequestQueue: {
      ...remainingWsRequest,
    },

    websocketPrcessedActionRequestQueue:
      action.data.action === SUBSCRIBE
        ? {
            ...state.websocketPrcessedActionRequestQueue,
            [action.data.subTopic]: action.data,
          }
        : {
            ...state.websocketPrcessedActionRequestQueue,
          },

    currentWsRequest:
      remainingWsRequestSorted.length > 0
        ? {
            ...remainingWsRequest[remainingWsRequestSorted[0]],
          }
        : state.currentWsRequest,
  };
}

export function add_ws_request_action(state: any, action: { data: wsAction }) {
  waitForSocketConnection(state.wsConnection, () => {
    state.wsConnection.send(JSON.stringify(action.data))
  })
  return {
    ...state,
    // websocketActionRequestQueue: [
    //   ...state.websocketActionRequestQueue,
    //   action.data,
    // ],
    currentWsRequest: action.data,
    // websocketPrcessedActionRequestQueue:
    //   action.data.type === UNSUBSCRIBE
    //     ? Object.keys(state.websocketPrcessedActionRequestQueue)
    //         .filter(
    //           (key) =>
    //             state.websocketPrcessedActionRequestQueue[key].target !==
    //             action.data.model_type
    //         )
    //         .reduce((obj: any, key: string) => {
    //           obj[key] = state.websocketPrcessedActionRequestQueue[key];
    //           return obj;
    //         }, {})
    //     : {
    //         ...state.websocketPrcessedActionRequestQueue,
    //       },
  };
}

export function reconnect_ws_request_action(state: any) {
  return {
    ...state,
    // websocketActionRequestQueue: [
    //   ...state.websocketPrcessedActionRequestQueue,
    // ],
    // websocketPrcessedActionRequestQueue: {},
    currentWsRequest:
      Object.keys(state.websocketPrcessedActionRequestQueue).length > 0
        ? {
            ...state.websocketPrcessedActionRequestQueue[
              Object.keys(state.websocketPrcessedActionRequestQueue)[0]
            ],
          }
        : state.currentWsRequest,
  };
}

export function filter(state: any, action: any) {
  let filters: FilterKeysType = {};
  let oldFilterKeys = state.boardFilterKeys[state.currentOpening.id] || {};
  if (action.data.checked) {
    filters = {
      ...oldFilterKeys,
      [action.data.key]: oldFilterKeys[action.data.key]
        ? [...oldFilterKeys[action.data.key], action.data.value]
        : [action.data.value],
    };
  } else {
    filters = {
      ...oldFilterKeys,
      [action.data.key]: oldFilterKeys[action.data.key]
        ? oldFilterKeys[action.data.key].filter(
            (id: string) => id != action.data.value
          )
        : [action.data.value],
    };
  }

  return {
    ...state,
    boardFilterKeys: {
      ...state.boardFilterKeys,
      [state.currentOpening.id]: filters,
    },
  };
}
export function remove_filters(state: any) {
  return {
    ...state,
    boardFilterKeys: {
      ...state.boardFilterKeys,
      [state.currentOpening.id]: {},
    },
    filterCount: null,
  };
}
export function filter_count(state: any, action: any) {
  return {
    ...state,
    filterCount: action.count,
  };
}
function sortBoards(arr: any, opening: any, sortingType: string) {
  switch (sortingType) {
    case ASCENDING:
      return arr = ascending(arr, opening);
    case DESCENDING:
      return arr = descending(arr, opening);
    case DATE:
      return arr = dateSorting(arr, opening)
    default:
      return arr
  }
}
export function sorting_board(state: any, action: any) {
  const sortedCompanyBoards = Object.keys(state.companyBoards).reduce<Record<string, company>>((acc, companyId) => {
    const company = state.companyBoards[companyId]
    const sortedBoards = sortBoards([...company.boards], state.openings.pageSection.openings, action.data)
    acc[companyId] = { ...company, boards: sortedBoards }
    return acc
  }, {});

  return {
    ...state,
    companyBoards: sortedCompanyBoards,
    sortingType: {
      ...state.sortingType,
      [state.applicationId]: {
        ...state.sortingType[state.applicationId],
        ["boards"]: action.data,
      },
    },
  };
}
export function edit_role_of_board(state: any, action: any) {
  return {
    ...state,
    openingMembers: {
      ...state.openingMembers,
      [action.r_id]: state.openingMembers[action.r_id]
        ? state.openingMembers[action.r_id].map((m: openingMemberType) =>
            m.id === action.memberId
              ? { ...m, access: action.access }
              : { ...m }
          )
        : [{ id: action.memberId, access: action.access }],
    },
  };
}
export function change_board_visibility(state: any, action: any) {
  return {
    ...state,
    currentOpening:
      state.currentOpening.id === action.data.id
        ? {
            ...state.currentOpening,
            member_only: action.data.member_only,
          }
        : state.currentOpening,
    openings: {
      ...state.openings,
      pageSection: {
        ...state.openings.pageSection,
        openings: {
          ...state.openings.pageSection.openings,
          [action.data.id]: {
            ...state.openings.pageSection.openings[action.data.id],
            ...action.data
          },
        },
      },
    },
  };
}

function ascending(inputArray: string[], openings: any) {
  inputArray.sort((a: string, b: string) => {
    if (openings[a].name.toUpperCase() > openings[b].name.toUpperCase()) {
      return 1;
    }
    if (openings[a].name.toUpperCase() < openings[b].name.toUpperCase()) {
      return -1;
    }
    return 0;
  });
  return inputArray;
}
function descending(inputArray: string[], openings: any) {
  inputArray.sort((a: string, b: string) => {
    if (openings[a].name.toUpperCase() > openings[b].name.toUpperCase()) {
      return -1
    }
    if (openings[a].name.toUpperCase() < openings[b].name.toUpperCase()) {
      return 1;
    }
    return 0;
  });
  return inputArray;
}

function dateSorting(inputArray: string[], openings: any) {
  return inputArray.sort((a: string, b: string) => {
    const dateA = new Date(openings[a].created_at).getTime();
    const dateB = new Date(openings[b].created_at).getTime();
    return dateB - dateA;
  });
}

export function add_board_to_workspace(state: any, action: any) {
  return {
    ...state,
    currentOpening: {
      ...state.currentOpening,
      company_id: action.company_id
    },
  }
}

export function viewReducer(state: any, action: any) {
  switch (action.type) {
    case SET_VIEW_TYPE: {
      return {
        ...state,
        viewType: action.data.viewType,
      };
    }
    default:
      return state;
  }
}



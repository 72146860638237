import React, { useState } from "react";
import { connect } from "react-redux";
import { updateSearchFlag } from "../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { useHistory } from "react-router-dom";
import { Avatar, Tooltip } from "antd";
import { renderCreatedAt } from "../../utils/renderCreatedAt";
import CustomJobPostIcon from "../Icons/JobPostIcon";
import { convertUnixToISO } from "../../utils/ConvertUnixToIso";
import { preprocessSnippet } from "./ReviewsCardView";

interface JobPostCardViewProps {
  applicationId: string;
  searchResult: any;
  updateSearchFlag(): void;
}

function JobPostCardView({
  applicationId,
  searchResult,
  updateSearchFlag,
}: JobPostCardViewProps) {
  const history = useHistory();
  const [hovered, setHovered] = useState(false); // Hover state

  const showCard = (opening_id: string, applicant_id: string) => {
    history.push({
      pathname: `/o/${applicationId}/b/${opening_id}/tracker/${applicant_id}`,
    });
  };

  const showBoard = (opening_id: string) => {
    updateSearchFlag();
    history.push({
      pathname: `/o/${applicationId}/b/${opening_id}/tracker`,
    });
  };

  const { document, highlight } = searchResult;

  const nameSnippet = highlight.name ? highlight.name.snippet : document.name;
  //   const descriptionSnippet = highlight.description ? highlight.description.snippet : document.description;
  const date = convertUnixToISO(document.updated_at);

  return (
    <div
      className="flex flex-row rounded items-center pl-1 py-1 "
      style={{ width: "100%" }}
      onMouseEnter={() => setHovered(true)} // Set hovered state to true on mouse enter
      onMouseLeave={() => setHovered(false)} // Set hovered state to false on mouse leave
    >
      <Tooltip title="Job post">
      <Avatar
        style={{ backgroundColor: "#4B48A5" }}
        icon={<CustomJobPostIcon />}
      />
      </Tooltip>
      <div
        className="flex flex-col p-2 space-y-0.5 "
        onClick={() => showCard(document.opening_id, document.id)}
        role="button"
        tabIndex={0}
        style={{ width: hovered ? "95%" : "95%" }}
        onKeyDown={() => showCard(document.opening_id, document.id)}
        data-testid={document.id}
      >
        <div className="flex flex-row justify-between  text-xs font-medium w-full">
          <div
            className="flex leading-4 truncate break-words text-xs tracking-normal font-medium rounded pl-1 overflow-ellipsis"
            style={{ maxWidth: "75%" }}
            dangerouslySetInnerHTML={{ __html: preprocessSnippet(nameSnippet)  }} // Render highlighted name or fallback
          />

          { renderCreatedAt(date.toString())}
        </div>
        {/* <div className="flex flex-row space-x-3 text-gray-600 text-xs">
          {document.comments ? (
            <div className="flex items-center">
              <MessageOutlined />
              <span className="mx-1">{document.comments.length}</span>
            </div>
          ) : null}
          <div className="flex items-center">
            {document.description ? <AlignLeftOutlined /> : null}
          </div>
        </div> */}
        <div className="flex flex-row justify-between  text-xs font-medium w-full">
          <div
            className="truncate  break-words text-xs leading-4 tracking-normal font-medium pl-1 rounded capitalize cursor-pointer"
            onClick={() => showBoard(document.opening_id)}
            role="button"
            tabIndex={0}
            data-testid="testBtn"
            onKeyDown={() => showBoard(document.opening_id)}
          >
            {document.opening_name}
          </div>
        </div>
      </div>
      {/* {hovered && (
        <div className="flex space-x-1" onClick={(e) => e.stopPropagation()} role="button" tabIndex={0} onKeyDown={(e) => e.stopPropagation()}>
          <SendToTracker candidate={searchResult.document} />
          <ComposeMail currentApplicant={searchResult.document} />
          <SendToReview candidate={searchResult.document} />
          <MoveCard candidate={searchResult.document} />
        </div>
      )} */}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  updateSearchFlag: () => dispatch(updateSearchFlag()),
});

export default connect(mapStateToProps, mapPropsToState)(JobPostCardView);

import React, { useEffect, useState } from "react";
import { getCandidateApplication } from "../../../lib/api";
import { Applicant, Opening, OrganizedBoard, orgMember } from "../../../type/type";
import { connect } from "react-redux";
import { Avatar, Divider, Empty, Spin } from "antd";
import { AlignLeftOutlined, CommentOutlined} from "@ant-design/icons";
import Label from "../../boardPanel/label/Label";
import { renderCreatedAt } from "../../../utils/renderCreatedAt";
import TacitbaseAvatar from "../../../ant/Avtar";
import AttachmentIcon from "../../Icons/AttachmentIcon";
// Define prop types for the component
type CandidateInCDBTrackerProps = {
  openings: OrganizedBoard;
  members: orgMember;
  currentCandidate: any;
  currentOpening: Opening;
  applicationId: string;
  currentCandidateInEditMode:any;
  activeTab:string
};

// Component to track candidate details in ATS pool
const CandidateInCDBTracker: React.FC<CandidateInCDBTrackerProps> = (props) => {
  const [candidateApplications, setCandidateApplications] = useState<Applicant[]>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  // Fetch candidate application details when the current candidate changes
  useEffect(() => {
    setShowLoading(true)
    setCandidateApplications([])
    if(props.activeTab!=="Tracker")return;
    getCandidateApplication(props.currentCandidateInEditMode.candidateId.id).then(
      (res) => {
        setShowLoading(false)
        if (res.length > 0) {
          setCandidateApplications(res);
        }
      }
    );
  }, [props.currentCandidateInEditMode.candidateId.id,props.activeTab]);

  return (
    <div
      className="h-full pt-2 pb-2 pl-2 w-full"
      key={props.currentCandidateInEditMode.candidateId.id}
    >
      <div
        style={{ height: "calc(100vh - 245px)" }}
        className="overflow-y-scroll w-full "
      >
        <div className="w-full h-full gap-3.5 flex flex-col px-1">
          {
          candidateApplications.map((applicant: Applicant) => (
            <div
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              style={{
                boxShadow:
                  "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",
              }}
              className="flex flex-col rounded shadow-lg gap-1 w-full bg-white p-3 cursor-pointer"
              key={applicant.id}
              id={applicant.id}
            >
              <span className="text-base font-medium w-full truncate">
                {applicant.name}
              </span>
              <div className="flex flex-wrap">
                {applicant.labels.length > 0 &&
                  applicant.labels.map((label:any, index:any) => (
                    <Label
                      key={index}
                      color={label.color}
                      flag={true}
                      name={label.name}
                      click={() => {}}
                    />
                  ))}
              </div>
              <div className="flex items-center">
                <div className="flex items-center">
                  {props.members[applicant.creator] &&
                    props.members[applicant.creator].image_url && (
                      <Avatar.Group size={"small"} maxCount={4}>
                        <TacitbaseAvatar
                          src={props.members[applicant.creator].image_url}
                          key={props.members[applicant.creator].id}
                          size="small"
                          toolTip={`${
                            props.members[applicant.creator].first_name
                          } ${
                            props.members[applicant.creator].last_name
                          } (Creator)`}
                        />
                      </Avatar.Group>
                    )}
                  {applicant.members.filter(
                    (member) => member !== applicant.creator
                  ).length > 0 && (
                    <Divider
                      type="vertical"
                      style={{ borderColor: "#666666" }}
                    />
                  )}
                  <Avatar.Group maxCount={4} size={"small"}>
                    {applicant.members.map((member: string) =>
                      member !== applicant.creator ? (
                        props.members[member].image_url ? (
                          <TacitbaseAvatar
                            src={props.members[member].image_url}
                            key={props.members[member].id}
                            size="small"
                            toolTip={`${props.members[member].first_name} ${props.members[member].last_name}`}
                          />
                        ) : (
                          <TacitbaseAvatar
                            content={`${props.members[member].first_name[0]}${props.members[member].last_name[0]}`}
                            key={props.members[member].id}
                            size="small"
                            toolTip={`${props.members[member].first_name} ${props.members[member].last_name}`}
                          />
                        )
                      ) : null
                    )}
                  </Avatar.Group>
                </div>
              </div>
              <div className="flex items-center gap-4 text-lg justify-between">
                <div className="flex items-center gap-4 text-sm">
                  {applicant.description && (
                    <div>
                      <AlignLeftOutlined />
                    </div>
                  )}
                  <div>
                    <CommentOutlined /> {applicant.comment_count}
                  </div>
                  <div>
                    <AttachmentIcon /> {applicant.attachment_count}
                  </div>
                </div>
                <div
                  className="text-gray-500 text-xs font-normal"
                  style={{ color: "#666666" }}
                >
                  Created at {renderCreatedAt(applicant.created_at)}
                </div>
              </div>
              <Divider className="my-2" />
              <div className="flex flex-row">
                <Avatar
                  src={
                    props?.openings?.pageSection?.openings[applicant.opening_id]
                      ?.color || null
                  }
                  key={
                    props?.openings?.pageSection?.openings[applicant.opening_id]
                      ?.id
                  }
                  size="small"
                  shape="square"
                  className="w-10"
                  style={{
                    backgroundColor: props?.openings?.pageSection?.openings[
                      applicant.opening_id
                    ]?.color
                      ? props?.openings?.pageSection?.openings[
                          applicant.opening_id
                        ]?.color.split(" ")[0]
                      : "#F1735D",
                  }}
                >
                  {!props?.openings?.pageSection?.openings[applicant.opening_id]
                    ?.color && applicant.opening_name[0]}
                </Avatar>
                <div className="ml-4">
                  {applicant.workspace_name} {", "} {applicant.opening_name}
                </div>
              </div>
            </div>
          ))}
          {candidateApplications.length === 0 && !showLoading && (
            <Empty
              description="The candidate is not in the tracker."
              className="flex flex-col h-full justify-center items-center"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}

          {candidateApplications.length === 0 && showLoading && (
            <span className="flex flex-col space-y-2 items-center justify-center h-full">
              <Spin tip="" size="default" />
              <span>Fetching tracker history</span>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

// Map Redux state to component props
const mapStateToProps = (state: any) => ({
  currentCandidateInEditMode: state.opening.currentCandidateInEditMode,
  members: state.opening.members,
  currentOpening: state.opening.currentOpening,
  currentCandidate: state.opening.currentCandidateInEditMode,
  openings: state.opening.openings,
  applicationId: state.opening.applicationId,
});

// Connect component to Redux store
export default connect(mapStateToProps)(CandidateInCDBTracker);

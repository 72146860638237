import React, { useEffect, useState } from "react";
import JobPostInput from "./JobPostInput";
import JobPostInformation from "./JobPostInformation";
import { Modal, Form, Divider, Space, Button } from "antd";
import {
  createFormTemplate,
  createJobDescription,
  createOpenings,
  getAnalyticsListLabel,
  getAvailableIntegrations,
  getCompanies,
  getFormTemplate,
} from "../../lib/api";
import { Integrations, Status, jdCreationObject, orgMemberRoleVisibility } from "./Types";
import { connect } from "react-redux";
import { List, Opening, TotalBoards, loginUser, orgMember } from "../../type/type";
import { addNewOpening, getActiveBoard, initLists } from "../../redux/actions/opening/action";
import { Dispatch } from "redux";
import StepsBar from "./StepsBar";
import JobPostSuccess from "./JobPostSuccess";
import { PUBLISH, SOCKET_CLIENT_ID, TrackerRoute, UNPUBLISH } from "../../constant";
import { useHistory } from "react-router-dom";
import CancelDiscard from "../../ant/DiscardChanges";
// component props
type JobPostCreationProps = {
  open: boolean;
  onCancel: () => void;
  loginUser: loginUser;
  applicationId: string;
  saveNewOpening: (opening: any) => void;
  getActiveBoard(data: TotalBoards):void;
  initLists: (list: List[]) => void;
  orgMembers: orgMember;
  applicationName: string;
  totalBoards:TotalBoards;
};

const JobPostCreation = (props: JobPostCreationProps) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [boardRes, setBoardRes] = useState<Opening>();
  const [integration, setIntegration] = useState<Integrations[]>([]);
  const [disableNext, setDisableNext] = useState(false);
  
  // state that maintains the save as draft button
  const [draftSave, setDraftSave] = useState(false);
  const [discardWarning, setDiscardWarning] = useState(false);
  const [showbutton, setShowbutton] = useState(false);
  // api loading status
  const [apiLoading, setApiLoading] = useState<Status>({
    createOpening: 0,
    addFormToJd: 0,
    createJd: 0,
  });



  // interview stages which we add by default while creating job description
  const [trackerStages, setTrackerStages] = useState([
    { list_name: "Applications", label_id: "", isSelected: true },
    { list_name: "First interview", label_id: "", isSelected: true },
    { list_name: "HR round", label_id: "", isSelected: true },
    { list_name: "Offered", label_id: "", isSelected: true },
    { list_name: "Offer accepted", label_id: "", isSelected: true },
    { list_name: "Offer declined", label_id: "", isSelected: true },
    { list_name: "Joined", label_id: "", isSelected: true },
    { list_name: "Interview rejected", label_id: "", isSelected: true },
    { list_name: "Did not join", label_id: "", isSelected: true },
  ]);
  // job post creation process central data
  // default values of job description input fields
  const [data, setData] = useState<jdCreationObject>({
    selected_color: "teal text-white",
    board_title: "",
    workspaceId: "",
    workspaceName: "",
    department: "",
    company_name: props.applicationName,
    job_type: "",
    location: [],
    job_description: "",
    number_of_openings: 0,
    workplace_type: "",
    salary_type: "Annual",
    experiance_from: 0,
    experiance_to: 0,
    minimum_salary: 0,
    maximum_salary: 0,
    salary_currency_type: "USD",
    form_data: [],
    errorMessage: "",
    orgMemberRoleVisibility: [],
    selectedTool: [],
    tacitbaseIntegrationChecked: true,
    boardId: "",
    disableButtonState: true,
    jd_public_url: "",
    ManagerId: [],
    hide: false,
  });
  // getting form template and available integrations after first rendering
  useEffect(() => {
    getFormTemplate().then((res) => {
      setData({ ...data, form_data: res });
    });
    getAnalyticsListLabel().then((res) => {
      if (Array.isArray(res)) {
        const updatedStages = trackerStages.map((stage) => {
          const match = res.find((item) => item.name === stage.list_name);
          return {
            ...stage,
            label_id: match ? match.id : stage.label_id,
          };
        });
        setTrackerStages(updatedStages);
      }
    });
    getAvailableIntegrations()
      .then((res) => {
        if (res && Array.isArray(res)) {
          const integrations = res.map((integration) => ({
            integration_id: integration.id,
            platform_name: integration.integration_name,
            platform_image: integration.platform_image,
            status: UNPUBLISH,
          }));
          setIntegration( integrations);
        } else {
          console.error("Invalid response format", res);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch integrations", error);
      });
  }, []);

  const activeJobs = props.totalBoards.active;
  const updatedData = {
    active:activeJobs+1,
    total:props.totalBoards.total,
  }

  // if anything goes wrong while creating job post then enable go back button to to goto previous state
  const handleShowButton = (value: boolean) => {
    setShowbutton(value);
  };

  // setting data from JobPostInformation component
  const handleSetData = (newData: jdCreationObject) => {
    form.setFieldsValue({job_description: newData.job_description})
    setData(newData);
  };
// getting integrations and integrations status from another(child) component
  const handleSetIntegrations =(integrations: Integrations[]) => {
    setIntegration(integrations);
  }

  // getting workspaces id on user select based on that workspace manager will by default added to the job description
  const getWorkspaceId = (id: string) => {
    form.setFieldsValue({ workspace: id });
    setData({ ...data, workspaceId: id });
  };

  // after selecting workspace the workspace manager will by default added to the job description.
  useEffect(() => {
    getCompanies().then((res) => {
      const companyDataMemberIds = res.find(
        (member) => member.id === data.workspaceId
      );
      const workspaceMembers = companyDataMemberIds?.members.filter(
        (memberId) =>
          props.orgMembers[memberId]?.registrations[0]?.data.activated
      );
      const loginUserIdExists = workspaceMembers?.includes(
        props.loginUser.user.id
      );
      const loginUserId = props.loginUser.user.id;
      if (workspaceMembers && workspaceMembers.length > 0) {
        let updatedRoleVisibility: orgMemberRoleVisibility[] = [];
        for (let i = 0; i < workspaceMembers.length; i++) {
          updatedRoleVisibility.push({
            id: workspaceMembers[i],
            role: "Admin",
            is_secrete_visible: true,
          });
        }

        if (!loginUserIdExists && loginUserId) {
          updatedRoleVisibility.push({
            id: loginUserId,
            role: "Member",
            is_secrete_visible: false,
          });
        }
        setData({
          ...data,
          orgMemberRoleVisibility: updatedRoleVisibility,
          ManagerId: workspaceMembers,
        });
      }
    });
  }, [data.workspaceId]);

  // getting current step from another component
  const getCurrentStep = (value: number) => {
    setCurrentStep(value);
  };

  // getting first form value
  const getTitle = (value: string) => {
    setData({
      ...data,
      board_title: value,
    });
  };

  // function to get AI generated job description or manual written job description from jobPostInput component
  const getJobDescription = (description: string) => {
    setData({ ...data, job_description: description });
  };

  //before going to previous step saving all values in data
  const handleBack = () => {
    let values = form.getFieldsValue();
    setData({
      ...data,
      salary_currency_type: values.currency_type,
      department: values.department,
      experiance_from: values.experience_from,
      experiance_to: values.experience_to,
      minimum_salary: values.maximum_salary,
      maximum_salary: values.minimum_salary,
      job_type: values.job_type,
      location: values.location,
      workplace_type: values.workplace_type,
      workspaceId: values.workspace,
    });
    setCurrentStep(currentStep - 1);
  };

  // after api fail before go back setting form value in the data
  const handleErrorBack = () => {
    let values = form.getFieldsValue();
    setData({
      ...data,
      salary_currency_type: values.currency_type,
      department: values.department,
      experiance_from: values.experience_from,
      experiance_to: values.experience_to,
      minimum_salary: values.maximum_salary,
      maximum_salary: values.minimum_salary,
      job_type: values.job_type,
      location: values.location,
      workplace_type: values.workplace_type,
      workspaceId: values.workspace,
    });
    setCurrentStep(currentStep - 1);

    // setting api loading status to it's initial state  for fetching api agin
    setApiLoading({
      createOpening: 0,
      addFormToJd: 0,
      createJd: 0,
    });
  };

  // on click on go to tracker button go redirecting to pool
  const history: any = useHistory();
  const handlePublish = async () => {
    props.saveNewOpening(boardRes);
    history.push({
      pathname: `/o/${props.applicationId}/b/${data.boardId}/${TrackerRoute}`,
      method: "post",
    });
  };

  const handleSaveDreaft = () => {
    form.validateFields().then((values) => {
      setDraftSave(true);
      // after validating field user will see api fetch loading
      if (currentStep < items.length - 1) {
        setCurrentStep(currentStep + 1);
      }
      setApiLoading({
        ...apiLoading,
        createOpening: 1,
      });

      // request parameters for create opening api
      const newOpening = {
        name: data.board_title,
        company_id: values.workspace,
        member_only: true,
        color: data.selected_color,
        background_brightness: "DARK",
        member_id: props.loginUser.user.id,
        tenant_id: props.applicationId,
        socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
        default_template: true,
        member_with_access: data.orgMemberRoleVisibility,
        lists: trackerStages
          ?.map((stage) => ({
            name: stage?.list_name,
            label_id: stage?.label_id,
          })),
      };

      // api that create job board
      createOpenings(newOpening)
        .then((res) => {
          setBoardRes(res);
          props.initLists(res.lists ? res.lists : []);

          setApiLoading((apiLoading) => ({
            ...apiLoading,
            createOpening: 2,
            addFormToJd: 1,
          }));

          setData((prevData) => ({
            ...prevData,
            boardId: res.id,
          }));
          props.getActiveBoard(updatedData)

              // request parameters for creating form template api
              const formReq = {
                title: data.board_title,
                status: "Active",

                form_data: data.form_data[0]?.schema,
                creater_id: props.loginUser.user.id,
              };

              let formData = new FormData();
              formData.append("form_data", JSON.stringify(formReq));

              // api that create form template
              createFormTemplate(formData)
                .then((formRes) => {
                  setApiLoading((apiLoading) => ({
                    ...apiLoading,
                    addFormToJd: 2,
                    createJd: 1,
                  }));

                  // request parameters for creating job description
                  const jdReq = {
                    title: data.board_title,
                    company_name: props.applicationName,
                    status: "draft",
                    hide: data.hide,
                    number_of_open_positions: values.number_of_openings,
                    locations: values.location.map((loc: string) => {
                      const [city, state, country] = loc.split(", ");
                      return { city, state, country };
                    }),
                    job_type: values.job_type,
                    workplace_type: values.workplace_type,
                    experience_level_from: values.experience_from,
                    experience_level_to: values.experience_to,
                    salary_currency_type: values.currency_type
                      ? values.currency_type
                      : data.salary_currency_type,
                    salary_range_from: values.minimum_salary,
                    salary_range_to: values.maximum_salary,
                    salary_type: values.salary_type,
                    form_id: formRes.id,
                    board_id: res.id,
                    description: data.job_description,
                    department: values.department,
                    integrations: integration,
                  };
                  const jdFormDta = new FormData();
                  jdFormDta.append("jd_data", JSON.stringify(jdReq));

                  // final  api which will create a job description
                  createJobDescription(jdFormDta)
                    .then((jdRes) => {
                      setApiLoading((apiLoading) => ({
                        ...apiLoading,
                        createJd: 2,
                      }));

                      setData((prevData) => ({
                        ...prevData,
                        jd_public_url: jdRes.jd_public_url,
                      }));
                    })
                    .catch((error) => {
                      console.error(error);
                      setApiLoading((apiLoading) => ({
                        ...apiLoading,
                        createJd: 3,
                      }));
                    });
                })
                .catch((error) => {
                  console.error(error);
                  setApiLoading((apiLoading) => ({
                    ...apiLoading,
                    addFormToJd: 3,
                    createJd: 4,
                  }));
                });
            })
        })
        .catch((error) => {
          console.error(error);
          setApiLoading((apiLoading) => ({
            ...apiLoading,
            createOpening: 3,
            addFormToJd: 4,
            createJd: 4,
          }));
        });
  };

  // after click on publish job description button all this function will call and all api will be called
  const handleJobPostCreation = () => {
    // getting form values and validating it
    form.validateFields().then((values) => {
      // after validating field user will see api fetch loading
      setDraftSave(false);
      if (currentStep < items.length - 1) {
        setCurrentStep(currentStep + 1);
      }
      setApiLoading({
        ...apiLoading,
        createOpening: 1,
      });

      // request parameters for create opening api
      const newOpening = {
        name: data.board_title,
        company_id: values.workspace,
        member_only: true,
        color: data.selected_color,
        background_brightness: "DARK",
        member_id: props.loginUser.user.id,
        tenant_id: props.applicationId,
        socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
        default_template: true,
        member_with_access: data.orgMemberRoleVisibility,
        lists: trackerStages
          .map((stage) => ({
            name: stage.list_name,
            label_id: stage.label_id,
          })),
      };

      // api that create job board
      createOpenings(newOpening)
        .then((res) => {
          setBoardRes(res);
          props.initLists(res.lists ? res.lists : []);

          setApiLoading((apiLoading) => ({
            ...apiLoading,
            createOpening: 2,
            addFormToJd: 1,
          }));

          setData((prevData) => ({
            ...prevData,
            boardId: res.id,
          }));
          props.getActiveBoard(updatedData)


              // request parameters for creating form template api
              const formReq = {
                title: data.board_title,
                status: "Active",

                form_data: data.form_data[0]?.schema,
                creater_id: props.loginUser.user.id,
              };

              let formData = new FormData();
              formData.append("form_data", JSON.stringify(formReq));

              // api that create form template
              createFormTemplate(formData)
                .then((formRes) => {
                  setApiLoading((apiLoading) => ({
                    ...apiLoading,
                    addFormToJd: 2,
                    createJd: 1,
                  }));

                  // request parameters for creating job description
                  const jdReq = {
                    title: data.board_title,
                    company_name: props.applicationName,
                    status: PUBLISH,
                    hide: data.hide,
                    number_of_open_positions: values.number_of_openings,
                    locations: values.location.map((loc: string) => {
                      const [city, state, country] = loc.split(", ");
                      return { city, state, country };
                    }),
                    job_type: values.job_type,
                    workplace_type: values.workplace_type,
                    experience_level_from: values.experience_from,
                    experience_level_to: values.experience_to,
                    salary_currency_type: values.currency_type
                      ? values.currency_type
                      : data.salary_currency_type,
                    salary_range_from: values.minimum_salary,
                    salary_range_to: values.maximum_salary,
                    salary_type: values.salary_type,
                    form_id: formRes.id,
                    board_id: res.id,
                    description: data.job_description,
                    department: values.department,
                    integrations: integration,
                  };
                  const jdFormDta = new FormData();
                  jdFormDta.append("jd_data", JSON.stringify(jdReq));

                  // final  api which will create a job description
                  createJobDescription(jdFormDta)
                    .then((jdRes) => {
                      setApiLoading((apiLoading) => ({
                        ...apiLoading,
                        createJd: 2,
                      }));

                      setData((prevData) => ({
                        ...prevData,
                        jd_public_url: jdRes.jd_public_url,
                      }));
                    })
                    .catch((error) => {
                      console.error(error);
                      setApiLoading((apiLoading) => ({
                        ...apiLoading,
                        createJd: 3,
                      }));
                    });
                })
                .catch((error) => {
                  console.error(error);
                  setApiLoading((apiLoading) => ({
                    ...apiLoading,
                    addFormToJd: 3,
                    createJd: 4,
                  }));
                });
            })
        })
        .catch((error) => {
          console.error(error);
          setApiLoading((apiLoading) => ({
            ...apiLoading,
            createOpening: 3,
            addFormToJd: 4,
            createJd: 4,
          }));
        });
  };

  // on click on next button move to next step
  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        setData({ ...data, board_title: values.board_title, job_description:values.job_description });
        setCurrentStep(1);
      })
      .catch((err: any) => {
        console.log("validation error", err);
      });
  };


  // on click on cancel button if any value enter the show
  // warning wan't to discard changes
  const handlePrev = () => {
    let values = form.getFieldsValue();
    const hasValues = Object.values(values).some(
      (value) => value !== undefined && value !== null && value !== ""
    );
    if (hasValues) {
      setDiscardWarning(true);
    } else {
      props.onCancel();
      setCurrentStep(0);
    }
  };

  // check if job post is created or not if created then enable go to tracker button
  const isJobPostcreated = () => {
    if (apiLoading.createJd === 2) {
      return true;
    } else {
      return false;
    }
  };

  // on click on yes discard changes then resetting form value and closing modal
  const DiscardAllChanges = () => {
    form.resetFields();
    props.onCancel();
    setCurrentStep(0);
    setDiscardWarning(false);
  };

  // on click on not discard then close warning modal
  const dontDiscardChanges = () => {
    setDiscardWarning(false);
  };

  // getting value of save and next button is disable or not

  const getDisableState = (value:boolean)=>{
    setDisableNext(value);
  }

  // this are list of items
  const items = [
    {
      key: "1",
      label: "Create job post",
      children: (
        <JobPostInput
          data={data}
          handleSetData={handleSetData}
          getJobDescription={getJobDescription}
          cancel={props.onCancel}
          getCurrentStep={getCurrentStep}
          getTitle={getTitle}
          formInstance={form}
          getDisableState = {getDisableState}
        />
      ),
    },
    {
      key: "2",
      label: "Job post information",
      children: (
        <JobPostInformation
          data={data}
          handleSetData={handleSetData}
          handleSetIntegrations = {handleSetIntegrations}
          getCurrentStep={getCurrentStep}
          currentStep={currentStep}
          formInstance={form}
          integration={integration}
          getWorkspaceId={getWorkspaceId}
        />
      ),
    },
    {
      key: "3",
      label: "Job post success",
      children: (
        <JobPostSuccess
          handleShowButton={handleShowButton}
          apiLoading={apiLoading}
          data={data}
          draftSave= {draftSave}
        />
      ),
    },
  ];

  return (
    <Modal
      maskClosable={false}
      className="review-modal-content"
      title={
        <div className="header ">
          <div className="py-5 px-8 flex justify-start 2xl:w-1/2 xl:w-2/3 lg:w-2/3 md:w-full  sm:w-full">
            <StepsBar current={currentStep} />
          </div>
          <Divider type="horizontal" className="mx-0 mb-0 mt-0" />
        </div>
      }
      closable={false}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        margin: "20x",
      }}
      // onCancel={props.onCancel}
      open={props.open}
      bodyStyle={{
        width: "70vw",
        height: "67vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        overflow: "hidden",
      }}
      footer={
        <>
          <Space className="pb-5 flex justify-end w-full px-8">
            {currentStep === 0 && (
              <div className="flex ">
                <div className="flex space-x-4">
                  <Button
                    disabled={disableNext}
                    onClick={handlePrev}
                    type="default"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={disableNext}
                    onClick={handleNext}
                    htmlType="submit"
                    type="primary"
                  >
                    Save and next
                  </Button>
                </div>
              </div>
            )}
            {currentStep === 1 && (
              <div className="flex justify-end space-x-4">
                <Button onClick={handleBack}>Back</Button>
                <Button  onClick={handleSaveDreaft}>Save as draft</Button>
                <Button type="primary" onClick={handleJobPostCreation}>
                  Publish
                </Button>
              </div>
            )}

            {currentStep === 2 && (
              <div className="flex justify-end space-x-4">
                {showbutton && <Button onClick={handleErrorBack}>Back</Button>}
                <Button
                  type="primary"
                  disabled={!isJobPostcreated()}
                  onClick={handlePublish}
                >
                  Go to tracker
                </Button>
              </div>
            )}
          </Space>
        </>
      }
    >
      <div
        className="flex"
        style={{
          position: "relative",
          zIndex: 1,
          overflowY: "auto",
          width: "100%",
          maxWidth: "100%",
          height: "100%",
          maxHeight: "100%",
          background: "white",
          backdropFilter: "none",
          transition: "transform 270ms ease",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Form
          layout="vertical"
          form={form}
          className="w-full flex justify-center space-y-4  items-center h-full"
          initialValues={data}
        >
          {items[currentStep].children}
        </Form>
      </div>

      <CancelDiscard
        isPopupVisible={discardWarning}
        onOkay={DiscardAllChanges}
        onCancel={dontDiscardChanges}
      />
    </Modal>
  );
};

const mapPropsToState = (dispatch: Dispatch) => ({
  saveNewOpening: (opening: any) => dispatch(addNewOpening(opening)),
  initLists: (list: List[]) => dispatch(initLists(list)),
  getActiveBoard:(data: TotalBoards)=> dispatch(getActiveBoard(data)),
});

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
  orgMembers: state.opening.members,
  applicationName: state.opening.applicationName,
  totalBoards:state.opening.totalBoards
});

export default connect(mapStateToProps, mapPropsToState)(JobPostCreation);

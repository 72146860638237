import { Views } from "react-big-calendar";
// import { CalendarItem } from "../../../lib/apiRes";
// import { CalendarColorsResponse } from "../../../lib/apiRes";
import { eventItem } from "../../../lib/apiRes";
import { Dayjs } from "dayjs";

export interface ProcessedEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
  allDay: boolean;
  color: string;
  textColor: string;
  originalTitle?: string;
  description?: string;
  location?: string;
  attendees?: any[];
  calendarId: string;
}

interface ProcessedEvents {
  [eventId: string]: ProcessedEvent;
}

/**
 * Safely gets a color value from the colors object, with fallbacks
 * @param colors - Colors response object
 * @param type - Type of color (event or calendar)
 * @param colorId - ID of the color to retrieve
 * @param colorType - Type of color value to get (background or foreground)
 * @returns Color string or default color
 */

/**
 * Converts Google Calendar events to a format compatible with React Big Calendar
 * @param googleEvents - Array of events from Google Calendar API
 * @param colors - Calendar colors configuration
 * @param calendar - Calendar item containing calendar-specific settings
 * @returns Object of processed events keyed by event ID
 */
export const processEvents = (
  googleEvents: eventItem[] = [],
  calendarId: string,
  // colors?: CalendarColorsResponse,
): ProcessedEvents => {
  const processedEvents: ProcessedEvents = {};

  googleEvents.forEach((event: eventItem) => {
    try {
      let startDate: Date, endDate: Date;

      // Safely handle date parsing
      if (event.all_day_event) {
        startDate = new Date(event.start?.date ? `${event.start.date}T00:00:00` : new Date());
        endDate = new Date(event.end?.date ? `${event.end.date}T00:00:00` : new Date());
      } else {
        startDate = new Date(event.start?.date_time || new Date());
        endDate = new Date(event.end?.date_time || new Date());
      }

      const isPrivate = event.visibility === "private";
      // const calendarId = calendarId || 'default';

      processedEvents[event.id] = {
        ...event,
        title: isPrivate ? "Private event" : (event.summary || "Untitled Event"),
        start: startDate,
        end: endDate,
        allDay: !!event.all_day_event,
        color: event.event_color,
        textColor:  getContrastColor(event.event_color),
        originalTitle: isPrivate ? event.summary : undefined,
        description: isPrivate ? undefined : event.description,
        location: isPrivate ? undefined : event.location,
        attendees: isPrivate ? undefined : event.attendees,
      calendarId
      };
    } catch (error) {
      console.error(`Error processing event ${event.id}:`, error);
    }
  });

  return processedEvents;
};

// Color Contrast Utility
function getContrastColor(hexColor: string): string {
  // Handle edge cases
  if (!hexColor || hexColor === 'transparent') {
    return 'black';  // Default safe color
  }

  // Normalize hex color
  const sanitizedColor = hexColor.replace('#', '');
  const r = parseInt(sanitizedColor.slice(0, 2), 16);
  const g = parseInt(sanitizedColor.slice(2, 4), 16);
  const b = parseInt(sanitizedColor.slice(4, 6), 16);

  // Calculate relative luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Advanced contrast determination
  return luminance > 0.5 ? 'black' : 'white';
}


export const getDateRangeForView =
  (date: Dayjs, currentView: string) => {
    let start: Dayjs;
    let end: Dayjs;

    switch (currentView) {
      case Views.MONTH:
        start = date.startOf("month").startOf("week");
        end = date.endOf("month").endOf("week");
        break;
      case Views.WEEK:
      case Views.WORK_WEEK:
        start = date.startOf("week");
        end = date.endOf("week");
        break;
      case Views.DAY:
        start = date.startOf("day");
        end = date.endOf("day");
        break;
      case Views.AGENDA:
        start = date.startOf("month");
        end = date.add(1, "month").endOf("month");
        break;
      default:
        start = date.startOf("month").startOf("week");
        end = date.endOf("month").endOf("week");
    }

    return { start: start.toDate(), end: end.toDate() };
  }

export const getUserTimezone = () => {
  const timezone = {
    name: Intl.DateTimeFormat().resolvedOptions().timeZone,
    offset: new Date().getTimezoneOffset(),
    abbreviation: new Intl.DateTimeFormat('en', { timeZoneName: 'short' })
      .formatToParts(new Date())
      .find(part => part.type === 'timeZoneName')?.value || 'UTC'
  };

  return timezone;
};
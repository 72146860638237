import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { initSearchQuery } from '../../redux/actions/opening/action';

const { Search } = Input;

type SearchBoxProps = {
  initSearchQuery: (query: string) => void;
  searchQuery: any;
};

const PoolSearchBox = ({ initSearchQuery, searchQuery }: SearchBoxProps) => {
  const handleSearch = (value: string) => {
    initSearchQuery(value);
  };

  return (
    <Search
      placeholder="Search..."
      allowClear
      onChange={(e) => handleSearch(e.target.value)}
      value={searchQuery?.query || ''}
      style={{ width: 250 }}
      prefix={<SearchOutlined style={{ color: '#9CA3AF' }} />}
    />
  );
};

const mapStateToProps = (state: any) => ({
  searchQuery: state.opening.searchQuery,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initSearchQuery: (query: string) => dispatch(initSearchQuery(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PoolSearchBox);
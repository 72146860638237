import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {  Divider, Popover, Select, Space, Typography } from "antd";
import { List, Opening, OrganizedBoard,  companyBoards } from "../../../type/type";
import { listOfBoards } from "../../../redux/actions/opening/action";
import { IconButton, LabeledIconButton, SecondaryButton } from "../../../ant/Button";
import { CreateCandidateCard,  getOpeningLists, getOpenings } from "../../../lib/api";
import { throwSuccess } from "../../../service/throwError";
import { CloseOutlined} from "@ant-design/icons";
import Paragraph from "antd/es/typography/Paragraph"
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import JobPostIcon from "../../Icons/JobPostIcon";
const { Text } = Typography

const SendCandidateToTracker = (props: {
  isBulk: any;
  candidateIds: any;
  openings: OrganizedBoard;
  currentCandidateInEditMode: any;
  clientId: string;
  companyBoard: companyBoards[];
  listOfBoards(boardData: Opening[]): void,
    isCollapsed: any;
}) => {
  const {  isBulk, candidateIds, currentCandidateInEditMode} = props;
  const [selectedWorkspace, setSelectedWorkspace] = useState<string | undefined>(undefined)
    const [selectedBoard, setSelectedBoard] = useState<string>("")
    const [boardLists, setBoardLists] = useState<List[]>([])
    const [open, setOpen] = useState(false)
    const [error, setError] = useState<boolean>(false)
    const [loader, showLoader] = useState<boolean>(false)

    useEffect(() => {
        if (open)
            getOpenings()
                .then((openings: Opening[]) => {
                    props.listOfBoards(openings)

                })
        else {
            setSelectedBoard("")
            setSelectedWorkspace("")
        }
    }, [open])
    useEffect(() => {
        if (selectedBoard) {
            getOpeningLists(selectedBoard).then((res: List[]) => {
                if (res.length == 0) {
                    setError(true)
                } else {
                    setError(false)

                    setBoardLists(res)
                }
            })
        }
    }, [selectedBoard])

    const handleWorkspaceChange = (value: string) => {
        setSelectedWorkspace(value)
    }

    const handleBoardChange = (value: string) => {
        setSelectedBoard(value)
    }
    const createReviews = () => {
        showLoader(true)
        const req = {
            candidates: isBulk ? candidateIds : [currentCandidateInEditMode.candidateId.id],
            opening_id: selectedBoard,
        }

        CreateCandidateCard(req).then((res) => {
            if (res) {
                showLoader(false)
                if (req.candidates.length>1) {
                    throwSuccess(`Candidates have been forwarded to the tracker ${props.openings.pageSection.openings[selectedBoard].name}.`)
                } else {
                    throwSuccess(`Candidate have been forwarded to the tracker ${props.openings.pageSection.openings[selectedBoard].name}.`)
                }      
                hide();
            }
        }).catch(() => {
            showLoader(false)
        })

    }
    const hide = () => {
        setOpen(false)
    }
  return (
<Popover
open={open}
title={<div className="flex w-full bg-white justify-between items-center">
    <div>
    </div>
    <Text>{'Send to tracker'}</Text>
    <LabeledIconButton
        type="text"
        label=""
        onClick={hide}
        icon={<CloseOutlined />}
    />
</div>}
content={
    <div className="w-72">
        <Divider className="my-2" />
        <Space direction="vertical">

            <div >
                <Text>{"Select workspace"}</Text>
                <div className="w-full">
                    <Select
                        placeholder="Select workspace"
                        value={selectedWorkspace}
                        onChange={handleWorkspaceChange}
                        onMouseDown={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                        onKeyDown={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                        className="w-full"
                    >
                        {Object.values(props.companyBoard).map(workspace => (
                            <Select.Option key={workspace.company_id} value={workspace.company_id}>
                                {workspace.company_name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
            <div className="w-full">

                <Text>{"Select tracker"}</Text>
                <div>
                    <Select
                        placeholder="Select tracker"
                        value={selectedBoard}
                        onChange={handleBoardChange}
                        className="w-72"

                    >
                        {/* Render board options based on selected workspace */}
                        {selectedWorkspace &&
                            Object.values(props.companyBoard)
                                .find(workspace => workspace.company_id === selectedWorkspace)
                                ?.boards.map(board => (
                                    <Select.Option key={props.openings.pageSection.openings[board].id} value={props.openings.pageSection.openings[board].id}>
                                        {props.openings.pageSection.openings[board].name}
                                    </Select.Option>
                                ))}
                    </Select>
                </div>
            </div>
            {selectedBoard && boardLists ? (
                <>

<Paragraph style={{ fontSize: "13px" }}>
                                    {capitalizeFirstLetter(
                                        `candidates card will be added in the initial list in job tracker`
                                    )}{" "}
                                    <Text strong style={{ fontSize: "13px" }}>
                                        {props.openings.pageSection.openings[selectedBoard].name}
                                    </Text>.
                                    <br />
                                    <Text style={{ fontSize: "13px" }}>
                                        {capitalizeFirstLetter("with all their details, attachments and labels automatically")}
                                    </Text>
                                </Paragraph>
                </>
            ) : null}
            <div>
                {error && <div style={{ color: "red" }}>List is empty</div>
                }

            </div>
            <div className="justify-end flex">
                <LabeledIconButton loading={loader} label="Send" type="primary" onClick={createReviews}     disabled={!selectedWorkspace || !selectedBoard}
                />
            </div>
        </Space >

    </div >
}
onOpenChange={(open) => setOpen(open)}
trigger={"click"}
placement="bottom"
className="w-full"
>
      {!isBulk ? (
        <SecondaryButton
        onClick={()=>{}} 
        className="w-full truncate left-align-button"
        icon={<JobPostIcon />}
        >Send to tracker</SecondaryButton>
      ) : (
        <IconButton
        tooltipTitle="Send to tracker"
          icon={
              <JobPostIcon />
          }
          className="h-7 w-7"
          onClick={(e: { preventDefault: () => void; stopPropagation: () => void; }) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      )}
    </Popover>
  );
};

const mapStateToProps = (state: {
  opening: {
    currentCandidateInPool: any;
    currentOpening: any;
    openings: any;
    currentCandidateInEditMode: any;
    clientId: any;
    companyBoards: any;
    companies: any;
  };
}) => ({
  openings: state.opening.openings,
  currentCandidateInEditMode: state.opening.currentCandidateInEditMode,
  clientId: state.opening.clientId,
  companyBoard: state.opening.companyBoards,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  listOfBoards: (boardData: Opening[]) => dispatch(listOfBoards(boardData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendCandidateToTracker);

import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import EditIcon from "../../../../assets/Edit";
import {
  addBoardLabelInCard,
  removeLabelFromCard,
} from "../../../../redux/actions/opening/action";
import {
  InitLabel,
  currentApplicant,
  GetOpening,
  Label,
  KeyWithArray,
} from "../../../../type/type";
import { determineTextColor } from "../../../../utils/CalculateBrightnessOfColor"
import TickIcon from "../../../Icons/TickIcon";
// import CheckedLabel from "./CheckedLabel";
export type LabelPreviewProps = {
  currentApplicant: currentApplicant;
  currentOpening: GetOpening;
  labels: InitLabel;
  flag?: boolean;
  updateLabel?: (label: Label) => void;
  applicantLabels: KeyWithArray;
  clientId: string;
  label: Label;
  clickOnLabel: (label: Label) => void;
  searchInArray?: any[];
  searchInObject?: InitLabel | undefined;
};
const LabelPreview = (props: LabelPreviewProps) => {
  return (
    <div className="flex my-1 mx-2 space-x-1">
        <div
          onClick={
            props.flag ? () => {} : () => props.clickOnLabel(props.label)
          }
          role="button"
          tabIndex={0}
          data-testid="label-preview"
        onKeyDown={() => { }}
        style={{ backgroundColor: props.label.color }}
        className={`flex justify-between bg-color-${props.label.color}  w-full px-3 py-1.5  border-l-8 border-transparent  hover:border-color-${props.label.color}  hover:border-opacity-60 bg-opacity-80  rounded`}
        >
          {/* <span className=" w-48 truncate font-Inter text-sm  text-shadow">
                {props.label.name}
              </span> */}
        <span className="w-44 text-sm truncate mr-2 focus:outline-none" style={{ color: determineTextColor(props.label.color) }}>
            {props.label.name}
          </span>

          {props.flag
            ? null
            : props.searchInArray &&
              props.searchInArray.map((l: string) =>
                props.searchInObject ? (
                  l === props.searchInObject[props.label.id].id ? (
                    <TickIcon style={{color:'#ffff'}}  />
                  ) : null
                ) : null
              )}
        </div>
      {props.updateLabel &&
        <button
          data-testid="update-label"
          onClick={() => {
            if (props.updateLabel) props.updateLabel(props.label);
          }}
          tabIndex={0}
        >
          <EditIcon />
        </button>
      }
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  labels: state.opening.labels,
  currentApplicant: state.opening.currentApplicant,
  currentOpening: state.opening.currentOpening,
  applicantLabels: state.opening.applicantLabels,
  clientId: state.opening.clientId,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  saveApplicantLabel: (label: any, id: string, listID: string) =>
    dispatch(addBoardLabelInCard(label, id, listID)),
  removeLabel: (labelID: string, applicantID: string, list_id: string) =>
    dispatch(removeLabelFromCard(labelID, applicantID, list_id)),
});
export default connect(mapStateToProps, mapPropsToState)(LabelPreview);

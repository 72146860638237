import React, { useState } from "react";
import { connect } from "react-redux";
import { currentApplicant, eSignDetails, Jd } from "../../../type/type";
import GetDocumentById from "./GetDocumentById";
import { Button, Spin, Typography } from "antd";
import {
  getAllDocumentsByJd,
  getEmbeddedViewUrl,
  saveDocumentsAgainstCandidate,
} from "../../../lib/api";
import { Dispatch } from "redux";
import {
  setEsignDetails,
  updateEsignDetails,
} from "../../../redux/actions/opening/action";
import { throwFrontEndError, throwSuccess } from "../../../service/throwError";
import { useHistory } from "react-router-dom";
import SendForSignaturesPage from "./ZohoSign/UploadDocument";

const { Text } = Typography;

type ESignProps = {
  zohoSignLoading?: boolean;
  candidateId?: string;
  eSignDetails: eSignDetails;
  applicationId: string;
  currentApplicant: currentApplicant;
  docLoading: boolean;
  currentTrackerJd: Jd;
  setEsignDetails: (data: eSignDetails) => void;
  updateEsignDetails: (data: eSignDetails) => void;
};

const ShowDocumentStatus: React.FC<ESignProps> = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [embeddedUrlLoading, setEmbeddedUrlLoading] = useState<boolean>(false);
  const [currentWindow, setCurrentWindow] = useState<Window | null>(null);
  const [zohoEsignLoading, setZohoESignLoading] = useState<boolean>(false);

  const handleMessage = (event: MessageEvent) => {
    if (event.origin !== window.location.origin) return;
    
    if (event.data.type === "e_sign_doc_redirection") {
      const req = {
        candidate_id: props.currentApplicant.candidate_id,
        document_id: event.data.result,
        jd_id: props.currentTrackerJd.id,
      };
      setLoading(true);
      saveDocumentsAgainstCandidate(req)
        .then(() => {
          throwSuccess("document saved successfully");
          getAllDocumentsByJd(
            props.currentTrackerJd.id,
            props.currentApplicant.candidate_id
          )
            .then((res) => {
              setLoading(false);
              if (res.document) {
                props.updateEsignDetails({ documents: res.document });
                return;
              }
            })
            .catch(() => {
              setLoading(false);
              console.log("Failed to get all documents");
            });
        })
        .catch(() => {
          setLoading(false);
          console.error("Failed to save document against candidate.");
        });

      if (currentWindow && !currentWindow.closed) {
        currentWindow.close();
      }
      window.removeEventListener("message", handleMessage);
    } else if (event.data.type === "e_sign_doc_redirection_error") {
      throwFrontEndError(event.data.error);
      if (currentWindow && !currentWindow.closed) {
        currentWindow.close();
      }
      window.removeEventListener("message", handleMessage);
    }
  };

  const getEmbeddedView = () => {
    if(props.eSignDetails.tools && props.eSignDetails.tools[0]==="zohosign"){
      setShowModal(true);
      return;
    }
    setLoading(false);
    setEmbeddedUrlLoading(false);
    setCurrentWindow(null);
    setEmbeddedUrlLoading(true);
    getEmbeddedViewUrl()
      .then((url) => {
        const newCurrentWindow = window.open(
          url,
          "SaveEsignDocument",
          "width=600,height=600,resizable=yes,scrollbars=yes"
        );
        setEmbeddedUrlLoading(false);
        window.addEventListener("message", handleMessage);
        setCurrentWindow(newCurrentWindow);
      })
      .catch((error) => {
        setEmbeddedUrlLoading(false);
        throwFrontEndError("Error while getting embedded view URL");
        console.error("Failed to get embedded view URL:", error);
      });
  };

  if ((loading || props.docLoading)||zohoEsignLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Spin size="small" />
      </div>
    );
  }

  const buttonText = props.eSignDetails?.documents && props.eSignDetails?.documents?.length <= 0
    ? "Send document for sign"
    : "New document";

  return (
    <div className="relative">
      {props.eSignDetails?.connected &&(
        <div className="sticky top-0  left-0  bg-white p-4 border-b">
        <Button
          loading={embeddedUrlLoading}
          onClick={getEmbeddedView}
          type="primary"
          className="flex items-center"
        >
          <Text ellipsis style={{ color: "white", margin: 0, maxWidth: 240 }}>
            {buttonText}
          </Text>
        </Button>
      </div>)}
      <div className="overflow-y-auto px-4"
      style={{ height: "calc(-292px + 100vh)" }}
      >
        {!props.eSignDetails?.connected ? (
          <div className="text-center mt-4">
          <Text>
            To send document for signature, you need to{" "}
            <Button className="p-0" type="link" onClick={()=>history.push(`/o/${props.applicationId}/settings/e-sign`)} >
              integrate
            </Button>{" "}
            one of the tools with Tacitbase.
          </Text>
          </div>
        ) : (
          <div className="max-w-4xl mx-auto">
            {props.eSignDetails.documents &&
              props.eSignDetails.documents.length > 0 && (
                <Text type="secondary" className="block text-center my-2">
                  Review the details of your document and sign as needed.
                </Text>
              )}
            {props.eSignDetails.documents &&
              props.eSignDetails.documents.length <= 0 && (
                <div className="text-center text-gray-600 mt-4">
                  <Text>
                    You are connected and ready to send documents for signature.
                  </Text>
                </div>
              )}
            <div className="flex flex-col gap-4">
              {props.eSignDetails?.documents?.map((document) => (
                <div className="w-full" key={document.id}>
                  <GetDocumentById doc={document} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <SendForSignaturesPage 
       showModal={showModal} 
       setShowModal={setShowModal} 
       setZohoESignLoading = {setZohoESignLoading}
       name = {props.currentApplicant?.name || ""}
       email={props.currentApplicant.source?.email || ""} />
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setEsignDetails: (data: eSignDetails) => dispatch(setEsignDetails(data)),
  updateEsignDetails: (data: eSignDetails) => dispatch(updateEsignDetails(data)),
});

const mapStateToProps = (state: any) => ({
  eSignDetails: state.opening.eSignDetails,
  applicationId: state.opening.applicationId,
  currentApplicant: state.opening.currentApplicant,
  currentTrackerJd: state.opening.currentTrackerJd,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowDocumentStatus);
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography, Space, Alert } from 'antd';

const { Text } = Typography;

interface SlackNotIntegratedContentProps {
  applicationId: string;
  slackIntegrationMessage:string;
}

const SlackNotIntegratedContent: React.FC<SlackNotIntegratedContentProps> = ({ slackIntegrationMessage,applicationId }) => {
  const history: any = useHistory();

  const backToSetting = () => {
    history.push({
      pathname: `/o/${applicationId}/settings/general`,
    });
  };

  return (
    <Space direction="vertical" size="middle" className="h-full p-4 w-full 2xl:w-1/2 xl:w-3/4">
      <Text className="text-color-black font-medium">
        Send job post notifications to Slack channel
      </Text>
      <Text >
        Looks like the Tacitbase app has not been added to your Slack workspace yet. To get started, please add the Tacitbase app to your workspace.
      </Text>
      <Text   
       onClick={backToSetting} 
       style={{ color: '#1677ff', cursor: 'pointer' }}
      >Integrate your Slack workspace</Text>
       {slackIntegrationMessage.length > 0 && (
                <Alert type="warning" message={slackIntegrationMessage} showIcon />
              )}
    </Space>
  );
}

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
});

export default connect(mapStateToProps)(SlackNotIntegratedContent);

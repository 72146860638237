import React, { useEffect, useState } from "react";
import TacitbaseAvtar from "../../ant/Avtar";
import { customeFilterType, searchDynamicQuery } from "../../type/type";
import { IS, operatorMap } from "../../constant";
import { CheckOutlined } from "@ant-design/icons";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { connect } from "react-redux";

interface SourceFilterProps {
  sources: Record<string, { profile?: string; name: string }>;
  searchQuery: searchDynamicQuery;
  onFilterChange: (filters: customeFilterType[]) => void;
  filterColumn?: string;
}

const SourceFilter: React.FC<SourceFilterProps> = ({
  sources,
  searchQuery,
  onFilterChange,
  filterColumn = "sources.from"
}) => {
  const [selectedSource, setSelectedSource] = useState<string | null>(null);

  useEffect(() => {
    if (searchQuery?.filters?.length > 0) {
      const existingFilter = searchQuery.filters.find(
        (filter: customeFilterType) => filter.column === filterColumn
      );
      if (existingFilter) {
        setSelectedSource(existingFilter.value);
      }
    } else {
      setSelectedSource(null);
    }
  }, [searchQuery?.filters, filterColumn]);

  const handleSourceClick = (sourceId: string) => {
    const currentFilters = searchQuery?.filters || [];

    if (selectedSource === sourceId) {
      const updatedFilters = currentFilters.filter(
        (filter: customeFilterType) => filter.column !== filterColumn
      );
      onFilterChange(updatedFilters);
      setSelectedSource(null);
    } else {
      const newFilter = {
        column: filterColumn,
        operator: operatorMap[IS],
        value: sourceId,
        view: "label",
        operatorName: IS,
        id: sourceId,
      };
      const updatedFilters = [
        ...currentFilters.filter(
          (filter: customeFilterType) => filter.column !== filterColumn
        ),
        newFilter,
      ];
      onFilterChange(updatedFilters);
      setSelectedSource(sourceId);
    }
  };

  return (
    <div className="overflow-y-scroll flex flex-col space-y-2 w-72 max-h-72 pr-2">
      {Object.entries(sources).map(([id, source]) => (
        <div
          key={id}
          className={`flex items-center px-2 py-1 cursor-pointer rounded-md ${
            selectedSource === id ? "bg-tacitbase-secondary6" : "hover:bg-gray-100"
          }`}
          onClick={() => handleSourceClick(id)}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
        >
          <div className="ml-1">
            {source.profile ? (
              <img
                src={source.profile}
                alt={source.name}
                style={{ width: 20, height: 20, borderRadius: "50%" }}
              />
            ) : (
              <TacitbaseAvtar content={source.name[0]} />
            )}
          </div>
          <div className="pl-2 flex-1">{capitalizeFirstLetter(source.name)}</div>
          {selectedSource === id && <CheckOutlined />}
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  sources: state.opening.candidateSources,
});
export default connect(mapStateToProps)(SourceFilter);
import { Empty } from "antd";
import React from "react";


const SearchBefore = () => {
  
  return (
    <>
      <div
        className="flex h-full w-full text-black  divide-x divide-tacitbase-tertiary1 divide-opacity-30 "
        style={{ height: "534px" }}
      >
        <div className="w-full h-full items-center justify-center flex">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>No data available</span>}
          />{" "}
        </div>
      </div>
    </>
  );
};


export default (SearchBefore);


import { Input, Result, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { Status, jdCreationObject } from "./Types";
import {
  ExportOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import SuccessSkeleton from "./SuccessSkeleton";
import { LabeledIconButton } from "../../ant/Button";
import { JOB_POST_PUBLISHED_SUCCESS, SAVE_DRAFT_SUCCESS } from "../../constant";
import CopyIcon from "../Icons/CopyIcon";


type JobPostSuccessProps = {
  apiLoading: Status;
  handleShowButton:(value:boolean) => void;
  data: jdCreationObject;
  draftSave: boolean;
};

const JobPostSuccess: React.FC<JobPostSuccessProps> = ({
  apiLoading,
  handleShowButton,
  data,
  draftSave,
}) => {
  const [loadingSteps, setLoadingSteps] = useState({
    createOpening: false,
    addFormToJd: false,
    createJd: false,
  });
  const [tooltipVisible, setTooltipVisible] = useState(false)


  // function to redirect to the job post link
  function openRoute() {
    const route = data.jd_public_url;
    window.open(route, "_blank");
  }
// updating the loading state depends on the api status
  const updateLoadingSteps = (key: string) => {
    setLoadingSteps((prevSteps) => ({ ...prevSteps, [key]: true }));
  };


  useEffect(() => {
    const checkApiStatusAndProceed = (key: string, status: number) => {
      if (status === 2) {
        setTimeout(() => updateLoadingSteps(key));
      } else if (status === 3 || status === 4) {
        handleShowButton(true);
        return;
      }
    };

    checkApiStatusAndProceed("createOpening", apiLoading.createOpening);
    checkApiStatusAndProceed("addFormToJd", apiLoading.addFormToJd);
    checkApiStatusAndProceed("createJd", apiLoading.createJd);
  }, [apiLoading]);


  // check if all api's are proceed or not
  const isAllApisProcessed = () => {
    return Object.values(loadingSteps).every((status) => status);
  };
// function that copies the jd link
  const handleCopy = () => {
    const textToCopy = data.jd_public_url?data.jd_public_url : "Not available"
    navigator.clipboard.writeText(textToCopy).then(() => {
        setTooltipVisible(true)
        setTimeout(() => setTooltipVisible(false), 1000)
    })
}

  return (
    <>
      {isAllApisProcessed() ? (
        <div className="flex flex-col w-full justify-center items-center">
          <div className="w-8/12">
            <div className="w-full overflow-y-scroll">
              <Result
                status="success"
                title={draftSave?(<span className="text-sm" style={{ color: "#009D79" }}>
                  Your job post has been saved as a draft. 
                </span>):(
                  <span className="text-sm" style={{ color: "#009D79" }}>
                    Your job has been published successfully!
                  </span>
                  ) 
                }
                subTitle={
                  <span>
                  {draftSave ? SAVE_DRAFT_SUCCESS: JOB_POST_PUBLISHED_SUCCESS  } 
                  </span>
                }
              />
            </div>
            <div className="flex flex-col items-center">
              {!draftSave &&(<div className="w-full max-w-lg mb-6">
                <span className="block mb-2 font-bold text-gray-700 text-center">
                  Job post URL
                </span>
                <div className="w-full flex space-x-2 justify-start items-center">
                    <Input readOnly addonBefore={<LinkOutlined />} addonAfter={<Tooltip title="Copied to clipboard!" visible={tooltipVisible}>
                        <CopyIcon onClick={handleCopy} />
                    </Tooltip>} defaultValue={data.jd_public_url} />
                    <LabeledIconButton onClick={openRoute} label={""} icon={<ExportOutlined />} />
                </div>
              </div>)}
              <div className="w-full  flex  space-y-2 flex-col justify-center items-center ">
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SuccessSkeleton draftSave={draftSave} loadingSteps = {loadingSteps}/>
      )}
    </>
  );
};

export default JobPostSuccess;

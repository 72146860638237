import React, { useState } from "react";
import { Menu, Layout, ConfigProvider } from "antd";
import ChangeBoardBackground from "./trackerSettings/ChangeBoardBackground";
import ArchivedItemsIndex from "./trackerSettings/ArchivedItemsIndex";
import TrackerLabel from "./trackerSettings/TrackerLabel";
import AnalyticsLabels from "./trackerSettings/AnalyticsLabels";
import SlackNotificationModalContent from "../../../subNavbar/SlackNotificationModalContent";
import ArchiveBoard from "../../../subNavbar/Archive/ArchiveBoard";

const { Sider, Content } = Layout;

const SettingsPanel = () => {
  // State to keep track of the currently active tab key
  const [activeKey, setActiveKey] = useState("1-1");

  // Handler for menu item click to update the active tab key
  const handleMenuClick = (e: any) => {
    setActiveKey(e.key);
  };

  // Configuration for tabs and their respective sub-tabs
  const tabsConfig = [
    {
      label: "Tracker Settings",
      key: "1",
      subTabs: [
        { label: "Background", key: "1-1", content: <ChangeBoardBackground /> },
        { label: "Labels", key: "1-2", content: <TrackerLabel /> },
        { label: "Archived items", key: "1-3", content: <ArchivedItemsIndex /> },
      ],
    },
    
    {
      label: "Analytics settings",
      key: "2",
      subTabs: [
        { label: "Analytics labels", key: "2-1", content: <AnalyticsLabels /> },
      ],
    },
    {
      label: "Archive settings",
      key: "3",
      Content: <ArchiveBoard/>
    },
    {
      label: "Notification settings",
      key: "4",
      subTabs: [
        { label: "Slack", key: "4-1", content: <SlackNotificationModalContent /> },
      ],
    }
  ];

    // Function to render the content of the active tab/sub-tab
    const renderContent = () => {
      for (const tab of tabsConfig) {
        if (tab.subTabs) {
          for (const subTab of tab.subTabs) {
            if (subTab.key === activeKey) {
              return subTab.content;
            }
          }
        } else if (tab.key === activeKey) {
          return tab.Content;
        }
      }
      return null;
    };
  

  return (
    <div className="h-full flex flex-col">
      {/* Placeholder for tab action (could be breadcrumb, etc.) */}
      {/* <TabAction>
        <></>
      </TabAction> */}
      <div className="px-4 pb-4">
        <div className="bg-white rounded">
        <Layout 
          style={{ borderRadius:4,
            height: "calc(-118px + 100vh)",
          }}
        >
          <Sider width={200} className="site-layout-background border-r h-full ">
            <ConfigProvider
              theme={{
                components: {
                  Menu: {
                    colorText: "black",
                    colorBgContainer: undefined,
                    itemBg: "white",
                    /* here is your component tokens */
                  },
                },
              }}
            >
              <Menu
                mode="inline" className=" rounded-bl rounded-tl h-full overflow-y-scroll"
                defaultSelectedKeys={["1-1"]}
                defaultOpenKeys={["1", "2", "3","4"]} // Ensure all menus are expanded by default
                style={{ height: "100%", borderRight: 0 }}
                onClick={handleMenuClick}
              >
                 {tabsConfig.map((tab) =>
                    tab.subTabs ? (
                      <Menu.SubMenu key={tab.key} title={tab.label}>
                        {tab.subTabs.map((subTab) => (
                          <Menu.Item key={subTab.key}>{subTab.label}</Menu.Item>
                        ))}
                      </Menu.SubMenu>
                    ) : (
                      <Menu.Item key={tab.key}>{tab.label}</Menu.Item>
                    )
                  )}
              </Menu>
            </ConfigProvider>
          </Sider>
            <Content className="h-full bg-white rounded">{renderContent()}</Content>
        </Layout>
        </div>
      </div>
    </div>
  );
};

export default SettingsPanel;

import React, { useEffect } from "react";

import { useParams } from "react-router-dom"
import { connect } from "react-redux"
import { getSingleReviewCandidate } from "../../lib/api"
import { Dispatch } from "redux"
import parse from "html-react-parser"
import {
    CandidateDetailReview,
    InitLabel,
    Label,
    orgMember,
} from "../../type/type"
import { initCurrentCandidateInReview } from "../../redux/actions/opening/action"
import {
    Avatar,
    Collapse,
    ConfigProvider,
    Divider,
    Flex,
    List,
    Space,
    Tag,
    Typography,
} from "antd"
import TacitbaseAvtar from "../../ant/Avtar"
import {
    capitalizeFirstLetter,
    generateTooltipForMember,
} from "../../utils/capitalizeFirstLetter"
import { PhoneOutlined } from "@ant-design/icons"
import DetailReviewAttachments from "./DetailReviewAttachments"
import { determineTextColor } from "../../utils/CalculateBrightnessOfColor"
import { NOT_AVAILABLE, predefinedFields } from "../../constant";
import { SocialLinks } from "../CDB/CandidateInCDBDetails";
import MailIcon from "../Icons/MailIcon";
const { Title, Text } = Typography
const DetailedReviewContent = (props: {
  applicationId: string;
  initCurrentCandidateInReview: (candidate: CandidateDetailReview) => void;
  currentCandidateInReview: CandidateDetailReview | any;
  members: orgMember;
  reviewLabels: InitLabel;
  sources:{[key:string]:{name:string,profile:string}},
}) => {
  const params: { review_id: string } = useParams();
  useEffect(() => {
    getSingleReviewCandidate(params.review_id).then((res) => {
      props.initCurrentCandidateInReview(res);
    });
  }, [params.review_id]);

  const createdByMember = props.currentCandidateInReview.created_by;

  const customKeys =
    Object.keys(props.currentCandidateInReview.candidates.customs).length > 0
      ? Object.keys(props.currentCandidateInReview.candidates.customs)
      : [];
  const otherMembers = props.currentCandidateInReview.members.filter(
    (member: any) => props.members[member] && member !== createdByMember
  );

  const social_links=predefinedFields.map(link=>({
    ...link,
    // icon:SOCIAL_LINKS_ICON[link.name],
    iconLink:props.currentCandidateInReview.candidates[link.name]
  }))
  return (
    <><div className="flex">
      <Title level={4} className="space-x-2 ">
        <span>{props.currentCandidateInReview.candidates.first_name}</span>
        <span>{props.currentCandidateInReview.candidates.last_name}</span>
        </Title>
        
      </div>
      <Space direction="vertical">
        <div className="flex flex-row items-center  justify-between">
          <div>
          <Avatar.Group size={"small"}>
            {props.members[createdByMember] && (
              <>
                <TacitbaseAvtar
                  key={createdByMember}
                  src={props.members[createdByMember].image_url}
                  toolTip={generateTooltipForMember(
                    props.members[createdByMember]
                  )}
                ></TacitbaseAvtar>
              </>
            )}
          </Avatar.Group>
          {otherMembers.length > 0 && createdByMember && (
            <Divider type="vertical" style={{ borderColor: "gray" }} />
          )}
          <Avatar.Group size={"small"} maxCount={4}>
            {otherMembers.map(
              (memberId: string) =>
                memberId !== createdByMember &&
                props.members[memberId] &&
                props.members[memberId].registrations[0].data.activated &&
                (props.members[memberId].image_url ? (
                  <TacitbaseAvtar
                    key={memberId}
                    src={props.members[memberId].image_url}
                    toolTip={generateTooltipForMember(props.members[memberId])}
                  ></TacitbaseAvtar>
                ) : (
                  <TacitbaseAvtar
                    key={memberId}
                    content={
                      props.members[memberId].first_name[0] +
                      props.members[createdByMember].last_name[0]
                    }
                    toolTip={
                      props.members[memberId].first_name +
                      props.members[createdByMember].last_name
                    }
                  ></TacitbaseAvtar>
                ))
            )}
          </Avatar.Group>
          </div>
          <div className="flex flex-row items-center mr-2">
          <SocialLinks links={social_links} />
          </div>
        </div>
        <Flex gap="4px 0" wrap="wrap">
          {props.currentCandidateInReview.labels.length > 0
            ? props.currentCandidateInReview.labels.map((label: Label) => (
                <Tag
                  color={props.reviewLabels[label.id].color}
                  className="flex items-center h-7 max-w-full"
                  key={label.id}
                >
                  <Text
                    ellipsis={{ tooltip: props.reviewLabels[label.id].name }}
                    style={{
                      color: determineTextColor(
                        props.reviewLabels[label.id].color
                      ),
                      maxWidth: "100%",
                    }}
                  >
                    {props.reviewLabels[label.id].name}
                  </Text>
                </Tag>
              ))
            : null}
        </Flex>
      </Space>

      <Divider className="my-2" />
      <div>
        <Flex style={{ width: "100%" }} className="space-x-2">
          <div style={{ width: "100%" }} className="space-x-2 flex">
            <Text strong>Source:</Text>
            <Text
                    style={{
                      width: "70%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    ellipsis={{ tooltip: props.sources[props.currentCandidateInReview.candidates.source.from]? capitalizeFirstLetter(props.sources[props.currentCandidateInReview.candidates.source.from].name):capitalizeFirstLetter(props.currentCandidateInReview.candidates.source.from)}}
                  >
                     {(() => {
                      const sourceKey = props.currentCandidateInReview.candidates.source.from ;
                      if ( sourceKey?.trim()?.length==0) {
                        return NOT_AVAILABLE;
                      }
                      return props.sources[sourceKey] ? capitalizeFirstLetter(props.sources[sourceKey].name) : capitalizeFirstLetter(sourceKey);
                    })()}
                  </Text>
          </div>
          <Text
                  style={{
                    width: "80%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  ellipsis={{ tooltip: props.currentCandidateInReview.candidates.source.medium && props.currentCandidateInReview.candidates.source.medium }}
                  className="space-x-2"
                >
                  <Text strong>Medium:</Text>
                  <Text disabled={props.currentCandidateInReview.candidates.source.medium ? false : true}>
                    {props.currentCandidateInReview.candidates.source.medium && capitalizeFirstLetter(props.currentCandidateInReview.candidates.source.medium) || "Not available"}
                  </Text>
                </Text>
        </Flex>
      </div>

      <Divider className="my-2" />
      <Space direction="vertical">
        <div>
          {" "}
          <Text strong>Candidate details</Text>
          <Flex style={{ width: "100%" }} className="space-x-2">
            <div style={{ width: "100%" }} className="space-x-2 flex">
              <MailIcon />
              <Text
                style={{
                  width: "100%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                copyable={{ tooltips: false }}
                ellipsis={{
                  tooltip: props.currentCandidateInReview.candidates.email,
                }}
              >
                {props.currentCandidateInReview.candidates.email
                  ? props.currentCandidateInReview.candidates.email
                  : "Not available"}
              </Text>
            </div>
            <Text
              style={{
                width: "80%",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              ellipsis={{
                tooltip: props.currentCandidateInReview.candidates.phone,
              }}
              className="space-x-2"
            >
              <PhoneOutlined />
              <Text
                disabled={
                  props.currentCandidateInReview.candidates.phone ? false : true
                }
              >
                {props.currentCandidateInReview.candidates.phone
                  ? props.currentCandidateInReview.candidates.phone
                  : "Not available"}
              </Text>
            </Text>
          </Flex>
        </div>

        <DetailReviewAttachments />
        {props.currentCandidateInReview.description && (
          <ConfigProvider
            theme={{
              components: {
                Collapse: {
                  headerPadding: "6px 0px",
                },
              },
            }}
          >
            <Collapse ghost>
              <Collapse.Panel
                header={<Text strong>Description</Text>}
                key={"Description"}
              >
                <p>
                  {parse(props.currentCandidateInReview.candidates.description)}
                </p>
              </Collapse.Panel>
            </Collapse>
          </ConfigProvider>
        )}
      </Space>
      <Divider className="my-2" />
      {Object.keys(props.currentCandidateInReview.candidates.customs).length >
        0 && (
        <List
          itemLayout="horizontal"
          dataSource={customKeys.map((key: string) => ({
            title: key,
            content:
              props.currentCandidateInReview.candidates.customs[key]
                ? props.currentCandidateInReview.candidates.customs[key]
                : "Not available",
          }))}
          renderItem={(item: any) => (
            <List.Item style={{ padding: "8px 0px" }}>
              <List.Item.Meta title={item.title} description={item.content} />
            </List.Item>
          )}
        />
      )}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  currentCandidateInReview: state.opening.currentCandidateInReview,
  members: state.opening.members,
  reviewLabels: state.opening.reviewLabels,
  sources: state.opening.candidateSources,

});
const mapPropsToState = (dispatch: Dispatch) => ({
  initCurrentCandidateInReview: (candidate: CandidateDetailReview) =>
    dispatch(initCurrentCandidateInReview(candidate)),
});
export default connect(mapStateToProps, mapPropsToState)(DetailedReviewContent);

import React from "react";
import { Tabs } from "antd";

const { TabPane } = Tabs;

type SearchTabProps = {
  setQueryBy: (operator: string) => void;
  activeKey: string;
};

const SearchTab = (props: SearchTabProps) => {
  // Define custom tabs with different keys and labels
  const tabs = [
    { key: "tacitbase", label: "All" },
    { key: "ats", label: "Job posts" },
    { key: "candidates_candidates", label: "Talent pool" },
    // { key: "mail", label: "Mails" },
    { key: "candidates_reviews", label: "Reviews" },
  ];

  return (
    <div className="w-full px-4">
      <Tabs
        activeKey={props.activeKey || ""}
        onChange={props.setQueryBy}
        tabBarStyle={{ marginBottom: 0 }} // Adjust the margin as needed
        size="small"
      >
        {tabs.map((tab) => (
          <TabPane
            tab={tab.label} // Custom label for each tab
            key={tab.key}   // Custom key for each tab
          >
            {/* The content here can be empty since we're only using the tab for selection */}
            <span className="hidden"></span>
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default SearchTab;

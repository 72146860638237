import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { NAV_TEXTBOX_PLACEHOLDER } from "../../constant";
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Dispatch } from "redux";
import { resetSearch, showSearch } from "../../redux/actions/opening/action";
import { Overlay } from "../hook/Overlay";
import { Opening, Scope } from "../../type/type.d";
import {  IconButton, LabeledIconButton } from "../../ant/Button";
import TacitBaseModal from "../../ant/TacitbaseModal";
import SearchPanal from "../AdvancedSearch/SearchPannel";
import { Divider, Tag, Tooltip } from "antd";
type NavSearchProps = {
  setSearchFlag(): void;
  resetSearchFlag(): void;
  search: boolean;
  color?:string;
  isCollaps:boolean;
  currentOpening:Opening
};
const NavSearch = (props: NavSearchProps) => {
  const refer = useRef(null);

  // Reset the modal state on component mount
  useEffect(() => {
    props.resetSearchFlag();
  }, []);

   // Handle opening modal on pressing '/'
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // Check if any input or textarea is focused to avoid interfering with typing
      const activeElement = document.activeElement;
      const isInputFocused =
        activeElement && (activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA" || activeElement.getAttribute("contenteditable") === "true");

      // Open the modal only if no input is focused and the modal isn't already open
      if (event.key === '/' && !isInputFocused && !props.search) {
        event.preventDefault(); // Prevent default behavior of '/'
        props.setSearchFlag();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [props.search]);


  return (
    <>
      <div className="w-full justify-between items-center focus:outline-none" 
       style={{
       backgroundColor: props.currentOpening.color || 'transparent',
      backgroundImage: props.currentOpening.background_image
       ? `url(${props.currentOpening.background_image})`
       : 'none',
      borderRight: !props.currentOpening.color && !props.currentOpening.background_image 
        ? '1px solid #e8e8e8' // Add your desired border style here
       : 'none',
       }} >
        <>
          <div
           className="w-full justify-between items-center">
          {!props.isCollaps?(
            <Tooltip
            title={
              <>
                Type <Tag color="blue">/</Tag> to search
              </>
            }
          >
            <LabeledIconButton
              // type="text"
              // tooltipTitle="Type  /  for search"
              label={NAV_TEXTBOX_PLACEHOLDER}
              icon={<SearchOutlined/>}
              onClick={()=>{props.setSearchFlag();}}
              className="left-align-button pl-5 border-none"
              style={{
                color: props.color ? props.color : "black",width:'182px',marginLeft:'8px',marginRight:'8px',marginTop:'8px',marginBottom:'4px'
              }}            ></LabeledIconButton></Tooltip>):(
                <Tooltip
                title={
                  <>
                Type <Tag color="blue">/</Tag> to search
                </>
                }
              >
                <LabeledIconButton
                // type="text"
                // tooltipTitle="Type  /  for search"
                label=""
                icon={<SearchOutlined  style={{paddingLeft:'22px'}}/>}
                onClick={()=>{props.setSearchFlag();}}
                className="left-align-button border-none"
                style={{
                  color: props.color ? props.color : "black",width:'62px',marginLeft:'8px',marginRight:'8px',marginTop:'8px',marginBottom:'4px'
                }}            ></LabeledIconButton></Tooltip>              )}
          </div>
        </>

        {props.search && (
          <TacitBaseModal styles={{content:{padding:0}}}
            centered
            keyboard={false}
            maskClosable={true}
            visibility={props.search}
            width={"1000px"}
            closeIcon={null}
            title={
              <>
                <Overlay onClose={() => props.setSearchFlag()} scope={Scope.Dialog}>
                  <div
                    className={`w-full flex justify-between items-center py-3 px-3   
                     
                    `}
                    // style={{ height: 50 }}
                  >
                    <div className="">
                      Search
                    </div>
                    <IconButton  icon={<CloseOutlined/>} onClick={() => props.setSearchFlag()}/>
                  </div>
    
                  <Divider className="m-0 p-0" />
                </Overlay>
              </>
            }
            content={
              <>
                <Overlay
                  onClose={() => props.setSearchFlag()}
                  scope={Scope.Dialog}
                >
                  <SearchPanal close={showSearch} refer={refer} />
                </Overlay>
              </>
            }
            onCancel={() => props.setSearchFlag()}  
            // className="review-modal-content"
            footer={<div className="m-0"></div>}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  openings: state.opening.openings,
  currentOpening: state.opening.currentOpening,
  search: state.opening.showSearch,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  setSearchFlag: () => dispatch(showSearch()),
  resetSearchFlag: () => dispatch(resetSearch()),
});

export default connect(mapStateToProps, mapPropsToState)(NavSearch);

import React from "react";
import Tooltip from "./Tooltip";
import { CloseOutlined } from "@ant-design/icons";

const CloseIconWithTooltip = () => {
  return (
    <Tooltip content={"Close"}>
      <CloseOutlined />
    </Tooltip>
  );
};

export default CloseIconWithTooltip;

import React, { lazy, useEffect } from "react"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import { initReviewLabels } from "../../redux/actions/opening/action"
import { Label } from "../../type/type"
import { getLabels } from "../../lib/api"
import { REVIEWS } from "../../constant"
const ReviewTable = lazy(() => import("./AntTable"))

import ContentHeader from "../analyticsVersion1/ContentHeader"
import { Breadcrumb } from "antd"
import Navbar from "../navbar/Navbar"
import SuspenseLoader from "../SuspenseLoader"

export type ReviewIndexProps = {
    intiReviewLabels: (labels: any) => void
}
const ReviewIndex = (props: ReviewIndexProps) => {
    useEffect(() => {
        getLabels(REVIEWS).then((labels) => {
            props.intiReviewLabels(labels)
        })
    }, [])
    return (
        <>
<div className="flex flex-col w-full  h-full overflow-hidden rounded" id="parentDiv"> 
      <ContentHeader>
        <div
          className="flex h-12 justify-between items-center px-4 py-3" >
          <div
            className="items-center flex  "          >
            <Breadcrumb
    items={[
      {
        title: 'Reviews',
      }
    ]}
  />
          </div>
          <div className="flex flex-row items-center">
              <Navbar/>

                </div> 
        </div>
      </ContentHeader>
      <div className=" flex flex-col h-full bg-white" >
            
      <SuspenseLoader>
                <ReviewTable />
      </SuspenseLoader>
                
                </div>
            </div>
        </>
    )
}

const mapPropsToState = (dispatch: Dispatch) => ({
    intiReviewLabels: (labels: Label[]) => dispatch(initReviewLabels(labels)),
})

export default connect(null, mapPropsToState)(ReviewIndex)

import {
    BarChartOutlined,
    CalendarOutlined,
    // NotificationOutlined,
} from "@ant-design/icons"
import { Badge, Menu } from "antd"
import { useLocation, useHistory } from "react-router-dom"
// import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import {
    addWsRequest,
    updateOrgNameId,
} from "../../redux/actions/opening/action"
import { loginUser } from "../../type/type.d"
import { servicesMembership } from "../../redux/reducers/initialState"
import { ATS_DASHBOARD, SUBSCRIBE, UNSUBSCRIBE } from "../../redux/actionType"
import { Dispatch } from "redux"
import {
    SIDENAV_MENU_ITEMS,
    SOCKET_CLIENT_ID,
    WS_BILLING,
    WS_CDB,
    WS_REVIEW,
} from "../../constant"
import MailIcon from "../Icons/MailIcon"
import ReviewIcon from "../Icons/ReviewIcon"
import JobPostIcon from "../Icons/JobPostIcon"
import TalentPoolIcon from "../Icons/TalentPoolIcon"
export type MenuItem = {
    label: string,
    icon: React.ReactNode,
    key: string,
    access: boolean,
    showBadge?: boolean,
}

type SideNavProps = {
    applicationId: string,
    applicationName: string,
    roles: string[],
    clientId: string,
    loginUser: loginUser,
    UpdateOrgNameId(orgData: any): void,
    addWsRequest(actionRequest: any): void,
    servicesMembership: servicesMembership,
    showUpdatesBadge: boolean,
    updatesBadgeCount: number,
    showDot: boolean
}

function SideMenu(props: SideNavProps) {
    const location = useLocation()
    const [selectedKeys, setSelectedKeys] = useState("/")
    const history = useHistory()
    const [previousRoute, setPreviousRoute] = useState("")

    useEffect(() => {
        const pathName = location.pathname
        // console.log(pathName)
        setSelectedKeys(pathName)
        let unsubscribeRoute
        // Unsubscribe from the previous WebSocket subscription
        let routeExist = getModelTypeForRoute(previousRoute)
        if (previousRoute) {
            if (routeExist) {
                unsubscribeRoute = generateWebSocketMessage(previousRoute, UNSUBSCRIBE)
                props.addWsRequest(unsubscribeRoute)
            }
        }

        // Subscribe to the current WebSocket subscription
        let previousRouteExist = getModelTypeForRoute(pathName)

        if (previousRouteExist) {
            const subscribeRoute = generateWebSocketMessage(pathName, SUBSCRIBE)
            props.addWsRequest(subscribeRoute)
        }
        // Update the previousRoute state
        setPreviousRoute(pathName)
    }, [location.pathname])


    const generateWebSocketMessage = (pathname: any, action: string) => {
        const wsMessage = {
            type: action,
            model_type: getModelTypeForRoute(pathname),
            model_id: getModelTypeForRoute(pathname),
            user_id: props.loginUser.user.id,
            org_id: props.applicationId, socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
            time: Math.floor(new Date().getTime() / 1000),
        }

        return wsMessage
    }
    const getModelTypeForRoute = (pathname: string) => {
        const routeParts = pathname.split("/")
        const lastRoutePart = routeParts[routeParts.length - 1]
        // Add logic to map last route part to model type
        switch (lastRoutePart) {
            case "ats":
                return ATS_DASHBOARD
            case "db":
                return WS_CDB
            case "reviewBase":
                return WS_REVIEW
            case "jd":
                return "jd"
            case "billing":
                return WS_BILLING
            default:
                return undefined
        }
    }

    // Mapping between menu labels and corresponding service names in token
    const MenuItemMapping: { [key: string]: string } = {
        [SIDENAV_MENU_ITEMS.BOARDS]: "ats",
        [SIDENAV_MENU_ITEMS.TALENT_POOL]: "candidates",
        [SIDENAV_MENU_ITEMS.JOB_DESCRIPTION]: "jd",
        [SIDENAV_MENU_ITEMS.WORKSPACE]: "companies",
    }

    const generateMenuItems = () => {
        const menuItems: MenuItem[] = [
            
            {
                label: SIDENAV_MENU_ITEMS.BOARDS,
                icon: <JobPostIcon   />,
                key: `/o/${props.applicationId}/ats`,
                access: false,
            },
            {
                label: SIDENAV_MENU_ITEMS.REVIEW,
                key: `/o/${props.applicationId}/reviews`,
                icon: <ReviewIcon />,
                access: false,
            },
            {
                label: SIDENAV_MENU_ITEMS.INBOX,
                key: `/o/${props.applicationId}/tmail/inbox`,
                icon: <MailIcon />,
                access: false,
            },
            {
                label: SIDENAV_MENU_ITEMS.TALENT_POOL,
                key: `/o/${props.applicationId}/db`,
                icon: <TalentPoolIcon   />,
                access: true,
            },
            {
                label: SIDENAV_MENU_ITEMS.ANALYTICS,
                icon: <BarChartOutlined   />,
                key: `/o/${props.applicationId}/analytics`,
                access: false,
            },
            {
                label: SIDENAV_MENU_ITEMS.CALENDAR,
                icon: <CalendarOutlined   />,
                key: `/o/${props.applicationId}/calendar`,
                access: false,
            },

        ]
        return menuItems.filter((menuItem) => {
            if (!menuItem.access) {
                return true // Exclude items with access set to false
            }

            if (menuItem.access && menuItem.label in MenuItemMapping) {
                const serviceName = MenuItemMapping[menuItem.label]
                return props.loginUser.services.includes(serviceName)
            }

            return true // Include items with access set to true and no service check
        })
    }

    return (
        <>
            <Menu
                style={{
                    height: "100%",
                }}
                mode="inline"
                onClick={(item) => {
                    history.push({
                        pathname: item.key,
                    })
                }}
                selectedKeys={[selectedKeys]}
            >
                {generateMenuItems().map((menuItem) => (
                    <Menu.Item
                        key={menuItem.key}
                        icon={
                            menuItem.icon
                        }
                        title={
                            <span>{menuItem.label}</span> 
                        } // Set title for tooltip
                    >
                        {!menuItem.access ||
                            props.loginUser.services.includes(
                                MenuItemMapping[menuItem.label]
                            ) ? (
                            <div
                                style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        placeItems: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {menuItem.label}
                                    {menuItem.showBadge &&
                                        menuItem.label === SIDENAV_MENU_ITEMS.UPDATES && (
                                            <Badge dot status="processing" color="red" />
                                        )}
                            </div>
                        ) : null}
                    </Menu.Item>
                ))}
            </Menu>
        </>
    )
}

const mapPropsTostate = (dispatch: Dispatch) => ({
    UpdateOrgNameId: (orgData: any) => dispatch(updateOrgNameId(orgData)),
    addWsRequest: (actionRequest: any) => dispatch(addWsRequest(actionRequest)),
})

const mapStateToProps = (state: any) => ({
    applicationId: state.opening.applicationId,
    applicationName: state.opening.applicationName,
    roles: state.opening.roles,
    loginUser: state.opening.loginUser,
    clientId: state.opening.clientId,
    servicesMembership: state.opening.servicesMembership,
    showUpdatesBadge: state.opening.showUpdatesBadge, // Modify based on your Redux state
    updatesBadgeCount: state.opening.updatesBadgeCount, // Modify based on your Redux state
    showDot: state.opening.notificationCenter.showDotOnUpdates,

})

export default connect(mapStateToProps, mapPropsTostate)(SideMenu)
import {  Upload } from 'antd';
import React, { useState } from 'react';
import { SecondaryButton } from '../../../../ant/Button';
import { MAX_ATTACHMENT_SIZE, SOCKET_CLIENT_ID } from '../../../../constant';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { addAttachments, attachmentLoader } from '../../../../redux/actions/opening/action';
import { currentApplicant, GetOpening } from '../../../../type/type';
import { addAttachment } from '../../../../lib/api';
import { throwFrontEndError, throwSuccess } from '../../../../service/throwError';
import { buildFormData } from '../../../../utils/FormDataBuilder';
import AttachmentIcon from '../../../Icons/AttachmentIcon';


type AddAttachmentProps = {
  currentOpening: GetOpening;
  currentApplicant: currentApplicant;
  attachmentLoader: () => void;
  clientId: string;
  addAttachments: (attachment: any, listID: string) => void;
};

const AddAttachment: React.FC<AddAttachmentProps> = ({
  currentApplicant,
  currentOpening,
  attachmentLoader,
  addAttachments,
}) => {
  const [fileExceed, setFileExceed] = useState(false);

  const handleFileChange = (info: any) => {
    const fileList = info.fileList;
    if (!fileList || fileList.length === 0) return;
  
    const file = fileList[0].originFileObj;
  
    if (file.size > MAX_ATTACHMENT_SIZE) {
      setFileExceed(true);
    } else {
      setFileExceed(false);
      uploadFile(fileList.map((file: any) => file.originFileObj));
    }
  };
  
  

  const uploadFile = (files: FileList) => {
    const data = [
      { key: 'model_name', value: 'applicants' },
      { key: 'record_id', value: currentApplicant.id },
      { key: 'opening_id', value: currentOpening.id },
      { key: 'socket_client_id', value: sessionStorage.getItem(SOCKET_CLIENT_ID) || '' },
      { key: 'list_id', value: currentApplicant.list_id }
    ];
    const formData = buildFormData(data, files);
    attachmentLoader();
    addAttachment(formData, currentApplicant.id)
      .then((res) => {
        addAttachments(res, currentApplicant.list_id);
        attachmentLoader();
        throwSuccess('Attachment uploaded successfully.');
      })
      .catch(() => {
        throwFrontEndError('Error while uploading attachment')
      });
  };

  

  return (
    <div className="w-full">
        {fileExceed && (
          <div className="bg-red-50 p-4 mx-2">{MAX_ATTACHMENT_SIZE}</div>
        )}
        <Upload
         className='flex card-file-upload w-full'
          showUploadList={false}
          beforeUpload={() => false} // Disable default behavior to handle manually
          onChange={handleFileChange}
          fileList={[]}
        >
          <SecondaryButton
            className=" flex justify-start truncate w-full"
            icon={<AttachmentIcon />}
            onClick={()=>{}}
          >
           Attachments
          </SecondaryButton>
        </Upload>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  clientId: state.opening.clientId,
  currentOpening: state.opening.currentOpening,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addAttachments: (attachment: any, listID: string) =>
    dispatch(addAttachments(attachment, listID)),
  attachmentLoader: () => dispatch(attachmentLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAttachment);

import React from "react";
import SignupIndex from "../../components/signUp/SignupIndex";
import TB_ORANGE_LOGO from "../../images/TB full orange.png"

const SignUpPageLayout= () => {
  return (
    <>
      <div className="flex flex-col w-full  h-full bg-cover  bg-bgImgType1 overflow-hidden ">
        <div className="  justify-center h-full items-center">
          <div className="bg-transparent pt-6 pl-6 sticky top-0 ">
            <img src={TB_ORANGE_LOGO} alt="" width="200px" style={{ backgroundColor: 'transparent' }} />
          </div>
        <div className="flex  pt-1   xl:space-x-4  overflow-hidden h-full ">
          <div className=" flex  justify-center h-full items-center w-full -mt-12">
            <div
              className="bg-white     xl:p-8 p-4 rounded xl:m-10 m-4   "
              style={{
                width: 463,
                boxShadow: "2px 4px 10px 0px #00000040",
                border: "1px solid #D9D9D9",
              }}
            >
              <SignupIndex />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpPageLayout;

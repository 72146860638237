import React, {  useEffect, useState } from "react";
import {  Empty, Popconfirm, Popover, Space, Table, Tooltip, Typography } from "antd";
import { getApplicantStatus, getCandidates, getTable, searchCandidateDocument } from "../../lib/api"
import { Candidate, FormBuilder, Opening, relevancyFilter, ResumeAnalysis, searchDynamicQuery, TableColumn } from "../../type/type";
import TacitbaseAvtar from "../../ant/Avtar";
// import { generateSearchQuery } from "../../utils/SearchDynamicQuery";
import {  BETA_PRODUCT, IS, NOT_AVAILABLE, operatorMap, PAID_RELEVANCY_FEATURES, PoolRoute } from "../../constant";
import { connect } from "react-redux";
import TableAnt from "../../ant/TableAnt";
import { IconButton } from "../../ant/Button";
import { EyeOutlined, LockOutlined, ReloadOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom";
import ApplicantDetailView from "../../components/jobPost/tabs/pool/ApplicantDetailView";
import { Dispatch } from "redux";
import {  applicantStatus, initCandidatesAtsPool, initCDBHead, initJobPostPoolSearchFilter,  initSearchQuery, setResumeRelevancyFilterNameAndCount } from "../../redux/actions/opening/action"
import { renderCreatedAt } from "../../utils/renderCreatedAt";
import SendPoolCandidateToTracker from "../../components/jobPost/tabs/pool/actions/SendPoolCandidateToTracker";
import SendPoolCandidateToReview from "../../components/jobPost/tabs/pool/actions/SendPoolCandidateToReview";
import AddBulkLabelToCandidateInAtsPool from "../../components/jobPost/tabs/pool/actions/AddBulkLabelToCandidateInAtsPool";
import SendMailToPoolCandidate from "../../components/jobPost/tabs/pool/actions/SendMailToPoolCandidate";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { Tag } from "antd/lib"
import ActionButton from "../../components/CDB/AddCandidateActionButton";
import ImportResumePool from "./ImportResumePool";
import Read from "../../Read";
import { useOrgMetadata } from "../setting/billingSetting/OrgMetadata";
import { newOrganisation } from "../../lib/apiRes";
import PoolTableFilter from "./PoolTableFilter";
import MoreAction from "../../components/CDB/CDBTableMoreActions/MoreAction";
import PoolSearchBox from "./PoolSearchBox";
import { generateSearchQuery } from "../../utils/SearchDynamicQuery";
import { convertUnixToISO } from "../../utils/ConvertUnixToIso";
const {Text} = Typography;

// Type definition for the Redux props
type TableReduxProps = {
  currentOpening: Opening;
  applicationId: string;
  initCDBHead: (table: any) => void;
  initCandidates: (members: Candidate[], currentPage: string) => void;
  candidatePool: any;
  currentTrackerForm: FormBuilder;
  currentTrackerJd:any;
  sources:{[key:string]:{name:string,profile:string}},
  applicantStatus: (applicants: { id: string, trackers: string[] }[]) => void,
  applicantionStatus: {
    [key: string]: Candidate[]
  },
  currentOrg:newOrganisation;
  relevancyFilter:relevancyFilter;
  addResumeRelevancyCount: (data: relevancyFilter) => void;
  searchQuery: searchDynamicQuery;
  initSearchQuery: (query: string) => void;
  initSearchFilter: (filterObj: any) => void;
};



const PoolTable = ({
  currentOpening,
  applicationId,
  initCandidates,
  candidatePool,
  currentTrackerJd,
  sources,
  applicantStatus,
  applicantionStatus,
  initCDBHead,
  currentOrg,
  relevancyFilter,
  addResumeRelevancyCount,
  initSearchQuery,
  initSearchFilter,
  searchQuery
}: TableReduxProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [selectedRowKeyData, setSelectedRowKeyData] = useState<any[]>([]);
  const orgMetadata = useOrgMetadata(currentOrg)
  const [hoveredRow, setHoveredRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [selectedRowKey, setSelectedRowKey] = useState<string>("");
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 25,
      position: ["bottomLeft"],
      size: "default",
      showSizeChanger: false,
      style: {
        marginTop: "0px",
        borderTop: "1px solid rgba(5, 5, 5, 0.06)",
        paddingTop: "9px",
      },
    },
  });
  const filter = relevancyFilter?.count !== 0 ?relevancyFilter?.name.toLowerCase():"";
  const history = useHistory();
  useEffect(() => {
    initSearchQuery("");
    getTable()
      .then((res: { columns: any }) => {
        initCDBHead(res.columns);
      })
    return()=>{
      addResumeRelevancyCount({ name: "created_at", count: 0 });
    }
  }, []);

  useEffect(() => {
    // Dynamically set the height of the table body
    const tableBody: any = document.querySelector(".ant-table-body");
    if (tableBody) {
      tableBody.style.height = "calc(100vh - 249.8px)";
    }
  }, [data]);

  useEffect(() => {
    // Populate data from candidate pool when available
    if (candidatePool[tableParams.pagination.current]) {
      setData(generateData(Object.values(candidatePool[tableParams.pagination.current])));
    } else {
      setData([]);
    }
  }, [candidatePool]);

  useEffect(() => {
    // Fetch data when table parameters or current tracker form changes
    if (tableParams.pagination.current && currentTrackerJd) {
      fetchData({ currentPage: tableParams.pagination.current,filter });
    }
  }, [currentTrackerJd,relevancyFilter,searchQuery]);

  // Generate data for the table from candidate pool
  const generateData = (result: any) => {
    return result.map((candidate: Candidate) => ({
      key: candidate.id,
      id: candidate.id,
      phone: candidate.phone,
      source: candidate.source?.from,
      email: candidate.email,
      candidates: {
        first_name: candidate.first_name,
        last_name: candidate.last_name,
      },
      created_by: candidate.created_by,
      created_at: candidate.created_at,
      comment_count: candidate.comment_count,
      attachment_count: candidate.attachment_count,
      resume_analysis:candidate.resume_analysis,
    }));
  };

  // Fetch data from the API
  const fetchData = (reqParams: { currentPage: number, filter?: string }) => {
    if (!currentTrackerJd.id) return;
    setLoading(true);
    const searchQueryParam =
      (searchQuery && searchQuery.query.trim().length > 0 || searchQuery?.filters?.length > 0) 
        ? generateSearchQuery(
            searchQuery.query,
            [{ column: "source.jd", operator: operatorMap[IS], value: currentTrackerJd.id, view: "Source", operatorName: IS, id: "" },...searchQuery.filters],
            1,
            'candidates_candidates'
          )
        : null;
  
    const apiCall = searchQueryParam
      ? searchCandidateDocument(searchQueryParam)
      : getCandidates(reqParams.currentPage, 25, currentTrackerJd.id, reqParams.filter);
  
    apiCall
      .then((res) => {
        initCandidates(res.items, reqParams.currentPage.toString());
        setTableParams((prev: any) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            total: res.total_count,
          },
        }));
        getApplicantStatus({ "ids": res.items.map((items:any) => items.id) }).then((res: any) => {
          applicantStatus(res)
        })
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // Column definitions for the Ant Design table
  const columns:any = [
    {
      title: <span style={{ paddingLeft: '3px'}}>Name</span>,
      dataIndex: "candidates",
      ellipsis: true,
      fixed: "left",
      render: (_: any, record: any) => (
        <Space  className=" w-full">
          <TacitbaseAvtar content={record.candidates.first_name[0]} />
          <Text ellipsis={{ tooltip: `${capitalizeFirstLetter(record.candidates.first_name)} ${capitalizeFirstLetter(record.candidates.last_name)}`}} style={{ minWidth: 100, maxWidth: 300, margin: 0 }} className="truncate">{`${capitalizeFirstLetter(record.candidates.first_name)} ${capitalizeFirstLetter(record.candidates.last_name)}`}</Text>
        </Space>
      ),
    },

    {
      width: "15%",
      title: (
        <div className="flex gap-2">
          {orgMetadata?.ai_feature !== "true" && (
            <div>
              <Tooltip title= {PAID_RELEVANCY_FEATURES}>
                <LockOutlined />
              </Tooltip>
            </div>
          )}
          <div>Relevancy</div>
          <Tooltip title={BETA_PRODUCT}>
            <Tag bordered={false} color="gold">
              Beta
            </Tag>
          </Tooltip>
        </div>
      ),
      ellipsis: true,
      fixed: "left",
      render: (_: any, record: any) => {
        // Retrieve candidate details
        const candidateDetails = record.resume_analysis;
        // Check if candidateDetails exist and has at least one item
        if (candidateDetails && candidateDetails.length > 0) {
          const candidate: ResumeAnalysis = candidateDetails[0];
          const isIrrelevant = candidate?.remark.toLowerCase() === "irrelevant";

          const style = {
            color: isIrrelevant ? "#F5222D" : "#009D79",
            backgroundColor: isIrrelevant ? "#FFF1F0" : "#E3FFF9",
            border: `1px solid ${isIrrelevant ? "#FFA39E" : "#37C8A6"}`,
          };
          return (
            <div className="w-full flex items-center justify-start space-x-2">
              <div style={{ minWidth: "75px" }}>
                <Tag style={style}>
                  {capitalizeFirstLetter(candidate?.remark)}
                </Tag>
              </div>
              <div>
                <Popover
                  content={
                    <div
                      style={{
                        maxHeight: "320px",
                        width: "310px",
                        overflowY: "auto",
                      }}
                    >
                      <Text type="secondary">{candidate?.summary}</Text>
                    </div>
                  }
                  title={`Why ${candidate?.remark} ?`}
                  trigger="hover"
                >
                  <EyeOutlined />
                </Popover>
              </div>
            </div>
          );
        }

        // Return null or fallback if no candidate details are found
        return null;
      },
    },
    {
      width: "25%",
      title: "Email",
      ellipsis: true,
      dataIndex: "email",
    },
    // {
    //   width: "15%",
    //   title: "Phone",
    //   dataIndex: "phone",
    //   ellipsis: true,
    // },
    {
      width: "10%",
      title: "Status",
      dataIndex: "id",
      ellipsis: true,
      render: (sourceKey: string) => {
        return applicantionStatus?.[sourceKey]?.length > 0 &&
          applicantionStatus[sourceKey].some((applicants: Candidate) => applicants.opening_id == currentOpening.id)
          ? <Tag>{"In tracker"}</Tag>
      : null;    },
    },
    {
      width: "20%",
      title: "Source",
      dataIndex: "source",
      ellipsis: true,
      render: (sourceKey: string) => {
        const sourceName = sources[sourceKey] ? capitalizeFirstLetter(sources[sourceKey].name) : capitalizeFirstLetter(sourceKey);
        const sourceImage = sources[sourceKey]?.profile; // Assuming 'image' is a property in your source data
        return sourceKey.trim().length === 0 ? (
          NOT_AVAILABLE
        ) : (
          <Space className="truncate flex justify-start w-full" style={{minWidth:"188px"}}>
            {sourceImage ? (
              <img src={sourceImage} alt={sourceName} style={{ width: 20, height: 20, borderRadius: '50%' }} />
            ) : (
              <TacitbaseAvtar content={sourceName[0]} />
            )}
            <div className="truncate">{sourceName}</div>
          </Space>
        );
      },
    },
    // {
    //   width: "15%",
    //   title: "Sourcer name",
    //   dataIndex: "source",
    //   ellipsis: true,
    // },
    // {
    //   width: "10%",
    //   title: "Activity",
    //   dataIndex: "members",
    //   ellipsis: true,
    // },
    {
      width: "14%",
      title: "Submitted at",
      dataIndex: "created_at",
      ellipsis: true,
      render: (record: any) => <span>{(typeof record === "number")?renderCreatedAt(convertUnixToISO(record)):renderCreatedAt(record)}</span>,
    },
  ];
  // Handle row selection changes
  const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: any) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRowKeyData(selectedRows);

  };

  const handleTableChange = (pagination: any) => {
    setTableParams({
      pagination,
    });
    fetchData({ currentPage: pagination.current,filter });
  };

  // Handle row click event
  const onRowClick = (record: any) => {
    setSelectedRowKey(record.key);
    history.push(
      `/o/${applicationId}/b/${currentOpening.id}/${PoolRoute}/${record.key}`
    );
  };

  // Handle row hover event
  const handleRowHover = (record: any) => {
    setHoveredRow(record ? record.key : null);
  };

  const handleRefreshTab = () => {
    setTableParams((prev: any) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        current:1,
      },}))
      initSearchQuery("");
      initSearchFilter([]);
      addResumeRelevancyCount({ name: "created_at", count: 0 });
    //   fetchData({
    //   currentPage: 1,
    // });
  };

  // Render bulk action controls for selected rows
  const tableBulkAction = () => (
    <>
      <Space className="flex items-center px-4 justify-start">
        <strong>{selectedRowKeys.length}</strong> Selected
        <AddBulkLabelToCandidateInAtsPool selectedRowKeys={selectedRowKeys} />
        <SendPoolCandidateToTracker
          isBulk={true}
          candidateIds={selectedRowKeys} />
        <SendPoolCandidateToReview isBulk={true} candidateIds={selectedRowKeys} />
        <SendMailToPoolCandidate isBulk={true} candidateIds={selectedRowKeys} cadidatesData={selectedRowKeyData} />
        {/* <MoreAction  selectedCandidates={selectedRowKeys} isSelected={true}/> */}
        <Popconfirm
          title="Close review"
          onConfirm={() => { } }
          okText="Yes"
          cancelText="No"
          placement="bottom"
          key={2} />
      </Space><div className="flex flex-row gap-2 items-center justify-end pr-4">
        {/* <div className="flex gap-2 items-center "> */}
        <PoolSearchBox />
        {/* {currentTrackerJd?.resume_ranking_settings !== null && (<PoolTableFilter />)} */}
        <PoolTableFilter />
        <IconButton
          icon={<ReloadOutlined />}
          onClick={handleRefreshTab} />
        <MoreAction selectedCandidates={selectedRowKeys} isSelected={true} />
      </div></>
   
  );

  return (
    <div className="flex flex-col px-4">
      <div className="bg-white flex flex-row w-full h-12 items-center rounded-t justify-between border-b">
        {selectedRowKeys.length > 0 ? (
          tableBulkAction()
        ) : (         
          <div className="flex flex-1 flex-row px-4 justify-between items-center">
            <div className="flex  justify-start">
              <Read >
                {currentTrackerJd?.id?.trim().length > 0 && (
                  <div className="flex flex-row space-x-2">
                    <ActionButton />
                    <ImportResumePool />
                  </div>
                )}
              </Read>

            </div>

            <div className="flex gap-2 justify-end items-center ">
            <PoolSearchBox />
             {/* {currentTrackerJd?.resume_ranking_settings !== null &&( */}
               <PoolTableFilter/>
               {/* )} */}
              <IconButton
                icon={<ReloadOutlined />}
                onClick={handleRefreshTab}
              />
              <MoreAction JdId={currentTrackerJd.id} isSelected={false}/>
            </div>
          </div>
        )}
      </div><div className=" h-full z-0" style={{ height: "calc(100vh - 166px)" }}>
        <div className="h-full">
          <TableAnt
            rowClassName={(record) => record.key === hoveredRow ? "cursor-pointer" : ""}
            size="small"
            loading={loading}
            columns={columns}
            data={data}
            onChange={handleTableChange}
            className="pool-table h-full w-full bg-white rounded-b overflow-y-hidden"
            pagination={tableParams.pagination}
            scroll={{ y: "calc(100vh - 130.8px)" ,x: 1500}}
            rowSelection={{
              selectedRowKeys,
              preserveSelectedRowKeys: true,
              onChange: onSelectChange,
              selections: [
                Table.SELECTION_ALL,
                Table.SELECTION_INVERT,
                Table.SELECTION_NONE,
                {
                  key: "odd",
                  text: "Select Odd Row",
                  onSelect: (changeableRowKeys) => {
                    const newSelectedRowKeys = changeableRowKeys.filter(
                      (_, index) => index % 2 === 0
                    );
                    setSelectedRowKeys(newSelectedRowKeys);
                  },
                },
                {
                  key: "even",
                  text: "Select Even Row",
                  onSelect: (changeableRowKeys) => {
                    const newSelectedRowKeys = changeableRowKeys.filter(
                      (_, index) => index % 2 !== 0
                    );
                    setSelectedRowKeys(newSelectedRowKeys);
                  },
                },
              ],
              columnWidth: 45,
            }}
            onRow={(record) => ({
              onMouseEnter: () => handleRowHover(record),
              onMouseLeave: () => handleRowHover(null),
              onClick: () => onRowClick(record),
            })}
            locale={{
              emptyText: (
                  <div className="flex h-full items-center justify-center" style={{height:"calc(100vh - 249.8px)"}}>
                      <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={<span>No data available</span>}
                      />
                  </div>
              ),
          }}
          />
        </div>
        <ApplicantDetailView selectedRow={selectedRowKey} />
      </div></div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initCDBHead: (table: TableColumn[]) => dispatch(initCDBHead(table)),
  initCandidates: (candidates: Candidate[], currentPage: string) =>
    dispatch(initCandidatesAtsPool(candidates, currentPage)),
  applicantStatus: (applicants: { id: string, trackers: string[] }[]) =>
    dispatch(applicantStatus(applicants)),
  addResumeRelevancyCount: (data: relevancyFilter) =>
    dispatch(setResumeRelevancyFilterNameAndCount(data)),
  initSearchQuery: (query: string) => dispatch(initSearchQuery(query)),
  initSearchFilter: (filterObj: any) => dispatch(initJobPostPoolSearchFilter(filterObj)),
});

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  candidatePool: state.opening.candidatePool,
  currentTrackerForm: state.opening.currentTrackerForm,
  applicationId: state.opening.applicationId,
  currentTrackerJd: state.opening.currentTrackerJd,
  sources: state.opening.candidateSources,
  applicantionStatus: state.opening.applicantStatus,
  currentOrg:state.opening.currentOrg,
  relevancyFilter: state.opening.relevancyFilter,
  searchQuery: state.opening.JobPostPoolSearchQuery,
});

export default connect(mapStateToProps, mapDispatchToProps)(PoolTable);

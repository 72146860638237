import React, { lazy, useState } from "react";
const TimelineComponent = lazy(() => import("./Timeline"));
import { Breadcrumb, Divider, Spin } from "antd";
import ContentHeader from "../analyticsVersion1/ContentHeader";
import Navbar from "../navbar/Navbar";
import { colors, DIVIDER_HEIGHT } from "../../constant";
import DesktopNotificationCheckbox from "./DesktopNotificationCheckBox";
import FilterSelect from "./UpdatesFilter";
import { IconButton} from "../../ant/Button";
import { ReloadOutlined } from "@ant-design/icons";
import { getRTUUpdatesOfOrg } from "../../lib/api";
import { notificationsPagination } from "../../type/type";
import { NotificatioRTU } from "../../lib/apiRes";
import { initNotificationCenterUnread } from "../../redux/actions/opening/action";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import SuspenseLoader from "../SuspenseLoader";
type timelineNotificationProps = {
  initUpdates: (notifications: NotificatioRTU[],pagination:notificationsPagination) => void;
};
const NotificationCenterIndex = (props:timelineNotificationProps) => {
  const [fetchInProgress,setFetchInProgress]=useState(false)
  const [allowDesktopNotifications, setAllowDesktopNotifications] =
    useState(false);
  const [queryBy, setQueryBy] = useState<any>({});

  // Function to handle refresh and clear all filters
  const handleRefreshTab = () => {
    setFetchInProgress(true)
    getRTUUpdatesOfOrg(`type=all&page=1&per_page=10`).then(
      (rtuUpdatesResponse) => {
        setTimeout(() => {
          setFetchInProgress(false);
        }, 1000);
        props.initUpdates(rtuUpdatesResponse.items,{page:rtuUpdatesResponse.page,per_page:rtuUpdatesResponse.per_page,page_count:rtuUpdatesResponse.page_count,total_count:rtuUpdatesResponse.total_count}) 
      }
    ).catch(()=>{
      setFetchInProgress(false);
    });
    // setQueryBy({}); // Clears the filters by resetting the state to an empty object
  };

  return (
    <>
      <ContentHeader>
        <div className="flex flex-row px-4 py-3 items-center justify-between h-12">
          <div className="flex items-center ">
            <Breadcrumb
              items={[
                {
                  title: "Updates",
                },
              ]}
            />
          </div>
          <div className="flex flex-row items-center">
            <Navbar />
          </div>
        </div>
      </ContentHeader>
      <div className=" flex flex-row h-12 border-b bg-white space-x-2 items-center px-4 justify-between">
        <div className="flex">
          <DesktopNotificationCheckbox
            allowDesktopNotifications={allowDesktopNotifications}
            setAllowDesktopNotifications={setAllowDesktopNotifications}
          />
        </div>
        <div className="flex flex-row space-x-2 items-center">
          <FilterSelect queryBy={queryBy} setQueryBy={setQueryBy} />
          <Divider
            type="vertical"
            style={{
              height: DIVIDER_HEIGHT,
              borderColor: `${colors.divider}`,
            }}
          />

          <IconButton
            tooltipTitle="Refresh tab"
            icon={<ReloadOutlined />}
            onClick={handleRefreshTab}
          />
        </div>
      </div>
      <div
        className="w-full h-full flex flex-col overflow-hidden"
        id="parentDiv"
      >
        <div className=" flex items-center justify-center bg-white   flex-1 overflow-hidden  rounded">
          <SuspenseLoader>  {fetchInProgress ? (
            <div className="">
              <div>
                <Spin />{" "}
              </div>
            </div>
          ) : (
          <div className="flex flex-col h-full w-full">
            <div className="w-full flex flex-col h-full justify-center items-center">
              <TimelineComponent filtervalue={queryBy.section} activeTab={queryBy.readStatus} />
            </div>
          </div>
          )}
          </SuspenseLoader>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initUpdates: (notifications: NotificatioRTU[],pagination:notificationsPagination) =>
    dispatch(initNotificationCenterUnread(notifications,pagination)),
});
export default connect(null,mapDispatchToProps)(NotificationCenterIndex);

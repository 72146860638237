import React, { useState } from "react";
import { Button, DatePicker, Form, Input, Popconfirm, Popover, Typography } from "antd";
import {
  BellOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  cancelDocumentSignatureProcess,
  downloadEsignDocument,
  extendDocumentSignatureProcess,
  remindDocumentSignatureProcess,
} from "../../../lib/api";
import throwError, {
  throwFrontEndError,
  throwSuccess,
} from "../../../service/throwError";
import { useDispatch, useSelector } from "react-redux";
import { updateEsignDetails } from "../../../redux/actions/opening/action";
import dayjs from "dayjs";
const {Text} = Typography;

type DocumentActionPopupsProps = {
  id: string;
};
// api call for download document
// we have to  download the document only when all participants sign document
const handleDocumentDownload = (
  e: any,
  id: string,
  setLoading: (loading: boolean) => void
) => {
  return new Promise<void>((resolve, reject) => {
    e.stopPropagation();
    e.preventDefault();
    setLoading(true);
    downloadEsignDocument(id)
      .then(async (res) => {
        if (res?.url) {
          try {
            const response = await fetch(res.url);
            const blob = await response.blob();

            const blobUrl = window.URL.createObjectURL(blob);

            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            document.body.appendChild(iframe);

            iframe.src = blobUrl;
            setLoading(false);
            setTimeout(() => {
              document.body.removeChild(iframe);
              window.URL.revokeObjectURL(blobUrl);
            }, 1000);

            resolve();
          } catch (error) {
            throwFrontEndError("Error downloading document");
            setLoading(false);
            reject(error);
          }
        } else {
          throwFrontEndError("No URL provided for document download.");
          setLoading(false);
          reject();
        }
      })
      .catch((reason) => {
        setLoading(false);
        reject();
        throwError(reason);
      });
  });
};

// api call for remind participants to sign document
const handleRemindParticipants = (id: string) => {
  return new Promise<void>((resolve, reject) => {
    remindDocumentSignatureProcess(id)
      .then((res) => {
        throwSuccess(res.message);
        resolve();
      })
      .catch((reason) => {
        throwError(reason);
        reject();
      });
  });
};

// extend document signature process for docusig
const handleExtendDocumentProcess = (id: string, days: string) => {
  return new Promise<void>((resolve, reject) => {
    extendDocumentSignatureProcess(id, days)
      .then(() => {
        throwSuccess(`Document signature process extended by ${days} days`);
        resolve();
      })
      .catch((reason) => {
        throwError(reason);
        reject();
      });
  });
};

export const ConfirmableButton: React.FC<{
  onConfirm: (e?: any) => void;
  title: string;
  buttonText: string;
  description: string;
  icon?: React.ReactNode;
}> = ({ onConfirm, title, buttonText, icon, description }) => (
  <Popconfirm
    title={title}
    onConfirm={onConfirm}
    description={<div className="w-60">{description}</div>}
    okText="Yes"
    cancelText="No"
  >
    <Button type="text" danger={buttonText === "Cancel"} icon={icon}>
      {buttonText}
    </Button>
  </Popconfirm>
);

export const DownloadDocument: React.FC<DocumentActionPopupsProps> = (
  props: DocumentActionPopupsProps
) => {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      type="text"
      loading={loading}
      icon={<DownloadOutlined />}
      onClick={(e) => handleDocumentDownload(e, props.id, setLoading)}
    >
      Download
    </Button>
  );
};

export const RemindParticipants: React.FC<DocumentActionPopupsProps> = (
  props: DocumentActionPopupsProps
) => (
  <ConfirmableButton
    onConfirm={() => handleRemindParticipants(props.id)}
    title="Remind participants"
    description="Are you sure you want to remind participants for signature?"
    buttonText="Remind"
    icon={<BellOutlined />}
  />
);

export const ExtendDocumentProcess: React.FC<DocumentActionPopupsProps> = (
  props: DocumentActionPopupsProps
) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const onExtend = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        handleExtendDocumentProcess(props.id, values.days)
          .then(() => {
            setVisible(false);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch((errorInfo) => {
        console.log("Validate Failed:", errorInfo);
      });
  };

  const content = (
    <div style={{ width: 250 }}>
      <Form form={form} layout="vertical">
        <Form.Item
          name="days"
          label="Extend Days"
          rules={[
            {
              required: true,
              message: "Please input number of days to extend",
            },
          ]}
        >
          <Input
            type="number"
            placeholder="Enter days to extend"
            min={1}
            max={30}
          />
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" onClick={onExtend} loading={loading}>
            Extend
          </Button>
        </div>
      </Form>
    </div>
  );

  return (
    <Popover
      content={content}
      title="Extend Signature Process"
      trigger="click"
      open={visible}
      onOpenChange={(newVisible) => setVisible(newVisible)}
    >
      <Button type="text" icon={<ClockCircleOutlined />}>
        Extend
      </Button>
    </Popover>
  );
};

export const CancelDocumentProcessWithReason: React.FC<
  DocumentActionPopupsProps
> = (props: DocumentActionPopupsProps) => {
  const eSignDetails = useSelector((state: any) => state.opening.eSignDetails);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCancelProcessWithReason = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        cancelDocumentSignatureProcess(props.id, values.cancelReason)
          .then(() => {
            const documentIndex = eSignDetails.documents.findIndex(
              (item: any) => item.id === props.id
            );
            const updatedDocuments = [...eSignDetails.documents];
            updatedDocuments[documentIndex] = {
              ...updatedDocuments[documentIndex],
              status: "voided",
            };
            dispatch(updateEsignDetails({ documents: updatedDocuments }));

            throwSuccess("Document signature process has been cancelled");
            setVisible(false);
            setLoading(false);
          })
          .catch((error) => {
            throwError(error);
            setLoading(false);
          });
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  };

  const cancelPopoverContent = (
    <div style={{ width: 300 }}>
      <Form form={form} layout="vertical" initialValues={{ reason: "" }}>
        <Form.Item
          name="cancelReason"
          label="Cancellation reason"
          rules={[
            {
              required: true,
              message: "Please provide reason for cancellation process",
            },
          ]}
        >
          <Input.TextArea
            rows={3}
            placeholder="Please provide specific details for cancellation"
          />
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 10,
          }}
        >
          <Button
            type="primary"
            danger
            onClick={handleCancelProcessWithReason}
            loading={loading}
          >
            Cancel Process
          </Button>
        </div>
      </Form>
    </div>
  );

  return (
    <Popover
      content={cancelPopoverContent}
      title="Cancel Signature Process"
      trigger="click"
      open={visible}
      onOpenChange={(newVisible) => setVisible(newVisible)}
    >
      <Button type="text" danger icon={<CloseCircleOutlined />}>
        Cancel
      </Button>
    </Popover>
  );
};
export const ExtendZohoDocumentProcess: React.FC<DocumentActionPopupsProps> = (
  props: DocumentActionPopupsProps
) => {
  const dateFormat = "DD MMM YYYY";
  const [visible, setVisible] = useState(false);
  const [dateString, setDateString] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const disablePastDates: any = (current: dayjs.Dayjs | null) => {
    return current && current < dayjs().startOf("day");
  };

  const onExtend = () => {
    setLoading(true);
    extendDocumentSignatureProcess(props.id, dateString)
      .then(() => {
        setVisible(false);
        setLoading(false);
        throwSuccess("Document signature process has been extended");
      })
      .catch((error) => {
        throwError(error);
        setLoading(false);
      });
  };
  const content = (
    <div style={{ width: 250 }}>
      <div className="flex flex-col space-y-2">
        <Text>Extend up to :</Text>
        <DatePicker
          format={dateFormat}
          style={{ width: "100%" }}
          placeholder="Select date"
          onChange={(_, dateString) => setDateString(dateString)}
          disabledDate={disablePastDates}
        />
      </div>
      <div className= "mt-2" style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button type="primary" onClick={onExtend} loading={loading}>
          Extend
        </Button>
      </div>
    </div>
  );

  return (
    <Popover
      content={content}
      title="Extend Signature Process"
      trigger="click"
      open={visible}
      onOpenChange={(newVisible) => setVisible(newVisible)}
    >
      <Button type="text" icon={<ClockCircleOutlined />}>
        Extend
      </Button>
    </Popover>
  );
};
import React from "react";
import { Button, Typography } from "antd";
import { CheckCircleOutlined, MailOutlined } from "@ant-design/icons";

const { Text } = Typography;

interface AccessContentProps {
  config: {
    description: string;
  };
}

const AccessContent: React.FC<AccessContentProps> = ({ config }) => {
  return (
    <div className="overflow-y-auto">
      <div className="flex flex-col pt-4 pb-6">
        <Text type="secondary">{config.description}</Text>
      </div>
      <div className="px-4">
        <div className="bg-tacitbase-secondary3 bg-opacity-10 rounded p-4 mb-6">
          <h3 className="font-medium mb-3">
            Your team members will be able to:
          </h3>
          <div className="space-y-3">
            {[
              "View and schedule recruitment events",
              "Coordinate interview timeslots",
              "Track events by job positions",
            ].map((capability, index) => (
              <div key={index} className="flex items-center">
                <CheckCircleOutlined className="text-green-500 mr-3" />
                <span>{capability}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full rounded mb-6 text-center">
          <Button icon={<MailOutlined />} type="primary">
            Contact owner
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccessContent;

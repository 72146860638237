import React, { useEffect, useState } from "react";
import TacitBaseModal from "../../../../ant/TacitbaseModal";
import { Button, Divider, Pagination, Space, Typography } from "antd";
import { LabeledIconButton } from "../../../../ant/Button";
import {
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
const { Text } = Typography;

import { Overlay } from "../../../hook/Overlay";
import {
  clearEsignDetails,
  InitCurrentCandidateInAtsPool,
  InitCurrentCandidateLabelsInAtsPool,
} from "../../../../redux/actions/opening/action";
import { Dispatch } from "redux";

import { getCandidate, getCandidateLabel } from "../../../../lib/api";
import { CurrentCandidateInPool } from "../../../../redux/reducers/initialState";
import { Candidate, Label, Scope, loginUser } from "../../../../type/type.d";
import CandidateInPoolTabs from "./tabs/CandidateInPoolTabs";
import SendCandidateToTracker from "./actions/SendPoolCandidateToTracker";
import AddLabelToCandidate from "./actions/AddLabelToPoolCandidate";
import AddAttachmentToPoolCandidate from "./actions/AddAttachmentToPoolCandidate";
import SendPoolCandidateToReview from "./actions/SendPoolCandidateToReview";
import SendMailToPoolCandidate from "./actions/SendMailToPoolCandidate";
import { PoolRoute } from "../../../../constant"
import CandidateInPoolDetails from "./details/CandidateInPoolDetails";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import { renderCreatedAt } from "../../../../utils/renderCreatedAt";


const DetailedPoolApplicant = (props: {
  selectedRow: string;
  applicationId: string;
  candidatePool: any;
  currentCandidateInPool: CurrentCandidateInPool;
  loginUser: loginUser;
  InitCurrentCandidateLabel: (labels: Label[]) => void;
  InitCurrentCandidateInDB: (candidate: Candidate) => void;
  clearEsignDetails: () => void;
  sources:{[key:string]:{name:string,profile:string}},
}) => {
  const history = useHistory();
  //   const [loading, setLoading] = React.useState<boolean>(false);
  const params: { board_id: string; candidate_id: string; tabname: string } =
    useParams();
    const [total, setTotal] = useState<number>(0);


  const itemRender = (current: any, type: any) => {
    if (type === "prev") {
      return (
        <LabeledIconButton
          icon={<LeftOutlined />}
          onClick={() => { }}
          label=""
        />
      );
    }
    if (type === "next") {
      return (
        <LabeledIconButton
          icon={<RightOutlined />}
          onClick={() => { }}
          label=""
        />
      );
    }

    if (type === "page") {
      return null;
    }
    return null;
  };

  const backToPool = () => {

    history.push({
      pathname: `/o/${props.applicationId}/b/${params.board_id}/${PoolRoute}`,
    });
  };

  useEffect(() => {
    props.clearEsignDetails();
    params.candidate_id &&
      (getCandidate(params.candidate_id).then((res) => {
        props.InitCurrentCandidateInDB(res);
      }),
        getCandidateLabel(params.candidate_id).then((res) => {
          props.InitCurrentCandidateLabel(res);
        }));
  }, [params.candidate_id]);

  const handleTableChange = (current: number) => {
    history.push({
      pathname: `/o/${props.applicationId}/b/${params.board_id}/${PoolRoute}/${
        Object.keys(props.candidatePool[props.candidatePool.currentpage])[
          current - 1
          ]        }`,
    });
  };
  useEffect(() => {
    // Calculate the total number of items
    let totalItems: number =
      props.candidatePool &&
      props.candidatePool[props.candidatePool.currentpage] &&
      Object.keys(props.candidatePool[props.candidatePool.currentpage]).length;
    setTotal(totalItems);
  }, [props.candidatePool]);

  return (
    <>
      <TacitBaseModal
        keyboard={false}
        maskClosable={false}
        visibility={params.candidate_id ? true : false}
        width={"100%"} 
        title={
          <>
            <Overlay onClose={backToPool} scope={Scope.Dialog}>
              <div
                className={`w-full flex justify-between items-center py-3    
                 
                `}
                style={{ height: 52 }}
              >
                <div className="">
                <Button
                 style={{
                  color:  "#000000" ,
                  transition: "color 0.3s",
                }}
                className=""
                  icon={<LeftOutlined   />} 
                  type="link"
                  name="Back to pool"
                  onClick={backToPool} 
                >{" "}Back to pool</Button>
                </div>
                {/* Pagination component in the header */}
                <div className="flex flex-row ">
                  <div className="flex items-center h-7 ">
                    <Text>
                      {props.currentCandidateInPool?.candidate.source?.from &&
                        (props.sources[props.currentCandidateInPool?.candidate.source?.from]
                          ? capitalizeFirstLetter(
                            props.sources[props.currentCandidateInPool?.candidate.source?.from].name
                          )
                          : capitalizeFirstLetter(
                            props.currentCandidateInPool?.candidate.source?.from
                          ))}
                    </Text>

                    {props.currentCandidateInPool?.candidate.source?.medium && (
                      <Text className="font-normal">
                        {`, ${capitalizeFirstLetter(props.currentCandidateInPool?.candidate.source.medium)}`}
                      </Text>
                    )}
                    <div className="ml-4 font-normal">
                      <ul>
                        <li>
                          <Text>
                            {renderCreatedAt(props.currentCandidateInPool?.candidate.created_at)}
                          </Text>
                        </li>
                      </ul>
                    </div>
                    <Divider type="vertical" />
                  </div>{" "}
                  <div className="flex items-center ">
                  <Pagination
                  showTotal={(total, range) =>
                    `${range[0]} of ${total} applicants`
                  } 
                  style={{alignItems:'center'}}
                  className=" pr-4"
                  showQuickJumper={false}
                  showSizeChanger={false}
                  pageSize={1}
                  current={
                    params.candidate_id &&
                      props.candidatePool[props.candidatePool.currentpage]
                      ? Object.keys(
                        props.candidatePool[props.candidatePool.currentpage]
                      )?.indexOf(params.candidate_id) + 1
                      : 1
                  }
                  onChange={handleTableChange}
                  total={total} // Total number of reviews
                  itemRender={(current: any, type: any) =>
                    itemRender(current, type)
                  }
                /></div>
              </div>
              </div>{" "}
              {/* <Divider className="m-0 p-0" /> */}
            </Overlay>
          </>
        }
        closeIcon={null}
        content={
          <>

            <div
              style={{
                margin: 0,
                // height: modalContentHeight,
                // paddingRight: "2px",
                position: "relative",
                // borderRadius: '12px',
                overflow: "hidden",
                width: "calc(100vw - 150px )",
                maxWidth: "calc(100vw - 150px )",
                height: "calc(100vh - 161px )",
                maxHeight: "calc(100vh - 100px )",
                borderRadius:'4px'
              }}
            >
              <div style={{ display: "flex" }} className="h-full  ">
                {/* First Part */}
                <div
                  style={{ width:"48%" }}
                  className="flex flex-col h-full flex-shrink-0  border-l border-b border-t border-r  "
                >
                  <CandidateInPoolDetails  />{" "}
                </div>

                {/* Second Part */}
                <div
                  style={{
                    width: "35%",
                  }}
                  className=" pr-0.5 pb-2  flex flex-col h-full border-t border-r"
                >
                  <CandidateInPoolTabs />
                </div>
                <>
                  <>
                    <div
                      style={{ width: "17%" }}
                      className="px-5 py-3 border-t border-r border-b"
                    >
                      <Space direction="vertical" className="w-full space-y-1">
                        <AddLabelToCandidate />
                        <AddAttachmentToPoolCandidate/>
                        <SendCandidateToTracker isBulk={false} candidateIds={[]} />
                        <SendPoolCandidateToReview isBulk={false} candidateIds={[]} />
                        <SendMailToPoolCandidate isBulk={false} candidateIds={[]} cadidatesData={[]}/>
                      </Space>
                    </div>
                  </>                
                </>
              </div>
            </div>
          </>

          // </Overlay >
        }
        footer={<div className="m-0"></div>}
        style={{
          overflowY: "hidden",
          width: " 100vw",
          height: "100vh",
          position: "fixed",
          top: "50px",
          left: "10px",
          bottom: "10px",
          right: "0px",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          padding: "0px",
          borderRadius:'4px'
          // opacity: 1
        }}
        className="review-modal-content"
      />
    </>
  );
};
const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  loginUser: state.opening.loginUser,
  candidatePool: state.opening.candidatePool,
  sources: state.opening.candidateSources,
  currentCandidateInPool: state.opening.currentCandidateInPool,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  InitCurrentCandidateInDB: (candidate: Candidate) =>
  dispatch(InitCurrentCandidateInAtsPool(candidate)), 
  InitCurrentCandidateLabel: (labels: Label[]) =>
    dispatch(InitCurrentCandidateLabelsInAtsPool(labels)),
  clearEsignDetails: () => dispatch(clearEsignDetails()),
  
});

export default connect(mapStateToProps, mapPropsToState)(DetailedPoolApplicant);

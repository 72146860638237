import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { Modal} from "antd";
import { IconButton,SecondaryButton } from "../../../ant/Button";
import ComposeMail from "../../common/composeMail/NewComposeMail";
import "../../../styles/component/style.css"
import UpgradePlanModal from "../../../pages/setting/billingSetting/UpgradePlanModal";
import { newOrganisation } from "../../../lib/apiRes";
import { useOrgMetadata } from "../../../pages/setting/billingSetting/OrgMetadata";
import { OrgDomain } from "../../../redux/reducers/initialState";
import DomainModel from "../../../pages/setting/billingSetting/DomainModel";
import MailIcon from "../../Icons/MailIcon";

type SendMailToCDBCandidateProps = {
  candidateDB:any;
    currentCandidateInEditMode: any;
  candidatePool: any;
  isBulk: boolean;
  candidateIds: string[];
  cadidatesData:any[];
  currentOrg:newOrganisation;
  defaultDomain:OrgDomain;
};

const SendMailToCDBCandidate: React.FC<SendMailToCDBCandidateProps> = (props) => {
 
  const [composeMail, setComposeMail] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const orgMetadata = useOrgMetadata(props.currentOrg);
  const [openDomainPopup, setOpenDomainPopup] = useState(false);
  const refer = useRef(null);


  const handleBillingModalCancel = () => {
    setIsModalVisible(false);
};
  // Function to handle the button click and open the compose mail overlay
  const handleComposeMail = () => {
    if(orgMetadata.bulk_email === "true"){
      const domainID = Object.keys(props.defaultDomain)[0];
      if(domainID && !props.defaultDomain[domainID].custom){
        setOpenDomainPopup(true);
        return;
      }
      setComposeMail(true);
      return;
    }
    setIsModalVisible(true);
    
  };
 
  const handleSingleMail = () => {
    const domainID = Object.keys(props.defaultDomain)[0];
    if(domainID && !props.defaultDomain[domainID].custom){
      setOpenDomainPopup(true);
      return;
    }
    setComposeMail(true)
  }
  return (
    <div>
      {/* Render button based on isBulk prop */}
      {props.isBulk ? (
        <IconButton
          onClick={handleComposeMail}
          className="w-7 h-7"
          tooltipTitle="Compose mail"
          icon={<MailIcon/>}
        />
      ) : (
        <SecondaryButton
          className="w-full truncate left-align-button"
          icon={<MailIcon />}
          onClick={handleSingleMail}
        >Compose mail</SecondaryButton>
      )}

      {/* Render the compose mail overlay if composeMail is true */}
      {composeMail && (
        <Modal
        visible={composeMail}
        onCancel={()=>{setComposeMail(false)}}
        footer={null}
        closable={false}
        bodyStyle={{ padding: 0, margin: 0 }}
        width={900} // Adjust the width as per your requirement
        getContainer={false}
        className="mailBox"
      >
        <ComposeMail
          refer={refer}
          entity="candidates"
          toAddress={
            props.isBulk
              ? props.cadidatesData.map((item) => item.email)
              : [props.currentCandidateInEditMode.candidateId.email]
          }
          service="candidates"
          close={()=>{setComposeMail(false)}}
          record_id={
            props.isBulk
              ? props.cadidatesData
              : [props.currentCandidateInEditMode.candidateId]
          }
          reduxActionCall={()=>{}}
          getJobDescription={()=>{}}
          getDisableState = {()=>{}}
        />
      </Modal>
      )}
      <UpgradePlanModal isModalVisible = {isModalVisible} handleCancel={handleBillingModalCancel}/>
      <DomainModel isModalVisible = {openDomainPopup} handleCancel = {()=>setOpenDomainPopup(false)}/>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  candidateDB: state.opening.candidateDB,
  candidatePool: state.opening.candidatePool,
  currentCandidateInEditMode: state.opening.currentCandidateInEditMode,
  currentOrg:state.opening.currentOrg,
  defaultDomain: state.opening.defaultDomain,
});

export default connect(mapStateToProps)(SendMailToCDBCandidate);

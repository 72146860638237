import React from "react";
import TacitBaseModal from "../../../../tacitbaseAnt/TacitbaseModal";
import EmptyCalendar from "../../../calender/global/EmptyCalendarScreen";

interface CreateEventEmptyScreenProps {
  onClose: () => void;
}

const CreateEventEmptyScreen: React.FC<CreateEventEmptyScreenProps> = ({onClose}) => {
  return (
    <>
      <TacitBaseModal
      ModalTriggerButtonName=""
      keyboard={false}
      maskClosable={true}
      onClose={onClose} onCancel={onClose}
      visibility={true}
      width={"100%"}
      content={
       <EmptyCalendar/>
      }
      footer={[]}
      style={{
        overflowY: "hidden",
        width: " 100vw",
        height: "100vh",
        position: "fixed",
        top: "50px",
        left: "10px",
        bottom: "10px",
        right: "0px",
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
        padding: "0px",
        borderRadius: "4px",
      }}
    ></TacitBaseModal>
    </>
  );
};
export default CreateEventEmptyScreen;

export const freeBusyResponse = {
    "calendars": {
      "guest1@example.com": {
        "busy": [
          { "start": "2024-10-21T10:00:00Z", "end": "2024-10-21T11:00:00Z" },
          { "start": "2024-10-21T14:00:00Z", "end": "2024-10-21T15:30:00Z" },
          { "start": "2024-10-22T10:00:00Z", "end": "2024-10-21T11:00:00Z" },
          { "start": "2024-10-22T14:00:00Z", "end": "2024-10-21T15:30:00Z" }
        ]
      },
      "guest2@example.com": {
        "busy": [
          { "start": "2024-10-22T09:30:00Z", "end": "2024-10-21T10:30:00Z" }
        ]
      },
     
    }
  };
  
  
// Convert Google Calendar Free/Busy response to React Big Calendar format
export const convertFreeBusyToEvents = (response) => {
    const events = [];
    const resources = [];
    let eventId = 0;
  
    // Loop through the calendars (each guest)
    for (const [guestEmail, data] of Object.entries(response.calendars)) {
      // Add each guest as a resource
      resources.push({ id: guestEmail, title: guestEmail });
  
      // Loop through the busy times for each guest
      data.busy.forEach((busyPeriod) => {
        events.push({
          id: eventId++,  // unique ID for each event
          title: `Busy, ${new Date(busyPeriod.start).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}`,  // Updated title to include start and end times
          start: new Date(busyPeriod.start),  // Convert start to Date object
          end: new Date(busyPeriod.end),  // Convert end to Date object
          resourceId: guestEmail,  // Assign the resource ID (guest's email)
        });
      });
    }
  
    return { events, resources }; // Return both events and resources
  };
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ACTIVITY_TAG, COMMENT_TAG, SOCKET_CLIENT_ID } from "./constant";
import {
  BillingMember,
 
  NotificatioRTU,
} from "./lib/apiRes";
// const customId = "custom-id-yes";

// import { WS_ORG } from "./constant";
import {
  addNewOpening,
  initWs,
  clientId,
  addList,
  updateListName,
  moveAllApplicants,
  archivedAllListApplicants,
  draggableList,
  addApplicant,
  editName,
  editApplicantDesc,
  move,
  copyApplicantAction,
  draggableCard,
  saveCardLabel,
  saveBoardLabel,
  saveComment,
  updateLabelState,
  addMemberOnCard,
  updateApplicantArchiveStatus,
  addAttachments,
  updateOpeningName,
  setBoardBackgroundColor,
  starredOpening,
  moveListAction,
  removeMemberFromCard,
  addMemberOnBoard,
  removeMemberFromOpening,
  deleteAttachment,
  removeLabelFromCard,
  updateComment,
  archiveList,
  addActivity,
  moveListWS,
  moveApplicantWS,
  copyApplicantWS,
  resetWebsocket,
  sendBackArchivedList,
  sendBackArchivedItem,
  dequeueWsRequest,
  reconnectWSRequestAction,
  updateWsRequestStatus,
  updateActivity,
  slackIntegration,
  addWsRequest,
  addCandidateInDB,
  addCandidateInReview,
  addCommentReview,
  addEventOnBoardCalender,
  editBoardEvent,
  deleteBoardEvent,
  addLabelToCandidateInReview,
  removeMemberOnReview,
  addMemberOnReview,
  addIncomingInvitation,
  addMemberInBilling,
  removeBillingMember,
  deleteCardEvent,
  editCardEvent,
  updateAttachment,
  removeLabelFromCandidate,
  addLabelToCurrentCandidateInCDB,
  updateCommentOfReview,
  removeLabelFromReview,
  listOfBoards,
  updateBoardVisibility,
  userNotificationSettingIntegration,
  addNotificationFromWs,
  showDotOrNotOnUpdates,
  addDots,
} from "./redux/actions/opening/action";
// import { REQUEST_MADE, SUBSCRIBE } from "./redux/actionType";
import { SUBSCRIBE } from "./redux/actionType";
// import { waitForSocketConnection } from "./service/waitForWsConnection";
import {
  Applicant,
  Attachment,
  Candidate,
  CandidateShortReview,
  comment,
  InitLists,
  loginUser,
  NotificationOptions,
  Opening,
  OrganizedBoard,
  orgMember,
  ReviewComment,
  wsAction,
} from "./type/type.d";
import { getApplicant, getBoardByid } from "./lib/api";


import { tokenVerify } from "./service/tokenVerify";
import { capitalizeFirstLetter } from "./utils/capitalizeFirstLetter";
import { handleWebSocketResponse } from "./handleWebSocketResponses";
type Props = {
  loginUser: loginUser;
  applicationId: string;
  currentReviewId: string;
  currentMailKey: string;
  currentApplicantId: string;
  member: orgMember;
  addNotificationToUpdates: (notification: NotificatioRTU) => void;
  listOfBoards(boardData: any): void;
  updateBoardVisibility: (board: Opening) => void;
  initWs(ws: WebSocket): void;
  websocket: wsAction;
  updateListName(list: any): void;
  addList(applicant: any): void;
  boards: OrganizedBoard;
  currentOpening: Opening;
  boardList: InitLists;
  initWs(ws: WebSocket): void;
  addClientId(id: any): void;
  moveAllApplicants(payload: any): void;
  archivedApplicants(payload: any, openingID: string): void;
  dragList(result: any): void;
  dragCard(result: any): void;
  saveApplicant(applicant: Applicant): void;
  updateApplicantName(nameobj: any): void;
  editDesc(desc: string): void;
  move(
    applicant: any,
    currentListId: any,
    selectedApplicantPosition: any
  ): void;
  copyApplicant(applicant: any, index: number): void;
  saveApplicantLabel(label: any): void;
  saveOpeningLabel(label: any, openingId: string): void;
  saveComment(comment: any, listID: string): void;
  updateComment(comment: any): void;
  integratedOptions(data: any): void;
  updateApplicantLabel(updatedLabel: any): void;
  addMember(member: any, applicantID: string): void;
  archiveUnArchive(applicantID: string): void;
  addAttachements(attachment: any, listID: string): void;
  saveNewOpening(opening: any): void;
  editOpeningName(opening: any): void;
  editOpeningBackground(opening: any): void;
  setStarredOpening(opening: any): void;
  moveList(listID: string, selectedPosition: string): void;
  moveListAnotherBoard(listID: string, selectedPosition: string): void;
  moveApplicantAnotherBoard(applicant: any): void;
  copyApplicantAnotherBoard(applicant: any): void;
  removeMember(memberID: string, applicantID: string): void;
  addMemberOnOpening(memberID: string, applicantID: string): void;
  removeMemberFromOpening(memberID: string, applicantID: string): void;
  removeAttachment(attachment: any, listID: string): void;
  updateAttachmentName(attachment: Attachment): void;
  removeLabel(labelID: string, applicantID: string, list_id: string): void;
  addActivity(activity: any, tag: string): void;
  updateActivity(activity: any): void;

  archiveList(list: any): void;
  resetWs(): void;

  websocketActionRequestQueue: wsAction[];
  sendBackArchivedList(archiveList: any): void;
  sendBackArchivedApplicant(archivedApplicant: any): void;
  dequeueWsRequest(wsAction: any): void;
  reconnectWSRequestAction(): void;
  updateWSRequestAction(wsAction: any): void;
  wsConnectionObject: WebSocket;
  clientId: string;
  addWsRequest(actionRequest: any): void;

  //candidateDb
  addCandidateInDB(Candidate: Candidate): void;
  addCandidateInReview(
    candidate: CandidateShortReview[],
    reviewTag: boolean
  ): void;
  addCommentReview(candidates: ReviewComment, tag: string): void;
  removeLabelFromCandidate: (candidateId: string, labelsId: string[]) => void;
  addLabelToCurrentCandidateInCDB(data: any, candidateId: string): void;
  //review
  saveReviewLabel(rID: string, label: any): void;
  removeMemberOnReview(memberID: any, applicantID: string): void;
  addMemberOnReview(mem: any, reviewId: string): void;
  updateCommentOfReview(comment: comment): void;
  removeLabelFromReview: (rId: string, label: string[]) => void;

  //calendar
  addEventOnBoardCalender: (event: Event) => void;
  editEventFromBoard: (data: any) => void;
  deleteEventWithIdFromBoard: (event_id: string) => void;
  addIncomingInvitations: (data: any) => void;
  deleteEventWithIdFromCard: (id: string) => void;
  editEventFromCard: (data: any) => void;

  //BILLING
  addMemberInBilling(members: BillingMember[]): void;
  removeBillingMember: (memberId: string) => void;
  userNotificationSettingIntegrationAction(data: any): void;
  notificationOptions: NotificationOptions;
  showDotOnUpdates: (showDot: boolean) => void;
  addUnreadDots: (payload: any) => void;
  applicationName:string

};
let customLogoutChannel:string="";
if(process.env.REACT_APP_LOGOUT_BROADCAST_CHANNEL){
  customLogoutChannel=process.env.REACT_APP_LOGOUT_BROADCAST_CHANNEL
}
export let logoutChannel = new BroadcastChannel(customLogoutChannel)
const WSComponent = (props: Props) => {
  let logoutChannel = new BroadcastChannel(customLogoutChannel)
useEffect(() => {       
  logoutChannel.onmessage = () => {
    window.location.replace(`${process.env.REACT_APP_WEB_URL}/login`);
    logoutChannel.close();
  }
},[]);

const setupHeartbeat = () => {
  let ws = props.wsConnectionObject;

  const heartbeatInterval = setInterval(() => {
    if (ws?.readyState === WebSocket.OPEN) {
     

      props.addWsRequest({type: "CHECK_CONNECTION",
        model_type: "heartbeat",
        model_id: "",
        user_id: props.loginUser.user.id,
        org_id: props.applicationId,
        socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
        time: Math.floor(new Date().getTime() / 1000)});
    }
  }, 5000);

  return () => clearInterval(heartbeatInterval);
};

useEffect(() => {
  setupHeartbeat();
}, [props.wsConnectionObject]);

  useEffect(() => {
    if (tokenVerify()) {
      let token = localStorage.getItem("login_token");
      let ws = props.wsConnectionObject;
      if (ws.readyState === WebSocket.CLOSED) {
        ws = new WebSocket(
          `${process.env.REACT_APP_WS_URL}/v1/rtu/pub/ws?Authorization=Bearer ${token}`
        );
        props.initWs(ws);
        props.addWsRequest({
          type: SUBSCRIBE,
          model_type: "member",
          model_id: props.loginUser.user.id,
          user_id: props.loginUser.user.id,
          socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
          org_id: "",
          time: Math.floor(new Date().getTime() / 1000),
        });
      }

      // const heartbeat = () => {
      //   if (!ws) return;
      //   if (ws.readyState !== 1) return;
      //   ws.send(`{"action":"CHECK_CONNECTION","topic":"heartbeat"}`);
      //   setTimeout(heartbeat, 10000);
      // }

    
      ws.onmessage = (event: any) => {
        var msg = JSON.parse(event.data);

        if (msg?.event?.type == "notification") {
          if (Notification.permission === "granted") {
           

            new Notification(`You got a new notification ${msg?.event?.org_name?"in "+capitalizeFirstLetter(msg?.event?.org_name):""} `, {
              body:(capitalizeFirstLetter( msg.event.data.deltas.entities.map((entity:any)=>entity.text).join(' ')))
               
              ,
              icon: "src/images/Tacitbase_WT_8.png", // You can use a custom icon
              
              // icon: '/path/to/icon.png', // You can use a custom icon
              });
          } 

          window.location.pathname == `/o/${props.applicationId}/update`
            ? props.showDotOnUpdates(false)
            : props.showDotOnUpdates(true);

        if(props.applicationId==msg?.event?.org_id||msg?.event?.org_id==''){( 
          
           props.addNotificationToUpdates(msg?.event),
          props.addUnreadDots({items:[msg?.event]})
          )
        }
        }
        else if(msg?.event?.type == "websocket"){
          handleWebSocketResponse(msg?.event,({loginUser:props.loginUser,websocket:
            props.websocket,
           orgId: props.applicationId,
          orgName:  props.applicationName,
          
           boards: props.boards,
           currentOpening: props.currentOpening,
         orgMembers:   props.member,
          
         currentApplicantId:    props.currentApplicantId,
         boardList:    props.boardList,
         clientId:   sessionStorage.getItem(SOCKET_CLIENT_ID),
           
         currentReviewId:    props.currentReviewId,
            // ws
            wsConnectionObject:   props.wsConnectionObject,
          }))
        }
        else {
          switch (msg?.notify?.action) {
            // set client id
            case "Error":
              break;

            case "MESSAGE":
              // props.dequeueWsRequest(
              //   JSON.parse(msg.notify.data.replace(/'/g, '"'))
              // );
              break;

            case "SET_CLIENT_ID":
              props.addClientId(msg.data);
              break;
            //lists
            case "ADD_LIST":
              props.addList(msg.data);
              break;
            case "EDIT_LIST_NAME":
              props.updateListName(msg.data);
              break;
            case "ARCHIVED_LIST":
              if (msg.data) {
                props.archiveList(msg.data);
              } else {
                props.sendBackArchivedList(msg.data);
              }
              break;
            case "ARCHIVED_APPLICANTS_OF_LIST":
              props.archivedApplicants(msg.data.id, msg.data.opening_id);
              break;
            case "MOVE_APPLICANTS_OF_LIST":
              props.moveAllApplicants(msg.data);
              break;
            case "MOVE_LIST": {
              if (msg.data.type === "DRAG") {
                const drag = {
                  draggableId: msg.data.src_list.id,
                  type: "list",
                  source: {
                    index: msg.data.src_list.position,
                    droppableId: "all-lists",
                  },
                  destination: {
                    droppableId: "all-lists",
                    index: msg.data.dst_list.position,
                  },
                };
                props.dragList(drag);
              } else {
                if (msg.data.destination === "move_to_same_opening") {
                  props.moveList(msg.data, msg.data.position);
                }
                if (msg.data.destination === "move_to_another_opening") {
                  props.moveListAnotherBoard(msg.data, msg.data.position);
                }
              }
              break;
            }
            case "ADD_APPLICANT":
              props.saveApplicant(msg.data);
              break;
            case "RENAME_APPLICANT":
              props.updateApplicantName(msg.data);
              break;
            case "UPDATE_APPLICANT_DESCRIPTION":
              props.editDesc(msg.data);
              break;
            case "MOVE_APPLICANT":
              if (msg.data.type === "DRAG") {
                props.dragCard(msg.data);
              } else {
                if (msg.data.destination === "move_to_same_opening") {
                  props.move(
                    msg.data,
                    msg.data.from.list_id,
                    msg.data.position
                  );
                }
                if (msg.data.destination === "move_to_another_opening") {
                  props.moveApplicantAnotherBoard(msg.data);
                }
              }
              break;
            case "ARCHIVE_APPLICANT":
              if (msg.data.archive) {
                props.archiveUnArchive(msg.data);
              } else {
                props.sendBackArchivedApplicant(msg.data);
              }
              break;
            case "UNARCHIVED_APPLICANT":
              props.sendBackArchivedApplicant(msg.data);
              break;
            case "COPY_APPLICANT":
              if (
                msg.data.copy_applicant.destination === "copy_to_same_opening"
              ) {
                props.copyApplicant(
                  msg.data.copy_applicant,
                  msg.data.copy_applicant.position
                );
              }
              if (
                msg.data.copy_applicant.destination ===
                "copy_to_another_opening"
              ) {
                props.copyApplicantAnotherBoard(msg.data.copy_applicant);
              }
              break;
            // attachments
            case "ADD_ATTACHMENT_ON_APPLICANT":
              props.addAttachements(msg.data, msg.data.list_id);
              break;
            case "REMOVE_ATTACHMENT_FROM_APPLICANT":
              props.removeAttachment(msg.data, msg.data.list_id);
              break;
            case "UPDATE_ATTACHMENT_NAME_OF_APPLICANT":
              props.updateAttachmentName(msg.data);
              break;
            //labels
            case "ADD_OPENING_LABEL":
              props.saveOpeningLabel(msg.data, msg.data.opening_id);
              break;
            case "ADD_LABEL_ON_APPLICANT":
              props.saveApplicantLabel(msg.data);
              break;
            case "UPDATE_LABEL":
              props.updateApplicantLabel(msg.data);
              break;
            case "REMOVE_LABEL_FROM_APPLICANT":
              props.removeLabel(
                msg.data.label_id,
                msg.data.applicant_id,
                msg.data.list_id
              );
              break;
            //opening
            case "ADD_OPENING":
              props.saveNewOpening(msg.data);
              break;
            case "UPDATE_OPENING_NAME":
              props.editOpeningName(msg.data);
              break;
            case "SET_BACKGROUND_COLOR":
              props.editOpeningBackground(msg.data);
              break;
            case "SET_OPENING_STARRED":
              props.setStarredOpening(msg.data);
              break;
            case "UPDATE_OPENING_ACCESS":
              props.updateBoardVisibility(msg.data.accessibility);
              break;
            // members
            case "ADD_MEMBER_ON_APPLICANT":
              if (
                !Object.keys(props.boardList).includes(msg.data[0].applicant_id)
              ) {
                getApplicant(msg.data[0].applicant_id).then(
                  (res: Applicant) => {
                    props.saveApplicant(res);
                  }
                );
              }
              props.addMember(msg.data, msg.data[0].applicant_id);
              break;
            case "REMOVE_MEMBER_FROM_APPLICANT":
              props.removeMember(msg.data.id, msg.data.applicant_id);
              break;
            case "ADD_MEMBER_ON_OPENING":
              if (
                !Object.keys(props.boards.pageSection.openings).includes(
                  msg.opening_id
                )
              ) {
                getBoardByid(msg.opening_id).then((opening: Opening) => {
                  props.saveNewOpening(opening);
                });
              }
              props.addMemberOnOpening(msg.data, msg.opening_id);
              break;
            case "REMOVE_MEMBER_FROM_OPENING":
              if (
                Object.keys(props.boards.pageSection.openings).includes(
                  msg.opening_id
                )
              ) {
                if (
                  props.boards.pageSection.openings[msg.opening_id].member_only
                ) {
                  let updatedOpening = Object.values(
                    props.boards.pageSection.openings
                  ).filter((opening: Opening) => opening.id != msg.opening_id);
                  props.listOfBoards(updatedOpening);
                }
              }
              if (props.currentOpening.id == msg.opening_id) {
                props.removeMemberFromOpening(msg.data.id, msg.opening_id);
              }
              break;
            // comment
            case "ADD_COMMENT":
              props.saveComment(msg.data, COMMENT_TAG);
              break;
            case "UPDATE_COMMENT":
              props.updateComment(msg.data);
              break;

            //activity
            case "ADD_ACTIVITY":
              props.addActivity(msg.data, ACTIVITY_TAG);
              break;
            case "UPDATE_ACTIVITY":
              props.updateActivity(msg.data);
              break;

            //slack
            case "ADD_SLACK_SUBSCRIBER":
              props.integratedOptions(msg.data);
              break;

            //candidateDb
            case "ADD_CANDIDATE":
              props.addCandidateInDB(msg.data.candidates);
              break;
            case "UPDATE_CANDIDATE":
              props.addCandidateInDB(msg.data.candidates);
              break;
            //review
            case "CREATE_REVIEW_FOR_CANDIATES":
              props.addCandidateInReview(msg.data.reviews, false);
              break;
            case "ADD_COMMENT_TO_REVIEW":
              props.addCommentReview(msg.data.comments, COMMENT_TAG);
              break;
            case "REMOVE_LABEL_FROM_CANDIDATE":
              props.removeLabelFromCandidate(
                msg.data.candidate_labels.candidate_ids[0].id,
                msg.data.candidate_labels.label_ids
              );
              break;
            case "ADD_LABEL_TO_CANDIDATE":
              props.addLabelToCurrentCandidateInCDB(
                msg.data.candidate_labels.labels,
                msg.data.candidate_labels.id
              );
              break;

            //calendar
          
            //review
            case "ADD_LABEL_TO_REVIEW":
              props.saveReviewLabel(
                msg.data.review_labels.id,
                msg.data.review_labels.labels
              );
              break;
            case "REMOVE_LABEL_FROM_BULK_REVIEW":
              props.removeLabelFromReview(
                msg.data.reviews_labels.review_ids[0],
                msg.data.reviews_labels.label_ids
              );
              break;
            case "REMOVE_MEMBER_FROM_REVIEW":
              props.removeMemberOnReview(
                msg.data.review_members.members,
                msg.data.review_members.id
              );
              break;
            case "ADD_MEMBER_TO_REVIEW":
              props.addMemberOnReview(
                msg.data.review_members.members,
                msg.data.review_members.id
              );
              break;
            case "UPDATE_COMMENT_TO_REVIEW":
              props.updateCommentOfReview(msg.data.comments);
              break;
            case "INVITE_USER":
              props.addIncomingInvitations(msg.data);
              break;

            //billing
            case "ADD_MEMBER_TO_BILLING":
              props.addMemberInBilling(msg.data);
              break;
            case "REMOVE_MEMBER_FROM_BILLING":
              props.removeBillingMember(msg.data[0].id);
              break;
          }
        }
      };

      ws.onclose = () => {
        props.reconnectWSRequestAction();
      };
    }
  }, []);
  return <></>;
};

const mapPropsToState = (dispatch: Dispatch) => ({
  addNotificationToUpdates: (notification: NotificatioRTU) =>
    dispatch(addNotificationFromWs(notification)),
  initWs: (ws: WebSocket) => dispatch(initWs(ws)),
  resetWs: () => dispatch(resetWebsocket()),

  addClientId: (id: any) => dispatch(clientId(id)),

  archiveUnArchive: (applicantID: string) =>
    dispatch(updateApplicantArchiveStatus(applicantID)),

  dragCard: (result: any) => dispatch(draggableCard(result)),

  //applicant
  saveApplicant: (applicant: any) => {
    dispatch(addApplicant(applicant));
  },
  updateApplicantName: (nameObj: any) => {
    dispatch(editName(nameObj));
  },
  editDesc: (desc: string) => {
    dispatch(editApplicantDesc(desc));
  },
  move: (
    applicant: any,
    currentListId: any,
    selectedApplicantPosition: any
  ) => {
    dispatch(move(applicant, currentListId, selectedApplicantPosition));
  },
  moveApplicantAnotherBoard: (applicant: any) => {
    dispatch(moveApplicantWS(applicant));
  },
  copyApplicant: (applicant: any, index: number) => {
    dispatch(copyApplicantAction(applicant, index));
  },
  copyApplicantAnotherBoard: (applicant: any) => {
    dispatch(copyApplicantWS(applicant));
  },

  sendBackArchivedApplicant: (archivedApplicant: any) => {
    dispatch(sendBackArchivedItem(archivedApplicant));
  },

  //lists
  addList: (list: any) => {
    dispatch(addList(list));
  },
  updateListName: (list: any) => {
    dispatch(updateListName(list));
  },
  archiveList: (list: any) => {
    dispatch(archiveList(list));
  },
  archivedApplicants: (listsID: any, openingID: string) => {
    dispatch(archivedAllListApplicants(listsID, openingID));
  },
  moveAllApplicants: (list: any) => {
    dispatch(moveAllApplicants(list));
  },
  moveList: (listID: string, selectedPosition: string) => {
    dispatch(moveListAction(listID, selectedPosition));
  },
  dragList: (result: any) => {
    dispatch(draggableList(result));
  },
  moveListAnotherBoard: (listID: string, selectedPosition: string) => {
    dispatch(moveListWS(listID, selectedPosition));
  },
  sendBackArchivedList: (archiveList: any) => {
    dispatch(sendBackArchivedList(archiveList));
  },

  // attachment
  addAttachements: (attachment: any, listID: string) => {
    dispatch(addAttachments(attachment, listID));
  },
  updateAttachmentName: (user: any) => dispatch(updateAttachment(user)),

  removeAttachment: (attachment: any, listID: string) => {
    dispatch(deleteAttachment(attachment, listID));
  }, // 1

  //openings
  saveNewOpening: (opening: any) => {
    dispatch(addNewOpening(opening));
  },
  editOpeningName: (board: any) => {
    dispatch(updateOpeningName(board));
  },
  editOpeningBackground: (opening: any) => {
    dispatch(setBoardBackgroundColor(opening));
  },
  setStarredOpening: (opening: any) => {
    dispatch(starredOpening(opening));
  },
  listOfBoards: (boardData: Opening[]) => dispatch(listOfBoards(boardData)),
  updateBoardVisibility: (board: Opening) =>
    dispatch(updateBoardVisibility(board)),
  // comment
  saveComment: (comment: any, listID: string) => {
    dispatch(saveComment(comment, listID));
  },
  updateComment: (comment: any) => {
    dispatch(updateComment(comment));
  },
  //activity
  addActivity: (comment: any, tag: string) => {
    dispatch(addActivity(comment, tag));
  },
  updateActivity: (comment: any) => {
    dispatch(updateActivity(comment));
  },
  // label
  saveApplicantLabel: (label: any) => {
    dispatch(saveCardLabel(label));
  },
  saveOpeningLabel: (label: any, openingId: string) => {
    dispatch(saveBoardLabel(label, openingId));
  },
  removeLabel: (labelID: string, applicantID: string, list_id: string) => {
    dispatch(removeLabelFromCard(labelID, applicantID, list_id));
  },
  updateApplicantLabel: (updatedLabel: any) => {
    dispatch(updateLabelState(updatedLabel));
  },

  // members
  addMember: (members: any, applicantID: string) => {
    dispatch(addMemberOnCard(members, applicantID));
  },
  removeMember: (memberID: string, applicantID: string) => {
    dispatch(removeMemberFromCard(memberID, applicantID));
  },
  addMemberOnOpening: (members: any, applicantID: string) => {
    dispatch(addMemberOnBoard(members, applicantID));
  },
  removeMemberFromOpening: (memberID: string, applicantID: string) => {
    dispatch(removeMemberFromOpening(memberID, applicantID));
  },
  //slack
  integratedOptions: (data: any) => dispatch(slackIntegration(data)),

  //websocket
  dequeueWsRequest: (wsAction: any) => {
    dispatch(dequeueWsRequest(wsAction));
  },
  reconnectWSRequestAction: () => {
    dispatch(reconnectWSRequestAction());
  },
  updateWSRequestAction: (wsAction: any) => {
    dispatch(updateWsRequestStatus(wsAction));
  },
  addWsRequest: (actionRequest: any) => dispatch(addWsRequest(actionRequest)),
  //cdb
  addCandidateInDB: (candidate: Candidate) =>
    dispatch(addCandidateInDB(candidate)),
  addCandidateInReview: (
    candidates: CandidateShortReview[],
    reviewTag: boolean
  ) => dispatch(addCandidateInReview(candidates, reviewTag)),
  addCommentReview: (candidates: ReviewComment, tag: string) =>
    dispatch(addCommentReview(candidates, tag)),
  removeLabelFromCandidate: (candidateId: string, labelsId: string[]) =>
    dispatch(removeLabelFromCandidate(candidateId, labelsId)),
  addLabelToCurrentCandidateInCDB: (data: any, candidateId: string) =>
    dispatch(addLabelToCurrentCandidateInCDB(data, candidateId)),
  //calendar
  addEventOnBoardCalender: (event: Event) =>
    dispatch(addEventOnBoardCalender(event)),
  editEventFromBoard: (data: any) => dispatch(editBoardEvent(data)),
  deleteEventWithIdFromBoard: (event_id: string) =>
    dispatch(deleteBoardEvent(event_id)),
  deleteEventWithIdFromCard: (event_id: string) =>
    dispatch(deleteCardEvent(event_id)),
  
  editEventFromCard: (data: any) => dispatch(editCardEvent(data)),

  //review
  saveReviewLabel: (rId: string, label: any) =>
    dispatch(addLabelToCandidateInReview(rId, label)),
  removeMemberOnReview: (memberID: any, applicantID: string) =>
    dispatch(removeMemberOnReview(memberID, applicantID)),
  addMemberOnReview: (members: any, reviewId: string) =>
    dispatch(addMemberOnReview(members, reviewId)),
  addIncomingInvitations: (data: any) => dispatch(addIncomingInvitation(data)),
  updateCommentOfReview: (comment: comment) =>
    dispatch(updateCommentOfReview(comment)),
  removeLabelFromReview: (rId: string, label: string[]) =>
    dispatch(removeLabelFromReview(rId, label)),

  //BILLING
  addMemberInBilling: (members: BillingMember[]) =>
    dispatch(addMemberInBilling(members)),
  removeBillingMember: (memberId: string) =>
    dispatch(removeBillingMember(memberId)),
  userNotificationSettingIntegrationAction: (data: any) =>
    dispatch(userNotificationSettingIntegration(data)),
  showDotOnUpdates: (showDot: boolean) =>
    dispatch(showDotOrNotOnUpdates(showDot)),
    addUnreadDots: (payload: any) => dispatch(addDots(payload)),
});
const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  websocket: state.opening.currentWsRequest,
  applicationId: state.opening.applicationId,
  applicationName: state.opening.applicationName,

  boards: state.opening.openings,
  currentOpening: state.opening.currentOpening,
  member: state.opening.members,

  currentApplicantId: state.opening.currentApplicant.id,
  boardList: state.opening.boardList,
  clientId: state.opening.clientId,
  currentMailKey: state.opening.currentApplicantMailThread
    ? state.opening.currentApplicantMailThread[0]?.message[0]?.key
    : "",
  currentReviewId: state.opening.currentCandidateInReview.id,
  // ws
  wsConnectionObject: state.opening.wsConnection,
  websocketActionRequestQueue: state.opening.websocketActionRequestQueue,
  notificationOptions: state.opening.notificationOptions,
});

export default connect(mapStateToProps, mapPropsToState)(WSComponent);

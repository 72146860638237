import React from "react";
import { connect } from "react-redux";
import { SelectPositionProps } from "../../../type/copyCard";
import { Select, Typography } from "antd";
const {Text} = Typography;
const SelectPosition = (props: SelectPositionProps): any => {

  const handleChange = (value: number) => {
    if (props.event) {
      props.event(value.toString());
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      onClick={handleClick}
      role="button"
      tabIndex={-1}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <Text strong>Position</Text>
      <Select
        className="w-full"
        onChange={handleChange}
        getPopupContainer={(trigger) => trigger.parentNode}
        dropdownStyle={{ zIndex: 9999 }}
      >
        {props.value.map((option: any, index: number) => (
          <Select.Option
            key={index}
            value={index+1}
            selected={option.id === props.comparedId}
          >
            {index + 1}
          </Select.Option>
        ))}
        {props.move ? (
          props.sourceId === props.extraIndexCheck ? null : (
            <Select.Option value={props.value.length + 1}>
              {props.value.length + 1}
            </Select.Option>
          )
        ) : (
          <Select.Option value={props.value.length + 1}>
            {props.value.length + 1}
          </Select.Option>
        )}
      </Select>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  lists: state.opening.lists,
  currentApplicant: state.opening.currentApplicant,
  keep: state.opening.keepCopyApplicant,
  tasks: state.opening.tasks,
  labels: state.opening.labels,
  members: state.opening.members,
  // comments: state.opening.comments,
});
export default connect(mapStateToProps)(SelectPosition);

export function set_esign_details(state: any, action: any) {
    return {
      ...state,
      eSignDetails: action.payload,
    };
  }
  export const update_esign_details = (state: any, action: any) => {
    return {
      ...state,
      eSignDetails: {
        ...state.eSignDetails,
        ...action.payload,  
      },
    }
  }
  export const  clear_esign_details = (state: any)=>{
    return {
      ...state,
      eSignDetails: {
        ...state.eSignDetails,
        documents: [],
        eSignDoc:[],
        allDocuments: [],
      },
    }
  }
import React  from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Result, Spin } from "antd";


type LoadingSteps = {
    createOpening: boolean;
    addFormToJd: boolean;
    createJd: boolean;
}
type SuccessSkeletonProps = {
  loadingSteps:LoadingSteps,
  draftSave:boolean;
};

// showing skeleton while api's processing
const SuccessSkeleton = (props: SuccessSkeletonProps) => {
  return (
    <div className="flex flex-col w-full justify-center items-center">
      <div className="w-8/12">
        <div className="w-full overflow-y-scroll">
          <Result
            status="success"
            icon={
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            }
            title={
              <span
                className="text-sm animate-pulse"
                style={{ color: "#009D79" }}
              >
                Your job post is being prepared. It will be live in just a moment!!
              </span>
            }
            subTitle={
            <span className="animate-pulse">
            {props.draftSave ? "": "Job posted on partner site will take at least 90 minutes to go live."  } 
            </span>
            }
          />
        </div>
        <div className="flex flex-col items-center">
          <div className="w-full max-w-lg mb-6">
            {!props.draftSave &&(<span className="block mb-2 font-bold text-gray-700 text-center animate-pulse">
              Job post link
            </span>)}
            {!props.draftSave &&(<div className="flex bg-gray-200 h-10 justify-between border p-2 items-center rounded animate-pulse" />)}
            {!props.loadingSteps.createOpening && (
              <span className="block mt-2 font-semibold text-gray-700 text-base text-center animate-pulse">
                Creating job board
              </span>
            )}
            {!props.loadingSteps.addFormToJd && props.loadingSteps.createOpening && (
              <span className="block mt-2 font-semibold text-gray-700 text-base text-center animate-pulse">
                Attaching form to your job post
              </span>
            )}
            {!props.loadingSteps.createJd && props.loadingSteps.addFormToJd && (
              <span className="block mt-2 font-semibold text-gray-700 text-base text-center animate-pulse">
                Creating job post
              </span>
            )}
          </div>
          <div className="w-full flex space-y-2 flex-col justify-center items-center animate-pulse"></div>
        </div>
      </div>
    </div>
  );
};

export default SuccessSkeleton;

import React, { useState } from "react";
import { Space, Upload } from "antd";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import { uploadAttachmentOfCandidate } from "../../../../../lib/api";
import { CANDIDATES } from "../../../../../constant";
import { CurrentCandidateInPool } from "../../../../../redux/reducers/initialState";
import { connect } from "react-redux";
import {
  addAttachmentUploadStatus,
  addNewAttachmentToCurrentCandidateInAtsPool,
} from "../../../../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { Attachment, loginUser } from "../../../../../type/type";
import { throwSuccess } from "../../../../../service/throwError";
import { OrgInfo } from "../../../../../lib/apiRes";
import { SecondaryButton } from "../../../../../ant/Button";
import AttachmentIcon from "../../../../Icons/AttachmentIcon";

// Type definitions for component props
type UploadStatus = {
  uploading: boolean;
  fileList: UploadFile[];
  uploadResponse: any;
  uploadError: any;
};

type AddAttachmentProps = {
  currentCandidateInPool: CurrentCandidateInPool;
  addNewAttachmentToCurrentCandidateInAtsPool: (attachments: Attachment[]) => void;
  addAttachmentUploadStatus: (status: UploadStatus) => void;
  loginUser:loginUser;
  org:OrgInfo
};

// Main component to add attachments to a candidate in the pool
const AddAttachmentToPoolCandidate: React.FC<AddAttachmentProps> = (props) => {
  const [uploading, setUploading] = useState(false);

  // Handler for file changes
  const handleFileChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    let updatedFileList = [...info.fileList];
    updatedFileList = updatedFileList.slice(-5); // Only keep the last 5 files


    setUploading(true);

    // Update the upload status in the Redux store
    props.addAttachmentUploadStatus({
      uploadResponse: "",
      uploadError: "",
      uploading: true,
      fileList: updatedFileList,
    });

    const formData = new FormData();

    // Append files to form data
    updatedFileList.forEach((file) => {
      if (file.originFileObj instanceof Blob) {
        formData.append("file", file.originFileObj);
      }
    });

    // Upload files to the server
    uploadAttachmentOfCandidate(formData, CANDIDATES, props.currentCandidateInPool.candidate.id,props.org.id,props.loginUser.user.id)
      .then((res) => {
        throwSuccess("Attachment uploaded successfully.");
        setTimeout(() => {
          props.addAttachmentUploadStatus({
            uploadResponse: "",
            uploadError: "",
            uploading: false,
            fileList: [],
          });
        }, 0);
        setTimeout(() => {
          props.addNewAttachmentToCurrentCandidateInAtsPool(res);
        }, 100);
      })
      .catch(() => {
        // Handle upload error
      })
      .finally(() => {
        setUploading(false);
      });
  };

  // Prevent automatic upload
  const handleBeforeUpload = () => {
    return false;
  };

  // Adjust upload button width to full width
  const uploadButtons = document?.getElementsByClassName("ant-upload ant-upload-select");
  if (uploadButtons) {
    for (let i = 0; i < uploadButtons.length; i++) {
      const element = uploadButtons[i];
      if (element instanceof HTMLElement) {
        element.style.width = "100%";
      }
    }
  }

  return (
    <div className="w-full">
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <Upload
          className="w-full"
          showUploadList={false}
          beforeUpload={handleBeforeUpload}
          onChange={handleFileChange}
          fileList={[]}
        >
          <SecondaryButton className="w-full truncate left-align-button" disabled={uploading} icon={<AttachmentIcon />} onClick={()=>{}} >
            Add attachment
          </SecondaryButton>
        </Upload>
      </Space>
    </div>
  );
};

// Map Redux state to component props
const mapStateToProps = (state: any) => ({
  currentCandidateInPool: state.opening.currentCandidateInPool,
  loginUser: state.opening.loginUser,
  org:state.opening.currentOrg,
});

// Map Redux dispatch actions to component props
const mapDispatchToProps = (dispatch: Dispatch) => ({
  addAttachmentUploadStatus: (status: UploadStatus) => dispatch(addAttachmentUploadStatus(status)),
  addNewAttachmentToCurrentCandidateInAtsPool: (attachments: Attachment[]) =>
    dispatch(addNewAttachmentToCurrentCandidateInAtsPool(attachments)),
});

// Connect component to Redux store
export default connect(mapStateToProps, mapDispatchToProps)(AddAttachmentToPoolCandidate);

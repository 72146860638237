import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Applicant, loginUser, orgMember } from "../../../../type/type.d";
import { SecondaryButton } from "../../../../ant/Button";
import { ScheduleOutlined } from "@ant-design/icons";
import { getIntegratedCalender } from "../../../../lib/api";
import { Dispatch } from "redux";
import { setCardCalEvents } from "../../../../redux/actions/opening/action";
// import EventFormModal from "../../../calender/global/CreateEvent";
import CreateEventEmptyScreen from "./CreateEventEmptyScreen";
import EventFormModal from "../../../calender/global/CreateEvent";
import { setConnectedClient } from "../../../../redux/reducers/calendarSlice";
type CardEventProps = {
  currentApplicant: Applicant;
  cardEvents: Event[];
  members: orgMember;
  loginUser: loginUser;
};
const CardEvent = (props: CardEventProps) => {
  const dispatch = useDispatch();

  const [addEventPopup, setAddEventPopup] = useState(false);
  const [calendarConnected, setCalendarConnected] = useState(false)
  useEffect(() => {

    getIntegratedCalender(
      props.loginUser.user.id).then((res) => {
        if(res)
        setCalendarConnected(true)
        dispatch(setConnectedClient(res.client));

      }).catch(() => {

        setCalendarConnected(false)
      })
  }, [])
  return (
    <>
      {props.currentApplicant ? (
        !props.currentApplicant.archive ? (
          <div>
            <SecondaryButton
              className="w-full flex truncate justify-start"
              onClick={() => {
                setAddEventPopup(true);
              }}
              icon={<ScheduleOutlined />}
            >
              Schedule interview
            </SecondaryButton>
          </div>
        ) : null
      ) : null}

      {addEventPopup ? (
        calendarConnected ? (
          <EventFormModal
            closeModal={() => setAddEventPopup(false)}
          />
        ) : (
          <CreateEventEmptyScreen onClose={() => setAddEventPopup(false)} />
        )
      ) : null}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  clientId: state.opening.clientId,
  roles: state.opening.roles,
  cal_id: state.opening.calendar.currentCalenderId,
  cardEvents: state.opening.calendar.currentCardEvents,
  boardEvents: state.opening.calendar.currentBoardEvents,
  currentApplicantRewards: state.opening.currentApplicantRewards,
  members: state.opening.members,
  loginUser: state.opening.loginUser,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  setCardEvents: (events: Event[]) => dispatch(setCardCalEvents(events)),
});

export default connect(mapStateToProps, mapPropsToState)(CardEvent);

import { Empty, Spin, Tabs,Typography } from "antd";
// import { TabsProps } from 'antd/lib';
import React, { useEffect, useState } from "react";
import CandidateInCDBActivity from "../tabs/CandidateInCDBActivity";
import TabPane from "antd/es/tabs/TabPane";
import CandidateInCDBTracker from "../tabs/CandidateInCDBTracker";
import CandidateInCDBReview from "../tabs/CandidateInCDBReview";
import CandidateInCDBMail from "../tabs/CandidateInCDBMail";
import { connect, useDispatch } from "react-redux";
import { eSignDetails, orgMember } from "../../../type/type";
import {  getAllDocumentsByCandidate, getAllDocumentsByCandidateId } from "../../../lib/api";
import { useParams } from "react-router-dom";
import { updateEsignDetails } from "../../../redux/actions/opening/action";
// import ShowDocumentStatus from "../../../styles/component/ESign/showDocumentStatus";
import GetDocumentById from "../../../styles/component/ESign/GetDocumentById";
import { countDocuments, setToString } from "../../../styles/component/ESign/Common";
const {Text} = Typography;
type CandidateInCDBTabsProps = {
  eSignDetails: eSignDetails;
  members:orgMember;
};
const CandidateInCDBTabs = (props: CandidateInCDBTabsProps) => {
  const [activeTab, setActiveTab] = useState<string>("Activity"); // State to track active tab
  const [docLoading, setDocLoading] = useState(false);
  const params: { candidate_id: string } = useParams();
  const dispatch = useDispatch();

  useEffect(()=>{
    setActiveTab("Activity")
  },[params.candidate_id])

  // Define tab titles and contents
  const tabData = [
    {
      key: "Activity",
      title: "Activity",
      content: <CandidateInCDBActivity activeTab={activeTab}/>,
    },
    {
      key: "Tracker",
      title: <span>Tracker</span>,
      content: <CandidateInCDBTracker activeTab={activeTab}/>,
    },
    {
      key: "Review",
      title: <span>Review</span>,
      content: <CandidateInCDBReview activeTab={activeTab}/>,
    },
    {
      key: "Mail",
      title: <span>Mail</span>,
      content: <CandidateInCDBMail activeTab={activeTab}/>,
    },
    {
      key: "documents",
      title: "Documents",
      content: (
        <div
          className="overflow-y-auto p-2 flex flex-col gap-2"
          style={{
            height: "calc(100vh - 225px )",
          }}
        >
          {props.eSignDetails?.documents?.length === 0 && props.eSignDetails?.allDocuments?.length === 0 ? (
            <Empty
            description="No documents found"
            className="flex flex-col h-full justify-center items-center"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
          ) : (
            docLoading ? (
              <div className="flex justify-center items-center h-full">
                <Spin size="small" />
              </div>
            ) : (
              <>
              {props.eSignDetails?.allDocuments?.length !== 0 && (
              <div className="flex flex-col gap-5">
              <div className="w-full max-w-2xl mx-auto p-6 bg-gray-100 rounded-lg">
                <div className="bg-white p-5 border rounded-lg shadow-md">
                  <div className="flex items-center justify-between mb-4">
                    <Text>Documents Sent to Candidates</Text>
                    <Text type ="secondary">
                      {props.eSignDetails?.allDocuments?.length} total
                    </Text>
                  </div>
                  <ul className="space-y-4 text-sm">
                    {Object.entries(countDocuments(props.eSignDetails?.allDocuments)).map(([key, items]: any) => (
                      <li key={key} className="flex justify-between items-center">
                        <div className="flex items-center space-x-3">
                          <Text className="font-medium">{key}</Text>
                        </div>
                        <div className="flex items-center space-x-2">
                          <Text type="secondary"> {items.count} document{items.count > 1 ? "s" : ""} sent by</Text>
                          <Text className="font-medium">{setToString(items.userIds, props.members)}</Text>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            )}
              <div className="flex flex-col gap-2">
              {props.eSignDetails?.documents?.map((doc) => (
                <GetDocumentById key={doc.id} doc={doc} />
              ))}
              </div>
              </>
            )
          )}
        </div>
      ),
    },
    // Add more tab data as needed
  ];

  const onChange = (key: string) => {
    setActiveTab(key);
    if(key === "documents"){
      if (props.eSignDetails.connected) {
        setDocLoading(true);
        getAllDocumentsByCandidate(params.candidate_id)
          .then((res) => {
            if (res.document) {
              setDocLoading(false);
              dispatch(updateEsignDetails({ documents: res.document }));
              return;
            }
            setDocLoading(false);
          })
          .catch(() => {
            setDocLoading(false);
            console.log("Failed to get all documents");
          });
      }
      getAllDocumentsByCandidateId(params.candidate_id)
        .then((res: any) => {
          if (res.offer_status) {
            dispatch(updateEsignDetails({ allDocuments: res.offer_status }));
            return;
          }
        })
    }
  };
  return (
    <div className="flex flex-col h-full justify-between items-baseline w-full comment-editor">
      <Tabs
        size="small"
        activeKey={activeTab}
        defaultActiveKey={activeTab}
        className="w-full "
        onChange={onChange} 
        tabBarStyle={{ marginBottom: -8 ,padding:"6.5px 16px" }}
      >
        {tabData.map((tab) => (
          <TabPane tab={tab.title} key={tab.key} className="py-3 ">
            {tab.content}
          </TabPane>
        ))}
      </Tabs> 
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  eSignDetails: state.opening.eSignDetails,
  members:state.opening.members
});

export default connect(mapStateToProps) (CandidateInCDBTabs);

import React from 'react';

const Skype: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      width="30"
      viewBox="-7.5 -12.5 65 75"
    >
      <circle fill="#28a8ea" r="14.5" cy="14.5" cx="14.5" />
      <circle fill="#28a8ea" r="14.5" cy="35.5" cx="35.5" />
      <circle fill="#28a8ea" r="24" cy="25" cx="25" />
      <path
        fillRule="evenodd"
        fill="#fff"
        d="M18.776 24.5443a7.2937 7.2937 0 01-2.2873-2.415 6.6719 6.6719 0 01-.7835-3.3355 5.9947 5.9947 0 011.4123-4.0371 8.501 8.501 0 013.6814-2.4607 14.2181 14.2181 0 014.8206-.811 18.7203 18.7203 0 013.2077.246 11.5793 11.5793 0 012.2235.5741 4.3117 4.3117 0 011.7406 1.1118 2.25 2.25 0 01.5376 1.4946 2.1653 2.1653 0 01-.565 1.5585 1.8812 1.8812 0 01-1.4216.5924 2.559 2.559 0 01-1.057-.2371 19.1342 19.1342 0 00-2.5152-.9204 9.13 9.13 0 00-2.4239-.3007 5.5322 5.5322 0 00-3.0346.7746 2.5251 2.5251 0 00-1.2119 2.2692 2.2815 2.2815 0 00.6287 1.5948 5.7338 5.7338 0 001.695 1.212q1.0663.529 3.1803 1.4036a3.2956 3.2956 0 01.4557.1823 22.5888 22.5888 0 013.9366 2.023 7.9373 7.9373 0 012.424 2.488 6.5598 6.5598 0 01.8748 3.4539 6.9475 6.9475 0 01-1.212 4.1648 7.2036 7.2036 0 01-3.3898 2.5153 14.2881 14.2881 0 01-5.094.8293 16.0498 16.0498 0 01-6.9437-1.3123 3.779 3.779 0 01-1.4307-1.1118 2.4995 2.4995 0 01-.4648-1.5129 1.9773 1.9773 0 01.6104-1.5401 2.2517 2.2517 0 011.5765-.556 4.0286 4.0286 0 011.8407.474q1.0567.5288 1.6677.793a8.382 8.382 0 001.4398.4556 8.2326 8.2326 0 001.8497.1912 4.937 4.937 0 003.1075-.8291A2.7366 2.7366 0 0028.9 31.2973a2.4117 2.4117 0 00-.656-1.6587 6.9745 6.9745 0 00-1.9318-1.4033q-1.276-.6743-3.5905-1.6404a22.2443 22.2443 0 01-3.9458-2.0506"
      />
      <path
        opacity=".1"
        fillRule="evenodd"
        d="M19.03.73a14.1527 14.1527 0 00-1.94-.5A26.0751 26.0751 0 00.23 17.09a14.1592 14.1592 0 00.5 1.94 10.9825 10.9825 0 00.67 1.67A23.9474 23.9474 0 0120.7 1.4a10.9902 10.9902 0 00-1.67-.67z"
      />
      <path
        opacity=".1"
        fillRule="evenodd"
        d="M19.03.73a25.0192 25.0192 0 00-18.3 18.3 10.9825 10.9825 0 00.67 1.67A23.9474 23.9474 0 0120.7 1.4a10.9902 10.9902 0 00-1.67-.67zm30.24 30.24a10.9818 10.9818 0 00-.67-1.67 23.9474 23.9474 0 01-19.3 19.3 10.984 10.984 0 001.67.67 14.1527 14.1527 0 001.94.5 26.0751 26.0751 0 0016.86-16.86 14.1614 14.1614 0 00-.5-1.94z"
      />
      <path
        opacity=".1"
        fillRule="evenodd"
        d="M48.6 29.3a23.9474 23.9474 0 01-19.3 19.3 10.984 10.984 0 001.67.67 25.019 25.019 0 0018.3-18.3 10.9818 10.9818 0 00-.67-1.67z"
      />
    </svg>
  );
};

export default Skype;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCalendarColors, fetchUserCalendarList, getIntegratedCalender } from "../../lib/api";
import { addCalendarList, addColors, reset, setConnectedClient } from "../../redux/reducers/calendarSlice";
import { loginUser } from "../../type/type";

const CalendarDetails = () => {
  const dispatch = useDispatch();
  const loginUserData: loginUser = useSelector(
    (state: any) => state.opening.loginUser
  );
  useEffect(() => {
    dispatch(reset());
    getIntegratedCalender(loginUserData.user.id)
    .then((res) => {
   
      dispatch(setConnectedClient(res.client));
    })
    .catch((error) => {
      console.log(error);
    });
    fetchCalendarColors(loginUserData.user.id)
      .then((colors:any) => {
        dispatch(addColors(colors));
      })
      .catch(() => {});

    fetchUserCalendarList(loginUserData.user.id).then((calendarList) => {
      dispatch(addCalendarList(calendarList.items));
    });
  }, [dispatch, loginUserData.user.id]);

  return <></>
};
export default CalendarDetails;

import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import styles from './minicalendar.module.css';

interface SimpleCalendarProps {
  value: Dayjs;
  onChange: (date: Dayjs) => void;
  onNavigate?: (date: Dayjs) => void;
}

const SimpleCalendar: React.FC<SimpleCalendarProps> = ({
  value,
  onChange,
  onNavigate
}) => {
  const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const currentDay = dayjs();
  
  const startOfMonth = value.startOf('month');
  const daysInMonth = value.daysInMonth();
  const startWeekDay = startOfMonth.day();
  
  // Calculate days from previous month to show
  const daysFromPrevMonth = startWeekDay;
  
  // Calculate days from next month to show to always fill 6 weeks (42 days)
  const totalDaysToShow = 42;
  const daysFromNextMonth = totalDaysToShow - (daysFromPrevMonth + daysInMonth);
  
  // Get last month's relevant dates
  const prevMonth = value.subtract(1, 'month');
  const prevMonthDays = prevMonth.daysInMonth();
  const prevMonthStart = prevMonthDays - daysFromPrevMonth + 1;

  const handlePrevMonth = () => {
    const newDate = value.subtract(1, 'month');
    onChange(newDate);
    onNavigate?.(newDate);
  };

  const handleNextMonth = () => {
    const newDate = value.add(1, 'month');
    onChange(newDate);
    onNavigate?.(newDate);
  };

  const handleDateClick = (dayNumber: number, isCurrentMonth: boolean) => {
    let newDate;
    if (!isCurrentMonth) {
      if (dayNumber > 20) { // Previous month
        newDate = value.subtract(1, 'month').date(dayNumber);
      } else { // Next month
        newDate = value.add(1, 'month').date(dayNumber);
      }
    } else {
      newDate = value.date(dayNumber);
    }
    onChange(newDate);
    onNavigate?.(newDate);
  };

  const renderDays = () => {
    const days = [];

    // Render previous month days
    for (let i = 0; i < daysFromPrevMonth; i++) {
      const dayNumber = prevMonthStart + i;
      days.push(
        <div
          role='button'
          onKeyDown={() => {}}
          tabIndex={0}
          key={`prev-${dayNumber}`}
          className={`${styles.day} ${styles.otherMonth}`}
          onClick={() => handleDateClick(dayNumber, false)}
        >
          {dayNumber}
        </div>
      );
    }

    // Render current month days
    for (let i = 1; i <= daysInMonth; i++) {
      const isToday = currentDay.date() === i && 
        currentDay.month() === value.month() &&
        currentDay.year() === value.year();
      const isSelected = value.date() === i;

      days.push(
        <div
          role='button'
          onKeyDown={() => {}}
          tabIndex={0}
          key={`current-${i}`}
          className={`${styles.day} 
            ${isToday ? styles.today : ''}
            ${isSelected ? styles.selected : ''}
          `}
          onClick={() => handleDateClick(i, true)}
        >
          {i}
        </div>
      );
    }

    // Render next month days
    for (let i = 1; i <= daysFromNextMonth; i++) {
      days.push(
        <div
          role='button'
          onKeyDown={() => {}}
          tabIndex={0}
          key={`next-${i}`}
          className={`${styles.day} ${styles.otherMonth}`}
          onClick={() => handleDateClick(i, false)}
        >
          {i}
        </div>
      );
    }

    return days;
  };

  return (
    <div className={styles.calendar}>
      <div className={styles.header}>
        <div className={styles.monthYear}>
          {value.format('MMMM YYYY')}
        </div>
        <div className={styles.navigation}>
          <Button 
            type="text" 
            icon={<LeftOutlined className='w-3 text-black' />} 
            onClick={handlePrevMonth}
          />
          <Button 
            type="text" 
            icon={<RightOutlined className='w-3 text-black' />} 
            onClick={handleNextMonth}
          />
        </div>
      </div>
      <div className={styles.weekDays}>
        {weekDays.map((day, index) => (
          <div key={index} className={styles.weekDay}>
            {day}
          </div>
        ))}
      </div>
      <div className={styles.days}>
        {renderDays()}
      </div>
    </div>
  );
};

export default SimpleCalendar;
import React, { useMemo } from "react";
import { Select } from "antd";
import dayjs from "dayjs";
import { ClockCircleOutlined } from "@ant-design/icons";

interface CustomTimePickerProps {
  mode: "start" | "end";
  value?: string;
  startTime?: string; // Only used when mode is "end"
  endTime?: string; // Only used when mode is "end"
  onChange: (value: string) => void;
  placeholder?: string;
}

const generateTimeOptions = (
  mode: "start" | "end",
  startTime?: string,
  endTime?: string
) => {
  const options: { value: string; label: string }[] = [];
  const startDayjs = startTime ? dayjs(startTime, "hh:mm A") : null;
  const endDayjs = endTime ? dayjs(endTime, "hh:mm A") : null;

  // Loop through the day (24 hours, 15-minute intervals)
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const current = dayjs().hour(hour).minute(minute).second(0).millisecond(0);
      const value = current.format("hh:mm A");

      let label = value;

      if (mode === "end" && startDayjs) {
        if (current.isAfter(startDayjs) && (!endDayjs || current.isBefore(endDayjs))) {
          // Calculate the duration in minutes from startTime
          const totalMinutes = current.diff(startDayjs, "minute");
          let durationLabel = "";

          if (totalMinutes >= 60) {
            const hours = Math.floor(totalMinutes / 60);
            const remainingMinutes = totalMinutes % 60;
            durationLabel = `${hours} hour${hours > 1 ? "s" : ""}${
              remainingMinutes ? ` ${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}` : ""
            }`;
          } else {
            durationLabel = `${totalMinutes} minute${totalMinutes > 1 ? "s" : ""}`;
          }

          label = `${value} (${durationLabel})`;
          options.push({ value, label });
        }
      } else if (mode === "start") {
        // For start mode, show all options in order
        options.push({ value, label });
      }
    }
  }

  return options;
};

const CustomTimePicker: React.FC<CustomTimePickerProps> = ({
  mode,
  value,
  startTime,
  endTime,
  onChange,
  placeholder = "Select Time",
}) => {
  const options = useMemo(
    () => generateTimeOptions(mode, startTime, endTime),
    [mode, startTime, endTime]
  );

  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={{ width: "130px" }}
      suffixIcon={<ClockCircleOutlined style={{ color: 'black', fontSize: "14px" }} />}
      dropdownStyle={{ width: mode === "end" ? '200px' : '150px' }}
      allowClear
    >
      {options.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CustomTimePicker;

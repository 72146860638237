
export const isIntegrationConnected = (
  eSignDetails: any,
  integrationType: string
) => {
  return (
    eSignDetails?.connected && eSignDetails?.tools?.includes(integrationType)
  );
};

export const isDocumentsSent = (eSignDetails: any) => {
  return eSignDetails?.allDocuments?.length > 0;
};


export const countDocuments = (offers:any) => {
  const counts:any = {};

  offers.forEach((offer:any) => {
    const jobName = offer.jd_name;
    const userId = offer.user_id;
    
    if (!counts[jobName]) {
      counts[jobName] = { userIds: new Set(), count: 0 };
    }
    
    counts[jobName].userIds.add(userId); 
    counts[jobName].count += 1; 
  });

  return counts;
};
const getUserById = (users: any, userId: any) => {
  const user = users[userId];
  if (user) {
    return `${user.first_name || ""} ${user.last_name || ""}`;
  }
  return "";
};
export const setToString = (set: any, users: any) => {
  return [...set].map(userId => getUserById(users, userId)).join(', ');
};
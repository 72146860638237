import React from "react";
import { connect } from "react-redux";
import { CheckedMemberProps } from "../../../type/cardActionButton";
import TickIcon from "../../Icons/TickIcon";
// import OutsideClick from "../../OutsideClick";
const CheckedMember = (props: CheckedMemberProps) => {
  return (
    <>
      {props.applicantMembers[props.currentApplicant.id]
        ? props.applicantMembers[props.currentApplicant.id].map(
            (applabelsID: any) =>
              props.members[props.selectedMemberId].id == applabelsID ? (
                <span key={applabelsID}>
                  <TickIcon />
                </span>
              ) : null
          )
        : null}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  members: state.opening.members,
  currentApplicant: state.opening.currentApplicant,
  applicantMembers: state.opening.applicantMembers,
});

export default connect(mapStateToProps)(CheckedMember);

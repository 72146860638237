import React from "react";
// Redux stuff
import { connect } from "react-redux";

import { CardPreviewLabelProps } from "../../../type/card";
import { Divider, Flex, Space, Tag, Typography } from "antd";
import { determineTextColor } from "../../../utils/CalculateBrightnessOfColor";
import { PhoneOutlined } from "@ant-design/icons";
import MailIcon from "../../Icons/MailIcon";
const { Text } = Typography;
const CardLabel = (props: CardPreviewLabelProps) => {
  // if (!props.applicantLabels[props.currentApplicant.id]) return null;

  return (
    <>
       {props.currentApplicant.candidate_id && props.currentApplicant.source ? (
        <>
         <Divider className="m-0"/>
        <div className="px-4">
          <div className="flex flex-wrap gap-6">
            <Space direction="vertical">
              <Flex  wrap="wrap" className="gap-4 h-full">
                <MailIcon />
                <Text>{props.currentApplicant.source.email}</Text>
              </Flex>
            </Space>
            {props.currentApplicant.source.phone && (
              <Space direction="vertical" >
                <Flex wrap="wrap" className=" h-full gap-4">
                  <PhoneOutlined />
                  <Text>{props.currentApplicant.source.phone}</Text>
                </Flex>
              </Space>
            )}
          </div>
        </div>
        </>
      ) : null}
    <div className="flex items-center mb-0 px-4">
      {props.applicantLabels[props.currentApplicant.id]?.length > 0 ? (
        <Space direction="vertical" className="mb-2">
          <Text strong>Labels</Text>
          <Flex gap="4px 0" wrap="wrap" className=" h-full">
            {props.applicantLabels[props.currentApplicant.id].map(
              (labelID: string, index: number) =>
                props.labels[labelID] ? (
                  <Tag
                    color={props.labels[labelID].color}
                    className="flex items-center h-7 max-w-full"
                    key={index}
                  >
                    <Text
                      ellipsis={{
                        tooltip: props.labels[labelID].name,
                      }}
                      style={{
                        color: props.labels[labelID].color
                          ? determineTextColor(props.labels[labelID].color)
                          : undefined,
                        maxWidth: "100%",
                      }}
                    >
                      {props.labels[labelID].name}
                    </Text>
                  </Tag>
                ) : null
            )}
          </Flex>
        </Space>
      ) : null}
    </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  labels: state.opening.labels,
  currentApplicant: state.opening.currentApplicant,
  applicantLabels: state.opening.applicantLabels,
});
export default connect(mapStateToProps)(CardLabel);

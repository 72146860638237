

import React from 'react';
import {
    Card, Button, Steps, Typography
} from 'antd';
import { 
  CalendarOutlined, 
  SettingOutlined, 
  ArrowRightOutlined, 
} from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';


const { Title, Text } = Typography;

const EmptyCalendar = () => {
    const history = useHistory()
    const params: { org_id: string } = useParams()
    const steps = [
    {
      title: 'Go to settings',
      description: 'Navigate to "Connect calendar" option below.',
    },
   
    {
      title: 'Select your platform',
      description: 'Choose between available calendar platforms.',
    },
  ];

  return (
    <div className="  bg-white space-y-6 w-full h-full   overflow-y-scroll ">
      {/* Main Connection Card */}
      <Card 
        className=" shadow-none h-full w-full flex items-center justify-center"
        bordered={false}
      >
        <div className="flex flex-col items-center text-center space-y-6">
          {/* Calendar Icon */}
          <div className="rounded-full bg-gray-100  p-6 inline-flex">
            <CalendarOutlined className="text-4xl text-gray-400" />
          </div>

          {/* Main Message */}
          <div className="space-y-2 ">
            <Title level={4} className="text-gray-800 ">
              No calendar connected
            </Title>
            <Text className="text-gray-600">
              Connect your calendar and start managing all your interviews.
            </Text>
          </div>

          {/* Steps */}
          <Card 
            className="  bg-gray-50"
            bordered={false}
          >
            <Title level={5} className="text-gray-700 ">
              How to connect:
            </Title>
            <Steps
              direction="vertical"
              size="small"
              current={-1}
              items={steps.map((step) => ({
                title: (
                  <Text className="text-gray-600 font-medium">
                    {step.title}
                  </Text>
                ),
                description: (
                  <Text className="text-gray-600">
                    {step.description}
                  </Text>
                ),
              }))}
            />
          </Card>

          {/* Settings Button */}
          <Button 
            type="primary"
                      onClick={() => {
                
                        history.push(`/o/${params.org_id}/settings/calendar`)
            }}
            icon={<SettingOutlined />}
            className="flex items-center group"
          >
           Connect calendar
            <ArrowRightOutlined className="ml-2 group-hover:translate-x-1 transition-transform" />
          </Button>
        </div>
      </Card>

     
    </div>
  );
};

export default EmptyCalendar;






import React, { useState } from "react";
import { connect } from "react-redux";
import { InitLabel, Label } from "../../../type/type";
import { SecondaryButton } from "../../../ant/Button";
import { CheckOutlined, LoadingOutlined} from "@ant-design/icons";
import { Dispatch } from "redux";
import BulkLabel from "../../common/AddLabelPopover";
import { determineTextColor } from "../../../utils/CalculateBrightnessOfColor";
import { attachLabelToCandidateReq } from "../../../lib/apiReq";
import {  SOCKET_CLIENT_ID } from "../../../constant";
import {
  addLabelToCDBAndReview,
  attachLabelToCandidate,
  deleteLabelFromCandidate,
  editLabelOfCDB,
} from "../../../lib/api";
import {
  addLabelToCurrentCandidateInCDB,
  removeLabelFromCandidate,
  saveCandidateLabel,
  updateCandidateLabel,
} from "../../../redux/actions/opening/action";
import { CurrentCandidateInPool } from "../../../redux/reducers/initialState";
import "../../../styles/component/style.css"
import AddLabelIcon from "../../Icons/AddLabelIcon";

// Define the types for the component's props
interface Props {
  candidateLabels: InitLabel;
  currentCandidateInPool: CurrentCandidateInPool;
  updateCandidateLabel(updatedLabel: any): void;
  saveCandidateLabel(label: any): void;
  addLabelToCurrentCandidateInCDB(data: any, candidateId: string): void;
  removeLabelFromPoolInAts(data: string, candidateId: string[]): void;
  currentCandidateInEditMode:any
}

const AddLabelToCandidate: React.FC<Props> = ({
  candidateLabels,
  // currentCandidateInPool,
  updateCandidateLabel,
  saveCandidateLabel,
  addLabelToCurrentCandidateInCDB,
    removeLabelFromPoolInAts,
  currentCandidateInEditMode,
}) => {
  // State variables to manage labels and loading state
  const [localLabels, setLocalLabels] = useState<any>([]);
  const [loader, showLoader] = useState(false)
  const [loadedLabel, setloadedLabel] = useState("")

  /**
   * Handles creating a new label and associating it with the current candidate.
   * @param newLabel - The label object containing value and color.
   */
  const handleCreateLabel = (newLabel: { value: string; color: string }) => {

    const labelRequest = {
      name: newLabel.value,
      color: newLabel.color,
      entity:  "candidates",
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
    };

    const clientId = sessionStorage.getItem(SOCKET_CLIENT_ID);

    addLabelToCDBAndReview(labelRequest).then((createdLabel) => {
      const request: attachLabelToCandidateReq = {
        candidate_id: currentCandidateInEditMode.candidateId.id,
        label_id: createdLabel.id,
        socket_client_id: clientId ? clientId : "",
      };
      saveCandidateLabel(createdLabel);

      attachLabelToCandidate(request).then((candidateLabels) => {
        addLabelToCurrentCandidateInCDB(
          candidateLabels.labels,
          currentCandidateInEditMode.candidateId.id        );

        showLoader(false);
      });
    });

    setLocalLabels([...localLabels, newLabel]);
  };

  /**
   * Handles editing an existing label.
   * @param updatedLabel - The label object containing id, value, and color.
   */
  const handleEditLabel = (updatedLabel: { id: string; value: string; color: string }) => {
    const request = {
      id: updatedLabel.id,
      color: updatedLabel.color,
      name: updatedLabel.value,
      entity: "candidates",
    };

    editLabelOfCDB(request).then((editedLabel) => {
      updateCandidateLabel(editedLabel);
    });

    setLocalLabels([...localLabels, updatedLabel]);
  };

  /**
   * Determines the icon to display next to a label.
   * @param label - The label object.
   * @returns The icon to display.
   */
  const determineIcon = (label: Label): JSX.Element | null => {
    const isLabelExist = currentCandidateInEditMode.labels.map((label: Label) => label).includes(label.id)
    const isLoading = label.id === loadedLabel;

    if (loader && isLoading) {
      return <LoadingOutlined style={{ color: determineTextColor(label.color) }} />;
    }

    if (isLabelExist) {
      return <CheckOutlined style={{ color: determineTextColor(label.color) }} />;
    }

    return null;
  };

  /**
   * Adds or removes a label from a candidate based on its current state.
   * @param labelData - The label object.
   */
  const addOrRemoveLabelFromCandidate = (labelData: Label) => {
    setloadedLabel(labelData.id)

    // const clientId = sessionStorage.getItem(SOCKET_CLIENT_ID);

    const isLabelAttached = currentCandidateInEditMode.labels.some(
      (candidateLabel:any) => candidateLabel === labelData.id
    );

    if (candidateLabels[labelData.id]) {
      if (isLabelAttached) {
        showLoader(true);
        const request = {
          candidate_ids: [currentCandidateInEditMode.candidateId.id],
          label_ids: [labelData.id],
          socket_client_id: "",
        };

        deleteLabelFromCandidate(request)
          .then((response) => {
            showLoader(false);
            removeLabelFromPoolInAts(
              currentCandidateInEditMode.candidateId.id,
              response.label_ids
            );
          })
          .catch(() => {
            showLoader(false);
          });
      } else {
        showLoader(true);
        const request: attachLabelToCandidateReq = {
          candidate_id: currentCandidateInEditMode.candidateId.id,
          label_id: labelData.id,
          socket_client_id:  "",
        };

        attachLabelToCandidate(request)
          .then((candidateLabels) => {
            showLoader(false);
            addLabelToCurrentCandidateInCDB(
              candidateLabels.labels,
              currentCandidateInEditMode.candidateId.id
            );
          })
          .catch(() => {
            showLoader(false);
          });
      }
    }
  };

  return (
    <div>
      <BulkLabel
        determineIcon={determineIcon}
        onAddLabel={addOrRemoveLabelFromCandidate}
        selectedLabels={currentCandidateInEditMode.labels.map(
          (label: string) => label
        )}
        element={<SecondaryButton onClick={() => {}} icon={<AddLabelIcon />} className="left-align-button w-full truncate">Add label</SecondaryButton>}
        onEditLabel={handleEditLabel}
        onCreateLabel={handleCreateLabel}
        defaultTitle="Create Label"
        initialLabels={Object.values(candidateLabels)}
      />
    </div>
  );
};

// Map Redux state to component props
const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  currentCandidateInPool: state.opening.currentCandidateInPool,
  candidateLabels: state.opening.candidateLabels,
  currentCandidateInEditMode: state.opening.currentCandidateInEditMode,

});

// Map Redux actions to component props
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveCandidateLabel: (label: any) => dispatch(saveCandidateLabel(label)),
  updateCandidateLabel: (updatedLabel: any) => dispatch(updateCandidateLabel(updatedLabel)),
  removeLabelFromPoolInAts: (data: string, candidateId: string[]) => dispatch(removeLabelFromCandidate(data, candidateId)),
  addLabelToCurrentCandidateInCDB: (data: any, candidateId: string) => dispatch(addLabelToCurrentCandidateInCDB(data, candidateId)),
});

// Connect component to Redux store
export default connect(mapStateToProps, mapDispatchToProps)(AddLabelToCandidate);

import React from "react";
import { connect } from "react-redux";
import { SEARCH_TO_ADD_MEMBER } from "../../../constant";
import {
  Member,
  orgMember,
  Opening,
  OpeningMembers,
  Registration,
} from "../../../type/type.d";
import { Avatar } from "antd"
import { generateTooltipForMember } from "../../../utils/capitalizeFirstLetter"
import TacitbaseAvtar from "../../../ant/Avtar"
import SlackIcon from "../../../assets/SlackIcon"
import TickIcon from "../../Icons/TickIcon";
export type AddBoardMemberPopupProps = {
  currentOpening: Opening;
  members: orgMember;
  openingMembers: OpeningMembers;
  searchResult: Member[];
  selectedMember: string[];
  click: (member: Member) => void;
  searchInObject: OpeningMembers;
  searchInObjectKey: string;
  refer?:any
};

const SearchedResultPopup = (props: AddBoardMemberPopupProps) => {
  if (!props.members) return null;


  return (
    <React.Fragment>
      <div className="absolute border  border-tacitbase-tertiary1 border-opacity-25 right-0 z-10 w-full mt-2 overflow-y-scroll max-h-80 origin-top-right rounded-default bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" ref={props.refer}>
        {" "}
        {props.searchResult.length > 0 ? (
          props.searchResult.map((member: Member, idx) => (
            <div
              key={idx}
              className="flex hover:bg-gray-200 hover:bg-opacity-40 m-1"
            >
              <div
                className={`flex py-1.5  ${
                  props.searchInObject[props.searchInObjectKey]
                    ? props.searchInObject[props.searchInObjectKey].some(
                        (m) => m.id == member.id
                      )
                    : null || props.selectedMember.includes(member.id)
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }   justify-between items-center hover:bg-gray-300 rounded-default hover:bg-opacity-40 w-full`}
                onClick={
                  !(props.searchInObject[props.searchInObjectKey]
                    ? props.searchInObject[props.searchInObjectKey].some(
                        (m) => m.id == member.id
                      )
                    : null || props.selectedMember.includes(member.id))
                    ? () => props.click(props.members[member.id])
                    : () => {}
                }
                onKeyDown={() => props.click(props.members[member.id])}
                tabIndex={0}
                role="button"
                data-testid={props.members[member.id].id}
              >
                <div className="flex space-x-1.5 w-full px-1">
                  <Avatar.Group>{props.members[member.id].image_url ?
                    <TacitbaseAvtar size="default" toolTip={generateTooltipForMember(props.members[member.id])} src={props.members[member.id].image_url} />
                    : <TacitbaseAvtar size="default" toolTip={generateTooltipForMember(props.members[member.id])} content={props.members[member.id].first_name[0] + props.members[member.id].last_name[0]} />
                  }</Avatar.Group>
                  <div className="flex justify-between w-full items-center">
                    <div className="flex flex-col justify-center w-full">
                      <div className="leading-snug font-semibold text-xs flex text-gray-800 tracking-normal space-x-1">
                        <span> {props.members[member.id].first_name}{" "}
                        </span>

                        <span>{props.members[member.id].last_name}</span>
                        {member.registrations.some((registration: Registration) => registration.data.source === "slack") && (
                          <SlackIcon size="14" />
                        )}
                      </div>
                      <div className="text-xs leading-snug tracking-wide text-gray-800">
                        {props.members[member.id].email}
                      </div>
                    </div>{" "}
                    {props.searchInObject[props.searchInObjectKey] ? (
                      props.searchInObject[props.searchInObjectKey].some(
                        (m) => m.id === member.id
                      ) ? (
                        <TickIcon />
                      ) : null
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-xs text-gray-500 px-2 py-2">
            {SEARCH_TO_ADD_MEMBER}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  members: state.opening.members,
  openingMembers: state.opening.openingMembers,
});

export default connect(mapStateToProps)(SearchedResultPopup);

import React, { useEffect, useMemo, useRef, useState } from "react";
import { Views } from "react-big-calendar";
import dayjs, { Dayjs } from "dayjs";
import { Button, Space, Select } from "antd";
import "react-big-calendar/lib/css/react-big-calendar.css";

import {
  fetchCalendarColors,
  fetchEventsForCalendar,
  fetchUserCalendarList,
  getUserCalendarPreferences,
  updateUserCalendarPreferences,
} from "../../../lib/api";
import {
  addCalendarList,
  addColors,
  removeCalendarEvents,
  removeSelectedCalendars,
  setCalendarEvents,
  setInitiallySelectedCalendar,
  setSelectedCalendar,
} from "../../../redux/reducers/calendarSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "./customCss.css";
import CalendarSidebar from "./CalendarSidebar";
import CalendarMain from "./CalendarMain";
import { RootState } from "../../../redux/reducers/root";
import {
  getDateRangeForView,
  getUserTimezone,
  ProcessedEvent,
  processEvents,
} from "./calendarUtils";
import { CalendarItem, userCalendarPreference } from "../../../lib/apiRes";
import { LeftOutlined, MenuUnfoldOutlined, RightOutlined } from "@ant-design/icons";
import { MenuFoldOutlined } from "@ant-design/icons";
import Tooltip from "../../../ant/Tooltip";
// Moved selectors outside component to prevent recreation
const selectAllEvents = (state: RootState): ProcessedEvent[] =>
  Object.values(state.calendar.eventsByCalendar).flatMap((calendar: any) =>
    Object.values(calendar)
  );

const selectCalendarList = (state: RootState) => state.calendar.calendarList;
const selectLoginUserData = (state: RootState) => state.opening.loginUser;
const selectSelectedCalendars = (state: RootState) =>
  state.calendar.selectedCalendars;

// Main Calendar Component
const CalendarView: React.FC = () => {
  const calendarRef = useRef<HTMLDivElement>(null);
  // const [selectedCalendars, setSelectedCalendars] = useState<string[]>([]);

  // Helper function to calculate date range based on view and current date
let initialSettings ={
  
  id: "",
  user_id: "",
  org_id: "",
  settings: {collapse:true,pref:{},view:"month"},
  created_at: "",
  updated_at: ""
}
  const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs());
  const [userPreferenceSettings, setUserPreferenceSettings] = useState<userCalendarPreference>(initialSettings)
  // Memoize dateRange calculation to prevent unnecessary recalculations
  const dateRange = useMemo(
    () => getDateRangeForView(currentDate, userPreferenceSettings.settings.view),
    [currentDate.valueOf(), userPreferenceSettings.settings.view] // Use valueOf() for stable dependency
  );

  // Use shallowEqual for objects to prevent unnecessary rerenders
  const events = useSelector(selectAllEvents, shallowEqual);
  const calendarList = useSelector(selectCalendarList, shallowEqual);
  const loginUserData = useSelector(selectLoginUserData, shallowEqual);
  const selectedCalendars = useSelector(selectSelectedCalendars, shallowEqual);
  const [collapsed, setCollapsed] = useState(false);
  
  const dispatch = useDispatch();

  // Handler for date changes from both main calendar and mini calendar
  const handleDateChange = (newDate: Dayjs) => {
    setCurrentDate(newDate);
  };
  useEffect(() => {
    setUserPreferenceSettings(initialSettings)
    getUserCalendarPreferences().then((userCalendarPreference) => {
      userCalendarPreference.settings?setUserPreferenceSettings(userCalendarPreference):null
    })
    fetchCalendarColors(loginUserData.user.id).then((colors) => {
      dispatch(addColors(colors));
    });
    fetchUserCalendarList(loginUserData.user.id).then((calendarList) => {
      let initialSelectedCalendars = calendarList.items
        .filter((cal) => cal.selected)
        .map((cal) => cal.id);
      dispatch(setInitiallySelectedCalendar(initialSelectedCalendars));
      dispatch(addCalendarList(calendarList.items));
     
    });
    
  }, []); // Empty dependency array means this runs once when component mounts
  const timeMin = dateRange.start.toISOString();
  const timeMax = dateRange.end.toISOString();
  const [isLoading, setIsLoading] = useState(false);

  const updateCalendarPreference = (reqObj: any) => {
    updateUserCalendarPreferences(reqObj).then((updatedCalendarPreference) => {
      setUserPreferenceSettings(updatedCalendarPreference)
    })
  }

  const fetchEvents = async (calendars: string[]) => {
    setIsLoading(true);
    try {
      // Fetch events for all selected calendars
      const eventsPromises = calendars.map((calendarId) =>
        fetchEventsForCalendar(
          calendarId,
          { timeMin, timeMax },
          loginUserData.user.id,
          getUserTimezone().name
        ).catch((error) => {
          console.error(`Error fetching calendar ${calendarId}:`, error);
          // Return null to handle this calendar's error individually
          return null;
        })
      );

      // Wait for all promises to resolve
      const eventsList = await Promise.all(eventsPromises);

      // Process each calendar's events
      calendars.forEach((calendarId, index) => {
        const calendarEvents = eventsList[index];

        // Check if we have valid events and items for this calendar
        if (calendarEvents?.items) {
          const processedEvents = processEvents(
            calendarEvents.items,
            calendarList[calendarId].id
          );

          dispatch(
            setCalendarEvents({
              calendarId,
              events: processedEvents,
            })
          );
        } else {
          // Handle case where this calendar's events are null/undefined
          dispatch(
            setCalendarEvents({
              calendarId,
              events: {}, // Set empty array as default
            })
          );
        }
      });
    } catch (error) {
      console.error("Error processing calendar events:", error);

      // Handle the overall error by setting empty events for all selected calendars
      calendars.forEach((calendarId) => {
        dispatch(
          setCalendarEvents({
            calendarId,
            events: {},
          })
        );
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchEvents(selectedCalendars);
  }, [dateRange,calendarList]);

  const handleToday = () => {
    setCurrentDate(dayjs());
  };
  const handlePrev = () => {
    const newDate = currentDate.subtract(
      1,
      userPreferenceSettings.settings.view === Views.MONTH ? "month" : userPreferenceSettings.settings.view === Views.WEEK ? "week" : "day"
    );
    setCurrentDate(newDate);
  };

  const handleNext = () => {
    const newDate = currentDate.add(
      1,
      userPreferenceSettings.settings.view === Views.MONTH ? "month" : userPreferenceSettings.settings.view === Views.WEEK ? "week" : "day"
    );
    setCurrentDate(newDate);
    // Update events based on the new date if needed
  };
  const handleViewChange = (newView: string) => {
    // setView(newView);    
    let req = {
      "settings": {
      view:newView
    }}
    updateCalendarPreference(req)

    // call api to update setting
  };

  const onCalendarToggle = async (calendar: CalendarItem,value:boolean) => {
    let req = {
      "settings": {
        "pref": {
           [calendar.id]: value
        }
    }}
    updateCalendarPreference(req)

    if (selectedCalendars.includes(calendar.id)) {
     dispatch(removeSelectedCalendars([calendar.id])) 
      dispatch(removeCalendarEvents(calendar.id));
    } else {
      setIsLoading(true);
      dispatch(setSelectedCalendar([calendar.id]));
      try {
        const eventsRes = await fetchEventsForCalendar(
          calendar.id,
          {
            timeMin: dateRange.start.toISOString(),
            timeMax: dateRange.end.toISOString(),
          },
          loginUserData.user.id,
          getUserTimezone().name
        );

        const processedEvents = eventsRes?.items
          ? processEvents(eventsRes.items, calendar.id)
          : {};

        dispatch(
          setCalendarEvents({
            calendarId: calendar.id,
            events: processedEvents,
          })
        );
      } finally {
        setIsLoading(false);
      }
    }
  };
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    let req = {
      "settings": {
      collapse:!userPreferenceSettings.settings.collapse 
    }}
    updateCalendarPreference(req)


  };
  const TOOLTIP_FORMATS: any = {
    [Views.MONTH]: (date: Dayjs) => date.format("MMMM YYYY"),
    [Views.WEEK]: (date: Dayjs) =>
      `Week of ${date.startOf("week").format("MMM D")}`,
    [Views.WORK_WEEK]: (date: Dayjs) =>
      `Week of ${date.startOf("week").format("MMM D")}`,
    [Views.DAY]: (date: Dayjs) => date.format("MMMM D, YYYY"),
    [Views.AGENDA]: (date: Dayjs) => date.format("MMMM D, YYYY"),
  };
  // Calculate tooltip dates based on current view and date
  const getNavigationTooltips = useMemo(() => {
    const today = dayjs();
    const format = TOOLTIP_FORMATS[userPreferenceSettings.settings.view] || TOOLTIP_FORMATS[Views.MONTH];
    return {
      prev: format(
        currentDate.subtract(
          1,
          userPreferenceSettings.settings.view === Views.MONTH ? "month" : userPreferenceSettings.settings.view === Views.WEEK ? "week" : "day"
        )
      ),
      next: format(
        currentDate.add(
          1,
          userPreferenceSettings.settings.view === Views.MONTH ? "month" : userPreferenceSettings.settings.view === Views.WEEK ? "week" : "day"
        )
      ),
      today: today.format("MMMM D, YYYY"),
    };
  }, [currentDate, userPreferenceSettings.settings.view]);

  return (
    <div
      style={{ height: "100%", position: "relative" }}
      ref={calendarRef}
      className="  "
    >
      <div className="flex justify-between items-center  py-2 px-4">
        <Space>
          <Tooltip
            arrow={false}
            content={
              userPreferenceSettings?.settings.collapse ? "Show navigation panel" : "Hide navigation panel"
            }
          >
            <Button
              icon={userPreferenceSettings?.settings.collapse ? <MenuUnfoldOutlined  className="w-3.5"/> : <MenuFoldOutlined  className="w-3.5"/>}
              onClick={toggleSidebar}
            />
          </Tooltip>
          <Tooltip
            arrow={false}
            content={`Go to ${getNavigationTooltips.today}`}
          >
            <Button onClick={handleToday}>Today</Button>
          </Tooltip>
          <Tooltip arrow={false} content={getNavigationTooltips.prev}>
            <Button onClick={handlePrev} icon={<LeftOutlined className="w-3"></LeftOutlined>}></Button>
          </Tooltip>
          <Tooltip arrow={false} content={getNavigationTooltips.next}>
            <Button onClick={handleNext} icon={<RightOutlined className="w-3" />}></Button>
          </Tooltip>

         
        </Space>
        <Space className="">
               {userPreferenceSettings.settings.view=='day'?currentDate.format("MMMM DD, YYYY"):currentDate.format("MMMM YYYY")}
        </Space>
         
        <Space className="font-medium text-sm">
        <Select
            value={userPreferenceSettings.settings.view}
            style={{ width: 120 }}
            onChange={(value) => handleViewChange(value)}
          >
            <Select.Option value={Views.MONTH}>Month</Select.Option>
            <Select.Option value={Views.WEEK}>Week</Select.Option>
            <Select.Option value={Views.DAY}>Day</Select.Option>
            <Select.Option value={Views.AGENDA}>Agenda</Select.Option>
            {/* <Select.Option value={Views.WORK_WEEK}>Work week</Select.Option> */}
          </Select></Space>
      </div>
      <div className="flex h-full  w-full ">
        <CalendarSidebar
          collapse={userPreferenceSettings?.settings.collapse}
          onCalendarToggle={onCalendarToggle}
          currentDate={currentDate}
          onDateChange={handleDateChange}
          selectedCalendars={selectedCalendars}
        />
        <CalendarMain
          collapse={userPreferenceSettings?.settings.collapse}
          isLoading={isLoading}
          events={events}
          view={userPreferenceSettings.settings.view}
          currentDate={currentDate}
          onViewChange={handleViewChange}
          onNavigate={setCurrentDate}
        />
      </div>
    </div>
  );
};

export default CalendarView;

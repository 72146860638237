import React, { Component } from "react";
import BoardPanal from "../../components/boardPanel/BoardPanel";
// import Navbar from "../../components/navbar/Navbar";
import SubNavbar from "../../components/subNavbar/SubNavbar";
import {
  getBoardByid,
  getBoardLabel,
  getCompanies,
  getFormById,
  getJDbyBoardId,
  getOpenings,
  readNotification,
} from "../../lib/api";
// Redux stuff
import { connect } from "react-redux";
import {
  company,
  FormBuilder,
  Jd,
  List,
  loginUser,
  notificationServiceEntityDotChangePayloadType,
  notificationServiceEntityDotRemovePayloadType,
  Opening,
  OpeningMembers,
  organisationState,
} from "../../type/type";
import { Dispatch } from "redux";
import {
  addWsRequest,
  changeServiceNotificationsCountValue,
  clearBoardActivity,
  initBoardLabels,
  initCompanies,
  initCurrentBoard,
  initCurrentBoardWatcher,
  initCurrentTrackerForm,
  initCurrentTrackerJd,
  initEntityUnreadNotificationsBoard,
  initLists,
  initOpeningMembership,
  initServiceEntityNotificationsDot,
  listOfBoards,
  removeCurrentBoard,
  removeOpening,
  slackOptions,
  topicSubscription,
  unread_dot_remover,
  updateSearchFlag,
} from "../../redux/actions/opening/action";
// import NavigationSlider from "../../components/sidePanal/navigationSlider/NavigationSlider";
// import SideNavbar from "../../components/navbar/SideNavbar";
// import SlackIntegration from "../../components/subNavbar/slack/SlackIntegration";
import { SUBSCRIBE, UNSUBSCRIBE } from "../../redux/actionType";
import { CalendarRoute,  FormRoute, JobDescriptionRoute, PoolRoute,  SettingsRoute, TACITBASE, TrackerRoute } from "../../constant";
import JoinPublicBoard from "../../components/subNavbar/addBoardMember/JoinPublicBoard";
import {
  allNotifications,
  WatcherSubscription,
} from "../../lib/apiRes";
import { ConfigProvider, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import BoardJD from "../../components/boardPanel/BoardJd";
import BoardForm from "../../components/boardPanel/BoardForm";
import PoolIndex from "../pool/Pool";
import Filter from "../../components/subNavbar/filter/Filter";
import ActivityIndex from "../../components/jobPost/tabs/activity/ActivityIndex";
import SettingsPanel from "../../components/jobPost/tabs/settings/SettingsPanel";
import BoardTabActionButton from "./BoardTabActionButton";
import AnalyticsMainPage from "../analytics/AnalyticsMainPage";
import BoardCalendarView from "../../components/calender/board/BoardCalendarView";
import { removeBoardEvents } from "../../redux/reducers/calendarSlice";
import CalendarDetails from "./CalenderDetails";
type props = {
  orgStatus: organisationState;
  removeOpening: (opening: any) => void;
  currentOpening: Opening;
  initCardNotificationDot(
    payload: notificationServiceEntityDotChangePayloadType
  ): void;
  boardLabels(boardData: any, id: string): void;
  topicSubscription(topic: any): void;
  removeCurrentBoard(): void;
  saveCurrentBoard(board: Opening): void;
  initEntityUnreadNotificationsBoard(notification: allNotifications[]): void;
  initCurrentBoardWatcher(data: WatcherSubscription): void;
  clearBoardActivity(): void;
  removeBoardNotificationDot(
    payload: notificationServiceEntityDotRemovePayloadType
  ): void;
  initCurrenTrackerJd(payload: Jd): void;
  initCurrentTrackerJdForm(payload: FormBuilder): void;

  initBoardCardNotifications(notification: Notification[]): void;
  changeServiceNotificationsCount(payload: {}): void;
  topic: string;
  cal_id: string;
  match: any;
  applicationId: string;
  updateSearchFlag(): void;
  setSlackOptions(option: any): void;
  initOpeningMembers(member: any, openingId: string): void;
  addWsRequest(actionRequest: any): void;
  setCalenderId(cal_id: string): void;
  initMembers(members: any): void;
  history: any;
  openingMembers: OpeningMembers;
  loginUser: loginUser;
  initLists(list: List[]): void;
  notificationCenter: any;
  initCompanies: (JobBoard: company[]) => void,
  listOfBoards(boardData: any): void,
  removeBoardEvents: () => void;

};
type state = {
  sidebarOpen: boolean;
  showPopup: boolean;
  showCal: boolean;
  activeTab: string;
};

class Board extends Component<props, state> {
  
  state = {
    sidebarOpen: false,
    showPopup: false,
    showCal: false,
    activeTab: "",
  };

  handleClick = () => {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
    });
  };
  openSideBar = () => {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
    });
  };
  showPopup = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };
  toggleViewBetweenCalAndList = () => {
    this.setState({
      showCal: !this.state.showCal,
    });
  };
  componentDidMount() {
    this.loadBoardData()

    // API call
  }
  componentDidUpdate(prevProps: any) {

    const prevBoardId = prevProps.match.params.board_id
    const currentBoardId = this.props.match.params.board_id

    if (prevBoardId !== currentBoardId) {
      this.props.removeCurrentBoard()
      this.loadBoardData()
    }
  }
  loadBoardData() {
    let shouldCallApis = this.props.history.location.method === "post" ? false : true;


    const { match, saveCurrentBoard, loginUser, applicationId, addWsRequest, initCurrenTrackerJd, initCurrentTrackerJdForm, boardLabels, currentOpening } = this.props
    const { tabname, board_id } = match.params

    if (tabname) {
      this.setState({ activeTab: tabname })
    } else {
      this.setState({ activeTab: TrackerRoute })
    }

    if (sessionStorage.getItem("current_tab_application_token") && shouldCallApis) {
      getBoardByid(board_id).then((opening: Opening) => {
        document.title = `${opening.name} | ${TACITBASE}`
        saveCurrentBoard(opening)

        addWsRequest({
          type: SUBSCRIBE,
          model_type: "board",
          model_id: board_id,
          user_id: loginUser.user.id,
          org_id: applicationId,
          time: Math.floor(new Date().getTime() / 1000),
        })


      })

    

      getBoardLabel(board_id).then((labels: any) => {
        boardLabels(labels, currentOpening.id)
      })
      getCompanies().then((res) => {
        this.props.initCompanies(res)
      })
      getOpenings().then((openings) => {
        this.props.listOfBoards(openings)
      })
    }
    getJDbyBoardId(board_id)
      .then((res) => {
        initCurrenTrackerJd(res)
        getFormById(res.form_id).then((form: FormBuilder) => {
          initCurrentTrackerJdForm(form)
        })
      })
      .catch((err) => {
        console.log("error", err)
      })
  }
  componentWillUnmount() {
    this.props.removeBoardEvents();
   if(this.props.currentOpening.archive){
    this.props.removeOpening(this.props.currentOpening) 
   }
    this.props.notificationCenter.unreadDotsofServices?.ats?.boards[
      this.props.currentOpening.id
    ] &&
      readNotification(this.props.currentOpening.id, "board").then(() => {
        this.props.removeBoardNotificationDot({
          service: "ats",
          model_name: "board",
          remove_id: this.props.currentOpening.id,
        });
      });
    if (this.props.orgStatus.prev.id === this.props.orgStatus.current.id) {
      this.props.addWsRequest({
        type: UNSUBSCRIBE,
        model_type: "board",
        model_id: this.props.currentOpening.id,
        user_id: this.props.loginUser.user.id,
        org_id: this.props.applicationId,
        time: Math.floor(new Date().getTime() / 1000),
      });
    }
    this.props.removeCurrentBoard();
  }

  handleTabChange = (key: string) => {
    this.setState({ ...this.state, activeTab: key });
    // Update URL when tab is changed
    const { org_id } = this.props.match.params;

    // this.props.history.push(`/o/${org_id}/b/${this.props.currentOpening.id}/${key}`);
    this.props.history.push(
      `/o/${org_id}/b/${this.props.currentOpening.id}/${key}`
    );
  };
  render() {
    // Define tabs as a separate variable
    const tabs = [
    
      {
        key: "tracker",
        label: "Tracker",
        path: `/${TrackerRoute}`,

        content: <BoardPanal />,
        filterOption: <Filter />,
      },
      {
        key: "pool",
        label: "Pool",
        path: `/${PoolRoute}`,

        content: (
          <>
            {" "}
            <PoolIndex />
          </>
        ),
        filterOption: <button>tick</button>,
      },
      {
        key: "calendar",
        label: "Calendar",
        path: `/${CalendarRoute}`,

        filterOption: <Filter />,

        content: ( <BoardCalendarView />
        ),
      },
      {
        key: "job-description",
        label: "Job description",
        path: `/${JobDescriptionRoute}`,

        // filterOption: <Filter />,
        content: (
          <>
            <BoardJD />
          </>
        ),
      },
      {
        key: "form",
        label: "Form",
        path: `/${FormRoute}`,

        content: (
         
              <BoardForm />
        ),
      },
      {
        key: "activity",
        label: "Activity",
        path: `/Activity`,

        content: (
          <ActivityIndex />
        ),
      },
      {
        key: "analytics",
        label: "Analytics",
        path: `/analytics`,

        content: <AnalyticsMainPage/>
      },   
      {
        key: "settings",
        label: "Settings",
        path: `/${SettingsRoute}`,

        content: (
          <SettingsPanel  />     ),
      },   
      // Add more tabs as needed
    ];

    
    return (
      <React.Fragment>
          <div className="flex flex-col overflow-hidden">
            <div
              style={{  borderBottom: "1px solid rgba(255, 255, 255, 0.25)" }}
              className="px-4 w-full justify-center items-center bg-black  bg-opacity-50   text-sm cursor-pointer focus:outline-none mb-4"
            >
              <CalendarDetails/>
              {" "}
              {/* Adjust width as needed */}
              <SubNavbar
                showPopup={this.showPopup}
                click={this.handleClick}
                sliderState={this.state.sidebarOpen}
                showCal={this.toggleViewBetweenCalAndList}
                toggleValue={this.state.showCal}
              />{" "}
              <div className="flex flex-row justify-between  h-10 " style={{height:37}}>
              <ConfigProvider 
          theme={{
            components: {
              Tabs: {
                cardPadding:"0px",
                itemColor:"rgba(255,255,255, 0.7)",
                itemSelectedColor:"rgba(255,255,255, 1)",
                itemActiveColor	:"rgba(255,255,255, 1)" ,
                inkBarColor:"white",
                horizontalItemPadding:"0px 0px 8px 0px",
                itemHoverColor:"rgba(255,255,255, 0.9)"

              },
            },
          }}
        >
                <Tabs tabPosition="top"
                  defaultActiveKey={this.state.activeTab}
                  onChange={this.handleTabChange}
                  size="small" 
                  className="text-white h-12 w-full flex" activeKey={this.state.activeTab}
                >
                  {tabs.map((tab) => (
                    <TabPane
                      forceRender
                      className="p-0"
                      key={tab.key} 
                      tab={tab.label}  style={{margin:0}}
                    ></TabPane>
                  ))}
                </Tabs></ConfigProvider>
                <div className="flex items-center justify-end"><BoardTabActionButton activeTab={this.state.activeTab}/></div>
              </div>
            </div>
            <div className="w-full h-full overflow-y-hidden ">
              {" "}
              {/* Adjust width as needed */}
              {tabs.map((tab) => (
                <div
                  key={tab.key} className={`${this.state.activeTab === tab.key?'h-full':'hidden'}`}
                 
                >
                  
                  {this.state.activeTab === tab.key&&(<>{tab.content}</>)}

                  {/* {tab.content} */}
                </div>
              ))}
            </div>
          </div>
          {/* <SideNavbar isBgColorExist={true} /> */}

        {this.props.openingMembers[this.props.currentOpening.id] ? (
          this.props.openingMembers[this.props.currentOpening.id].some(
            (member) => member.id === this.props.loginUser.user.id
          ) || this.props.currentOpening.access.admin ? null : !this.props
              .currentOpening.member_only ? (
            <div className="animate-popover_fadeIn rounded justify-center inset-0 bg-black bg-opacity-50 items-start pt-14 flex fixed  outline-none focus:outline-none">
              <JoinPublicBoard />
            </div>
          ) : null
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  openingMembers: state.opening.openingMembers,
  loginUser: state.opening.loginUser,
  currentOpening: state.opening.currentOpening,
  topic: state.opening.topicSubscription,
  applicationId: state.opening.applicationId,
  cal_id: state.opening.calendar.currentCalenderId,
  orgStatus: state.opening.orgStatus,
  notificationCenter: state.opening.notificationCenter,
});
const mapPropsTostate = (dispatch: Dispatch) => ({
  removeOpening: (opening: any) => dispatch(removeOpening(opening)),
  saveCurrentBoard: (board: Opening) => dispatch(initCurrentBoard(board)),
  initLists: (list: List[]) => dispatch(initLists(list)),
  updateSearchFlag: () => dispatch(updateSearchFlag()),
  boardLabels: (boardData: any, id: string) =>
    dispatch(initBoardLabels(boardData, id)),
  topicSubscription: (topic: any) => dispatch(topicSubscription(topic)),
  removeCurrentBoard: () => dispatch(removeCurrentBoard()),
  setSlackOptions: (options: any) => dispatch(slackOptions(options)),

  initEntityUnreadNotificationsBoard: (notification: allNotifications[]) =>
    dispatch(initEntityUnreadNotificationsBoard(notification)),
  initOpeningMembers: (members: any, openingId: string) => {
    dispatch(initOpeningMembership(members, openingId));
  },
  addWsRequest: (actionRequest: any) => dispatch(addWsRequest(actionRequest)),
  initCurrentBoardWatcher: (data: WatcherSubscription) =>
    dispatch(initCurrentBoardWatcher(data)),
  changeServiceNotificationsCount: (payload: {}) =>
    dispatch(changeServiceNotificationsCountValue(payload)),
  initCardNotificationDot: (
    payload: notificationServiceEntityDotChangePayloadType
  ) => dispatch(initServiceEntityNotificationsDot(payload)),
  clearBoardActivity: () => dispatch(clearBoardActivity()),
  removeBoardNotificationDot: (
    payload: notificationServiceEntityDotRemovePayloadType
  ) => dispatch(unread_dot_remover(payload)),
  initCurrenTrackerJd: (payload: Jd) => dispatch(initCurrentTrackerJd(payload)),
  initCurrentTrackerJdForm: (payload: FormBuilder) =>
    dispatch(initCurrentTrackerForm(payload)),
  listOfBoards: (boardData: Opening[]) => dispatch(listOfBoards(boardData)),
  initCompanies: (candidates: company[]) =>
    dispatch(initCompanies(candidates)),
  removeBoardEvents: () => 
    dispatch(removeBoardEvents()),

});
export default connect(mapStateToProps, mapPropsTostate)(Board);

import React, { useState } from "react"
import { connect } from "react-redux";
import Read from "../../../Read";
import { Opening, Scope } from "../../../type/type.d"
import { Overlay } from "../../hook/Overlay";
import AddBoardMemberPopups from "./AddBoardMemberPopup";
import { Popover } from "antd"
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter"
import { ADD_MEMBER_TITLE } from "../../../constant"
import { Typography } from "antd"
import { IconButton, LabeledIconButton } from "../../../ant/Button"
import { CloseOutlined} from "@ant-design/icons"
import AddIcon from "../../Icons/AddIcon";
const { Text } = Typography
// import OutsideClick from "../../OutsideClick";
type AddBoardMemberProps = {
  currentOpening: Opening;
};

const AddBoardMember = (props: AddBoardMemberProps) => {
  const [open, setOpen] = useState(false);

  return (

    <Read access={props.currentOpening.access} type={true}>
      <Popover destroyTooltipOnHide title={<div className="flex w-full bg-white items-center">
        <Text className="w-full flex justify-center">
          <Text>{capitalizeFirstLetter(ADD_MEMBER_TITLE)}</Text>
        </Text>
        <LabeledIconButton
          type="text"
          label=""
          onClick={() => setOpen(false)}
          icon={<CloseOutlined />}
        />
      </div>} open={open} onOpenChange={(open) => setOpen(open)} trigger={["click"]}
        content={<Overlay onClose={() => setOpen(false)} scope={Scope.Popover} >
          <AddBoardMemberPopups click={() => setOpen(!open)} />
        </Overlay>}>
        <div className="" >
          <IconButton
          tooltipTitle="Add member"
            icon={<AddIcon/>}
            onClick={() => setOpen(!open)}
            data-testid="openMemberPopup"
          />
      </div>
      </Popover>
    </Read >
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
});
export default connect(mapStateToProps)(AddBoardMember);

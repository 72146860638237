import React, { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Popconfirm} from "antd";
import { Opening } from "../../../../../type/type";
import { listOfBoards } from "../../../../../redux/actions/opening/action";
import { IconButton, SecondaryButton } from "../../../../../ant/Button";
import { CANDIDATE_ADDED_TO_TRACKER_SUCCESS, CANDIDATES_ADDED_TO_TRACKER_SUCCESS, SOCKET_CLIENT_ID } from "../../../../../constant";
import { CreateCandidateCard } from "../../../../../lib/api";
import { throwSuccess } from "../../../../../service/throwError";
import JobPostIcon from "../../../../Icons/JobPostIcon";

const SendCandidateToTracker = (props: {
  currentOpening: Opening;
  isBulk: boolean;
  candidateIds: React.Key[];
  currentCandidateInPool: any;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  // Function to handle sending candidate to tracker
  const handleSendToTracker = () => {
     // Set loader to true while API call is in progress
     setIsLoading(true);
    // Get the client ID from session storage
    let clientId = sessionStorage.getItem(SOCKET_CLIENT_ID)
      ? sessionStorage.getItem(SOCKET_CLIENT_ID)
      : "";

    // Request payload for API call
    const requestPayload = {
      candidates: props.isBulk
        ? props.candidateIds
        : [props.currentCandidateInPool.candidate.id],
      opening_id: props.currentOpening.id,
      socket_client_id: clientId ? clientId : "",
    };

   

    // API call to create candidate card
    CreateCandidateCard(requestPayload)
      .then((res) => {
        if (res) {
          // setIsLoading(false);
          if (requestPayload.candidates.length>1) {
            throwSuccess(CANDIDATES_ADDED_TO_TRACKER_SUCCESS);
          } else {
            throwSuccess(CANDIDATE_ADDED_TO_TRACKER_SUCCESS);
          }        }
      })
      .catch(() => {
        setIsLoading(false);
      }).finally(()=>{
        setIsLoading(false);
  
      });
  };

  return (
    <Popconfirm
      title={`Send to tracker: ${props.currentOpening.name}`}
      description={
        <>
          The Candidate will be added to the first list of
          <span className="font-semibold"> {props.currentOpening.name}.</span>
          <br /> This action is irreversible.
        </>
      }
      placement="bottom"
      okText="Yes"
      onConfirm={handleSendToTracker}
      cancelText="No"
      okButtonProps={{ loading: isLoading }}
      >
      {!props.isBulk ? (
        <SecondaryButton
          onClick={() => {}}
          className="w-full truncate left-align-button"
          icon={<JobPostIcon />}
          >Send to tracker</SecondaryButton>
      ) : (
        <IconButton
          icon={ <JobPostIcon /> }
          onClick={(e: { preventDefault: () => any; stopPropagation: () => any; }) => {
            e.preventDefault(), e.stopPropagation();
          }}
          tooltipTitle="Send to tracker"
        />
      )}
    </Popconfirm>
  );
};

// Map state to props
const mapStateToProps = (state: any) => ({
  currentCandidateInPool: state.opening.currentCandidateInPool,
  currentOpening: state.opening.currentOpening,
});

// Map dispatch to props
const mapDispatchToProps = (dispatch: Dispatch) => ({
  listOfBoards: (boardData: Opening[]) => dispatch(listOfBoards(boardData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendCandidateToTracker);

import React, { useEffect, useState } from "react";
import { Breadcrumb, Divider, Spin } from "antd";
import ContentHeader from "../../analyticsVersion1/ContentHeader";
import CalendarView from "./CalendarView";
import { RootState } from "../../../redux/reducers/root";
import EmptyCalendar from "./EmptyCalendarScreen";
import { colors } from "../../../constant";
import { DIVIDER_HEIGHT } from "../../../constant";
import Navbar from "../../navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  reset,
  setConnectedClient,
} from "../../../redux/reducers/calendarSlice";
import { getIntegratedCalender } from "../../../lib/api";
const CalenderHeader = () => {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(true);
  const [isCalendarConnected, setIsCalendarConnected] = useState(false);

  const loginUserData = useSelector(
    (state: RootState) => state.opening.loginUser
  );
  useEffect(() => {
    dispatch(reset());
    setIsFetching(true);
    getIntegratedCalender(loginUserData.user.id)
      .then((res) => {
        setIsCalendarConnected(true);
        setTimeout(() => {
          setIsFetching(false);
        }, 1000);
        dispatch(setConnectedClient(res.client));
      })
      .catch((error) => {
        console.log(error);
        setIsFetching(false);
        setIsCalendarConnected(false);
      });
  }, []);
  return (
    <>
      <div className="h-full w-full flex flex-col overflow-hidden">
        <ContentHeader>
          <div className="flex h-12 px-4 py-3 justify-between items-center ">
            <div className="flex items-center ">
              <Breadcrumb
                items={[
                  {
                    title: "Global calendar",
                  },
                ]}
              />
            </div>
            <div className="flex flex-row items-center">
              <Divider
                type="vertical"
                style={{
                  height: DIVIDER_HEIGHT,
                  borderColor: `${colors.divider}`,
                }}
              />

              <Navbar />
            </div>
          </div>
        </ContentHeader>
        {isFetching ? (
          <div className="h-full w-full bg-white flex justify-center items-center">
            <Spin />
          </div>
        ) : isCalendarConnected ? (
          <div className=" bg-white rounded overflow-hidden h-full relative ">
            <CalendarView />
          </div>
        ) : (
          <EmptyCalendar />
        )}
      </div>
    </>
  );
};

export default CalenderHeader;

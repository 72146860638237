export function init_search_query(state: any, action: any) {
    return {
        ...state,
        searchQuery: {
            ...state.searchQuery,
            query: action.query,
        },
        JobPostPoolSearchQuery: {
            ...state.JobPostPoolSearchQuery,
            query: action.query,
        },
        currentCandidateInEditMode: {
            ...state.currentCandidateInEditMode,
            candidateId: ""
        }
    }
}
export function init_search_Filter(state: any, action: any) {
    return {
        ...state,
        searchQuery: {
            ...state.searchQuery,
            filters: [...action.filterObj]
        }
    }
}

export function init_job_post_pool_search_Filter(state: any, action: any) {
    return {
        ...state,
        JobPostPoolSearchQuery: {
            ...state.JobPostPoolSearchQuery,
            filters: [...action.filterObj]
        }
    }
}

export function init_mail_search_query(state: any, action: any) {
    return {
        ...state,
        mailSearchQuery: {
            ...state.searchQuery,
            query: action.query,
        }
    }
}

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const convertUnixToISO = (unixTimestamp: number): string => {
  let timestampInSeconds: number;

  if (unixTimestamp > 1e18) {
    // Nanoseconds: Divide by 1e9 to convert to seconds
    timestampInSeconds = Math.floor(unixTimestamp / 1e9);
  } else if (unixTimestamp > 1e15) {
    // Microseconds: Divide by 1e6 to convert to seconds
    timestampInSeconds = Math.floor(unixTimestamp / 1e6);
  } else if (unixTimestamp > 1e12) {
    // Milliseconds: Divide by 1e3 to convert to seconds
    timestampInSeconds = Math.floor(unixTimestamp / 1e3);
  } else {
    // Assume the input is already in seconds
    timestampInSeconds = unixTimestamp;
  }

  // Convert to ISO 8601 UTC format
  return dayjs.unix(timestampInSeconds).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
};

import React, { useEffect } from "react";
import ShowLists from "../showList/ShowLists";
import { useLocation } from "react-router-dom";

// Api's
import { getListByListIDs } from "../../../lib/api";
import { connect } from "react-redux";
import { filterCount, initLists } from "../../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { ListProps } from "../../../type/boardPanal";
import { useHistory, useParams } from "react-router";
import FilterObject from "../../subNavbar/filter/FilterObject";
import { Applicant } from "../../../type/type";
const MemoizedShowLists = React.memo(ShowLists);

const List = (props: ListProps) => {
  const params: { board_id: string } = useParams();
  const search = useLocation().search;
  const history: any = useHistory();
  const filterKey: any = new URLSearchParams(search).get("filter");
  let obj: any = {};

  useEffect(() => {
    let called = history.location.method === "post" ? false : true;

    if (sessionStorage.getItem("current_tab_application_token") && called) {
      getListByListIDs(params.board_id)
        .then((lists_with_applicants: any) => {
          lists_with_applicants = lists_with_applicants
            ? lists_with_applicants
            : [];
          props.initLists(lists_with_applicants);
        })
       
    }
    return () => {};
  }, []);
  /*
   * check query string exist
   * if found then construct into object
   */

  if (filterKey) {
    filterKey.split("|").map((i: any) => {
      let a = i.split(":");
      obj[a[0]] = a[1].split(",");
    });
  } else if (props.filterObj[params.board_id]) {
    obj = props.filterObj[params.board_id];
  }

  /*
   * check each item of array exist in members,labels etc.
   * if found then return true and array filter return that applicant
   */

  const applyFilter = (applicant: any) => {
    if (applicant["isFilter"]) {
      return true;
    }
    const filterKeys = Object.keys(obj);
    for (let i = 0; i < filterKeys.length; i++) {
      const filterKey = filterKeys[i];
      const applicantLabels = props.applicantLabels[applicant.id] || [];
      const applicantMembers = props.applicantMembers[applicant.id] || [];
      const filterValues = obj[filterKey];
      if (
        filterValues.length <=
        applicantLabels.length + applicantMembers.length
      ) {
        const matchedValues = filterValues.filter(
          (value: any) =>
            applicantLabels.includes(value) || applicantMembers.includes(value)
        );
        if (matchedValues.length !== filterValues.length) {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  };

  /*
   * compute count of filtered result
   */
  let count = 0;
  const filterApplicantCount = (applicants: Applicant[], index: any) => {
    count =
      count +
      applicants.filter((applicant: Applicant) => applyFilter(applicant))
        .length;
    if (Object.keys(props.lists).length - 1 === index) {
      props.filterCount(count);
    }
    return applicants.filter((applicant: Applicant) => applyFilter(applicant));
  };

  if (Object.keys(obj).length > 0 && Object.keys(props.lists).length == 0)
    props.filterCount(0);
  if (props.lists.length < 1) return null;
  return (
    <>
      {Object.keys(props.lists).map((list: any, index: number) => (
        <MemoizedShowLists
          key={props.lists[list].id}
          listID={props.lists[list].id}
          listName={props.lists[list].name}
          applicants={
            Object.keys(obj).length > 0
              ? filterApplicantCount(props.lists[list].applicants, index)
              : props.lists[list].applicants
          }
          index={index}
          isListLock={props.lists[list].locked}
        />
      ))}

      {props.currentOpening.id === params.board_id && (
        <FilterObject boardId={params.board_id} />
      )}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  lists: state.opening.boardList,
  applicantLabels: state.opening.applicantLabels,
  applicantMembers: state.opening.applicantMembers,
  filterObj: state.opening.boardFilterKeys || {},
});
const mapPropsToState = (dispatch: Dispatch) => ({
  initLists: (lists: any) => dispatch(initLists(lists)),
  filterCount: (count: any) => dispatch(filterCount(count)),
});
export default connect(mapStateToProps, mapPropsToState)(List);

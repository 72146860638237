import React from "react";
import Button1 from "../common/button/Button1";
import ProductName from "../product/ProductName";
import BottomBubble from "./BottomBubble";
import TopBubble from "./TopBubble";

const FallBackErrorUI = () => {
  const reloadPage = () => {
    location.reload();
  };
  return (
    <>
      {" "}
      <div className="flex  flex-col justify-center items-center h-screen">
        <TopBubble />
        <div className="flex flex-col justify-center items-center space-x-4 z-50  ">
          <ProductName />
          <h1 className="tracking-widest text-3xl mb-1">O-o-oh!</h1>{" "}
          <h1 className="tracking-wider text-lg  mb-1">Don&apos;t worry</h1>
          <h1 className="tracking-wider text-lg mb-2">
            Everything is all right.{" "}
          </h1>
          <Button1 buttonName="Try this" click={reloadPage}></Button1>
        </div>{" "}
        <BottomBubble />
      </div>{" "}
    </>
  );
};
export default FallBackErrorUI;

import React from "react";
import BoardLabel from "../../sidePanal/boardLabel/BoardLabel";
// import BoardDescription from "../../sidePanal/boardDescription/BoardDescription";
import ChangeBoardBackground from "../../sidePanal/background/ChangeBoardBackground";
// import Settings from "../../sidePanal/setting/Settings";
// import SearchCard from "../../sidePanal/searchCard/SearchCard";
import CopyList from "../../boardPanel/listActionButtonsPopup/copyList/CopyList";
import MoveList from "../../boardPanel/listActionButtonsPopup/moveList/MoveList";
import MoveAllCardsInList from "../../boardPanel/listActionButtonsPopup/MoveAllCardsInList";
import ArchiveAllCard from "../../boardPanel/listActionButtonsPopup/ArchiveAllCard";
import ArchiveItems from "../../sidePanal/archiveItems/ArchiveItems";
import ColorPalette from "../../sidePanal/ColorPalette/ColorPalette";
import ArchiveList from "../../boardPanel/listActionButtonsPopup/archiveList/ArchiveList";
import ImagePalette from "../../sidePanal/palette/imagePalette/ImagePalette"
import AttachCompany from "../../sidePanal/attachCompany/AttachCompany"
import AnalyticsLabelPopover from "../../listPopup/AnalyticsLabel/AnalyticsLabelPopover";
import JdLink from "../../JdManagement/JdLink";
type Props = {
  prev: () => void;
  next: (name: any) => void;
  param: string;
  close: (e: boolean) => void;
  listId?: string;
};
type State = { [key: string]: any };
const GetComponent = (props: Props) => {
  const listComponents: State = {
    // setting: (
    //   <Settings prev={props.prev} close={(e: boolean) => props.close(e)} />
    // ),
    ArchiveItems: (
      <ArchiveItems prev={props.prev} close={(e: boolean) => props.close(e)} />
    ),
    
    BoardLabel: (
      <BoardLabel prev={props.prev} close={(e: boolean) => props.close(e)} />
    ),
    JdLink:(
      <JdLink  jdLinkProps={{ prev: props.prev, close: props.close }} />
    ),
    // BoardDescription: (
    //   <BoardDescription
    //     prev={props.prev}
    //     close={(e: boolean) => props.close(e)}
    //   />
    // ),
    ColorPalette: (
      <ColorPalette prev={props.prev} close={(e: boolean) => props.close(e)} />
    ),
    AttachCompany: (
      <AttachCompany prev={props.prev} close={(e: boolean) => props.close(e)} />
    ),
    ImgPalette: (
      <ImagePalette prev={props.prev} close={(e: boolean) => props.close(e)} />
    ),
    // SearchCard: (
    //   <SearchCard prev={props.prev} close={(e: boolean) => props.close(e)} />
    // ),
    ChangeBoardBackground: (
      <ChangeBoardBackground
        prev={props.prev}
        click={(name: string) => props.next(name)}
        close={(e: boolean) => props.close(e)}
      />
    ),
    CopyList: (
      <CopyList prev={props.prev} close={(e: boolean) => props.close(e)} />
    ),
    MoveList: (
      <MoveList
        prev={props.prev}
        close={(e: boolean) => props.close(e)}
        listId={props.listId}
      />
    ),
    ArchiveList: (
      <ArchiveList
        close={(e: boolean) => props.close(e)}
        listId={props.listId}
      />
    ),
    MoveAllCardsInList: (
      <MoveAllCardsInList
        prev={props.prev}
        close={(e: boolean) => props.close(e)}
        listId={props.listId}
      />
    ),
    ArchiveAllCard: (
      <ArchiveAllCard
        prev={props.prev}
        close={(e: boolean) => props.close(e)}
        listId={props.listId}
      />
    ),
    AnalyticLabel: (
      <AnalyticsLabelPopover  prev={props.prev} listId={props.listId}
      close={(e: boolean) => props.close(e)}
      />
    ),
  };
  const component = listComponents[`${props.param}`];
  return component;
};
export default GetComponent;
